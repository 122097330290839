import { Form, DatePicker, FormItemProps } from "antd";
import { dateFormat, monthFormat, yearFormat } from "../filter/FilterDate";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string;
  format?: "date" | "month" | "year";
  tooltip?: any;
}

function DateFormItem({
  className,
  disabled,
  tooltip,
  required = true,
  name = "date",
  label,
  format = "date",
}: Props) {
  const { t } = useTranslation();
  const defFormat =
    format === "date"
      ? dateFormat
      : format === "month"
      ? monthFormat
      : yearFormat;

  return (
    <Form.Item
      name={name}
      label={`${label ?? t("common.date")}${required ? " *" : ""}`}
      rules={
        required ? [{ required: true, message: t("common.selectDate") }] : []
      }
      className={className || "full"}
      tooltip={tooltip}
    >
      <DatePicker
        format={defFormat}
        picker={
          format === "date" ? "date" : format === "month" ? "month" : "year"
        }
        allowClear
        style={{ width: "100%" }}
        disabled={disabled}
        placeholder={
          format === "year"
            ? "____"
            : format === "month"
            ? "____-__"
            : "____-__-__"
        }
      />
    </Form.Item>
  );
}

export default DateFormItem;
