import { Form, Input, InputNumber, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddAktMutation,
  useEditAktMutation,
} from "src/app/services/finance";
import { IAktTable, IFormAkt } from "src/app/services/finance/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";

interface Props {
  handleGet: () => void;
  editableData?: IAktTable;
  setEditableData: React.Dispatch<React.SetStateAction<IAktTable | undefined>>;
}

function AddAkt({ handleGet, editableData, setEditableData }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addAkt, { isSuccess: addStatus }] = useAddAktMutation();
  const [editAkt, { isSuccess: editStatus }] = useEditAktMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormAkt) => {
    let { date, service, ...rest } = val;
    let obj = {
      ...rest,
      service: +service,
      date: val.date?.format("YYYY-MM-DD") || "",
    };

    if (editableData) editAkt({ id: editableData.id, body: obj });
    else addAkt(obj);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Majburiyat muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`Akt ma'lumotlari muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { date, service, customer, ...rest } = editableData;

      form.setFieldsValue({
        date: moment(date),
        service: String(service.id),
        customer: String(customer.id),
        ...rest,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? t("subHelper.editAktTitle")
              : t("subHelper.addAktTitle")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item
            name="number"
            label={`${t("subHelper.aktNumber")} *"`}
            rules={[{ required: true, message: t("subHelper.enterAktNumber") }]}
            className="half"
          >
            <Input />
          </Form.Item>

          <DateFormItem className="half" label={t("subHelper.aktDate")} />

          <ContingentFormItem
            name="customer"
            title={t("subCash.customer")}
            required
            message={t("subCash.selectCustomer")}
          />

          <Form.Item
            name="amount"
            label={`${t("common.amount")} (${t("common.uzs")})`}
            className="half"
            rules={[{ required: true, message: t("common.enterAmount") }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="cost"
            label={`${t("common.value")} (${t("common.uzs")})`}
            className="half"
            rules={[{ required: true, message: t("common.enterValue") }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <DirectionFormItem
            className="half"
            label={t("subHelper.serviceType")}
            name="service"
          />

          <Form.Item
            name="comment"
            label={`${t("common.comment")} *`}
            rules={[{ required: true, message: "Izohni kiriting!" }]}
            className="full"
          >
            <Input.TextArea />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddAkt;
