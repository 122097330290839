import { CurrencyAttributes } from "src/app/services/type";
import "./transfercard.scss";
import { useTranslation } from "react-i18next";

interface Props {
  name?: string;
  price?: number;
  currency?: CurrencyAttributes;
  icon: React.ReactNode;
  className: string;
}

function TrasnsferCard({ name, price, currency, icon, className }: Props) {
  const { t } = useTranslation();
  return (
    <div className={`transfercard ${className}`}>
      <div className="transfercard-header">{name}</div>

      <div className="transfercard-footer">
        <div className="transfercard-price">
          <p>{t("subCash.balans")}:</p>
          <h2>
            {currency === "usd" && "$ "}
            {price && Number(price)?.toLocaleString()}
            {currency !== "usd" && t(`common.${currency ?? "uzs"}`)}
          </h2>
        </div>
        <div className="transfercard-name">
          {icon}
          {name}
        </div>
      </div>
    </div>
  );
}

export default TrasnsferCard;
