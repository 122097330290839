import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IContingent,
  IContingentTable,
  ITeamRes,
} from "../services/management/type";
import { managementApi } from "../services/management";

export interface IContingentState {
  contingents?: IContingent[];
  contingentsAll?: IContingent[];
  teams?: ITeamRes["results"];
}

const initialState: IContingentState = {
  contingents: undefined,
};

const contingentSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    saveProjects: (
      state,
      action: PayloadAction<IContingentState["contingents"]>
    ) => {
      state.contingents = action.payload;
    },
    updateTeams: (state, action) => {
      state.teams = action.payload;
    },
    updateContingent: (state, action) => {
      state.contingents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      managementApi.endpoints.getContingent.matchFulfilled,
      (state, action) => {
        let arr: IContingentTable[] = [];
        action.payload.results.forEach((item, index) => {
          arr.push({ key: item.id, index: index + 1, ...item });
        });
        state.contingents = arr;
      }
    );
    builder.addMatcher(
      managementApi.endpoints.getContingentAll.matchFulfilled,
      (state, action) => {
        state.contingentsAll = action.payload.results;
      }
    );
    builder.addMatcher(
      managementApi.endpoints.getTeamM.matchFulfilled,
      (state, action) => {
        state.teams = action.payload.results;
      }
    );
  },
});

export const { saveProjects, updateTeams, updateContingent } =
  contingentSlice.actions;

export default contingentSlice.reducer;
