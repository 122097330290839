import { Col, Form, FormItemProps, Input, InputProps } from "antd";
import { MaskedInput } from "antd-mask-input";
import { MaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput";

type CombinedType = FormItemProps & InputProps & Partial<MaskedInputProps>;

export interface InputFormItemProps extends CombinedType {
  col?: number;
  message?: string;
  textarea?: boolean;
  row?: number;
}

function InputFormItem({ col = 12, name, label, message, mask, textarea, row = 2, ...rest }: InputFormItemProps) {
  return (
    <Col span={col}>
      <Form.Item name={name} label={label} rules={[{ required: !!message, message }]}>
        {textarea ? (
          <Input.TextArea rows={row} />
        ) : mask ? (
          <MaskedInput mask={mask} {...rest} className="form-item-input-mask" />
        ) : (
          <Input {...rest} />
        )}
      </Form.Item>
    </Col>
  );
}

export default InputFormItem;
