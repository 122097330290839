import { makeDateFormat, makeMonthFormat } from "src/utils";
import { Gutter } from "antd/es/grid/row";
import { TFunction } from "i18next";

const { innerWidth, innerHeight } = window;

export type TFunctionType = TFunction<"translation", undefined>;

export const cursorTypist = {
  show: true,
  blink: true,
  element: "",
  hideWhenDone: true,
  hideWhenDoneDelay: 250,
};

export const tableHeight = innerWidth > 1240 ? innerHeight - 281 : innerHeight - 333.25;

export const isMobile = innerWidth < 600;
export const isTablet = innerWidth < 800;

export const isLaptopSlider = innerWidth < 1281;
export const isTabletSlider = innerWidth < 949;
export const isMobileSlider = innerWidth < 638;
export const isSidebarFixed = innerWidth < 849;
export const isMobileChart = innerWidth < 465;

export const isTabletPartnersSlider = innerWidth < 1047;
export const isMobilePartnersSlider = innerWidth < 810;
export const isMobilePartnersSlider2 = innerWidth < 552;
export const isMobilePartnersSlider3 = innerWidth < 712;
export const isMobilePartnersSlider4 = innerWidth < 480;
export const window_757 = innerWidth < 757;

export const isMobileForm = innerWidth < 405;

export const isMenuCollapsed = innerWidth < 1150;

export const phonePattern = /^\(\d{2}\)-\d{3}-\d{2}-\d{2}$/;

export const today = new Date();
export const formattedToday = makeDateFormat(today);
export const formattedThisMonth = makeMonthFormat(today);

export const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
export const formattedYesterday = makeDateFormat(yesterday);

const lastMonthDate = new Date();
let lastMonth = lastMonthDate.getMonth() - 1;
let year = lastMonthDate.getFullYear();

if (lastMonth < 0) {
  lastMonth = 11; // December
  year--; // Go to the previous year
}

lastMonthDate.setMonth(lastMonth);
lastMonthDate.setFullYear(year);
export const formattedLastMonth = makeMonthFormat(lastMonthDate);

export const positionOptions = [
  {
    value: "ceo",
    label: "Bosh direktor",
  },
  {
    value: "cfo",
    label: "Moliyaviy operator",
  },
  {
    value: "accountant",
    label: "Bugalter",
  },
  {
    value: "employee",
    label: "Xodim",
  },
  {
    value: "chief_sales_manager",
    label: "Bosh sotuv manager",
  },
  {
    value: "sales_manager",
    label: "Sotuv manager",
  },
  {
    value: "operator",
    label: "Operator",
  },
  {
    value: "smm",
    label: "SMM",
  },
  {
    value: "marketolog",
    label: "Marketolog",
  },
];

export const currencyOptions = [
  {
    value: "usd",
    label: "Dollar",
  },
  {
    value: "uzs",
    label: "So'm",
  },
  {
    value: "rub",
    label: "Rubl",
  },
  {
    value: "eur",
    label: "Yevro",
  },
  {
    value: "gbp",
    label: "Funt",
  },
];

export const expenseTypeOptions = [
  {
    value: "cost",
    label: "Tannarx",
  },
  {
    value: "overhead",
    label: "Nakladnoy xarajatlar",
  },
  {
    value: "administrative",
    label: "Administrative xarajatlar",
  },
  {
    value: "commercial",
    label: "Tijoriy xarajatlar",
  },
  {
    value: "tax",
    label: "Soliqlar",
  },
  {
    value: "deprecation",
    label: "Amortizatsiya",
  },
];

export const monthOptions = [
  {
    value: "1",
    label: "Yanvar",
  },
  {
    value: "2",
    label: "Fevral",
  },
  {
    value: "3",
    label: "Mart",
  },
  {
    value: "4",
    label: "Aprel",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "Iyun",
  },
  {
    value: "7",
    label: "Iyul",
  },
  {
    value: "8",
    label: "Avgust",
  },
  {
    value: "9",
    label: "Sentyabr",
  },
  {
    value: "10",
    label: "Oktyabr",
  },
  {
    value: "11",
    label: "Noyabr",
  },
  {
    value: "12",
    label: "Dekabr",
  },
];
