import { ColumnsType } from "antd/es/table";
import { IAktTable } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const aktColumns = (t: TFunctionType): ColumnsType<IAktTable> => [
  {
    title: t("subHelper.aktNumber"),
    dataIndex: "number",
    key: "number",
  },
  {
    title: t("subHelper.aktDate"),
    dataIndex: "date",
    key: "date",
  },
  {
    title: t("subCash.customer"),
    dataIndex: "customer",
    key: "customer",
    render: (val) => val.first_name || "",
  },
  {
    title: `${t("common.amount")} (${t("common.uzs")})`,
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: `${t("common.value")} (${t("common.uzs")})`,
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: t("subHelper.serviceType"),
    dataIndex: "service",
    key: "service",
    render: (val) => val.title,
  },
];
