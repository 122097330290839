import { ColumnsType } from "antd/es/table";
import { IMonitorResData } from "src/app/services/finance/type";
import { Tag } from "antd";
import { Statistic } from "antd";
import { TFunctionType } from "src/static/constants";

export const monitorColumns = (t: TFunctionType): ColumnsType<IMonitorResData> => {
  return [
    {
      title: t("subCash.bankBalance"),
      dataIndex: "bank_balance",
      key: "bank_balance",
      render: (val) => <Statistic value={val} className="table-statistic" />,
    },
    {
      title: t("subCash.systemBalance"),
      dataIndex: "account_balance",
      key: "account_balance",
      render: (val) => <Statistic value={val} className="table-statistic" />,
    },
    {
      title: t("subCash.difesit"),
      dataIndex: "remaining",
      key: "remaining",
      render: (_, { remaining, account }) => (
        <Tag color={remaining < 0 ? "red" : remaining === 0 ? "cyan" : "geekblue"}>
          {account.currency === "usd" && "$ "}
          {remaining}
          {account.currency !== "usd" && t(`common.${account.currency}`)}
        </Tag>
      ),
    },
    {
      title: t("common.date"),
      dataIndex: "date",
      key: "date",
    },
  ];
};
