import { Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { hostName } from "src/app/services/api/const";
import {
  useGenerateCashflowMutation,
  useGetBudgetPnlDiagramMutation,
  useGetPnlMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import {
  cashFlowPredictedColumns,
  makePredColumn,
} from "src/pages/sub_control_reports/cashflow/constants";
import { ICashflowPredColumns } from "src/pages/sub_control_reports/cashflow/type";
import "src/pages/sub_control_reports/pnl/pnl.scss";
import { handleMakeUrlNoPage, redirectToNewTab } from "src/utils";

function BudgetPnlByMonthPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { screenMode } = useTypedSelector((state) => state.layout);
  const [getBudgetPnl, { isLoading: loading1 }] =
    useGetBudgetPnlDiagramMutation();
  const [getPnl, { isLoading: loading2 }] = useGetPnlMutation();
  const loading = loading1 || loading2;

  useEffect(() => {
    let url = handleMakeUrlNoPage(searchParams);
    getBudgetPnl("type=year" + url);
    getPnl("type=year" + url);
  }, [searchParams]);

  const { pnl, budget_pnl } = useTypedSelector((state) => state.pnl);

  const data: ICashflowPredColumns[] | undefined = pnl &&
    budget_pnl && [
      {
        key: "profits_green",
        sources: t("subCash.profit"),
        ...pnl?.profit,
        ...budget_pnl?.profit,
        children: makePredColumn(pnl?.profit_detail, budget_pnl?.profit_detail),
      },
      {
        key: "costs_pink",
        sources: t("common.value"),
        ...pnl?.cost,
        ...budget_pnl?.cost,
      },
      {
        key: "costs_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.cost_percentages,
        ...budget_pnl?.cost_percentages,
        children: makePredColumn(pnl?.cost_detail, budget_pnl?.cost_detail),
      },
      {
        key: "margin_green",
        sources: t("subCash.marginProfit"),
        ...pnl?.margin,
        ...budget_pnl?.margin,
      },
      {
        key: "margin_percentages_green",
        sources: t("subCash.marginPercent"),
        ...pnl?.margin_percentages,
        ...budget_pnl?.margin_percentages,
        children: makePredColumn(pnl?.margin_detail, budget_pnl?.margin_detail),
      },
      {
        key: "overhead_pink",
        sources: t("subCash.overheadOutcome"),
        ...pnl?.overhead,
        ...budget_pnl?.overhead,
      },
      {
        key: "overhead_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.overhead_percentages,
        ...budget_pnl?.overhead_percentages,
        children: makePredColumn(
          pnl?.overhead_detail,
          budget_pnl?.overhead_detail
        ),
      },
      {
        key: "gross_green",
        sources: t("subCash.grossAll"),
        ...pnl?.gross,
        ...budget_pnl?.gross,
      },
      {
        key: "gross_percentages_green",
        sources: t("subCash.grossRentabl"),
        ...pnl?.gross_percentages,
        ...budget_pnl?.gross_percentages,
      },
      {
        key: "administrative_pink",
        sources: t("common.administrativeOutcome"),
        ...pnl?.administrative,
        ...budget_pnl?.administrative,
      },
      {
        key: "administrative_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.administrative_percentages,
        ...budget_pnl?.administrative_percentages,
        children: makePredColumn(
          pnl?.administrative_detail,
          budget_pnl?.administrative_detail
        ),
      },
      {
        key: "commercial_pink",
        sources: t("common.commersialOutcome"),
        ...pnl?.commercial,
        ...budget_pnl?.commercial,
      },
      {
        key: "commercial_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.commercial_percentages,
        ...budget_pnl?.commercial_percentages,
        children: makePredColumn(
          pnl?.commercial_detail,
          budget_pnl?.commercial_detail
        ),
      },
      {
        key: "operative_green",
        sources: t("subCash.operativeAll"),
        ...pnl?.operative,
        ...budget_pnl?.operative,
      },
      {
        key: "operative_percentages_green",
        sources: t("subCash.operativRentabl"),
        ...pnl?.operative_percentages,
        ...budget_pnl?.operative_percentages,
      },
      {
        key: "tax_pink",
        sources: t("common.taxes"),
        ...pnl?.tax,
        ...budget_pnl?.tax,
      },
      {
        key: "tax_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.tax_percentages,
        ...budget_pnl?.tax_percentages,
        children: makePredColumn(pnl?.tax_detail, budget_pnl?.tax_detail),
      },
      {
        key: "deprecation_pink",
        sources: t("subCash.deprecation"),
        ...pnl?.deprecation,
        ...budget_pnl?.deprecation,
      },
      {
        key: "deprecation_percentages_pink",
        sources: t("subCash.profitPercent"),
        ...pnl?.deprecation_percentages,
        ...budget_pnl?.deprecation_percentages,
        children: makePredColumn(
          pnl?.deprecation_detail,
          budget_pnl?.deprecation_detail
        ),
      },
      {
        key: "soft_green",
        sources: t("subCash.sof"),
        ...pnl?.soft,
        ...budget_pnl?.soft,
      },
      {
        key: "soft_percentages_green",
        sources: t("subCash.sofRentabl"),
        ...pnl?.soft_percentages,
        ...budget_pnl?.soft_percentages,
      },
    ];

  //Generate section
  const [generateCashflow, { data: generatedUrl }] =
    useGenerateCashflowMutation();

  const generateExcel = () => {
    generateCashflow();
  };

  useEffect(() => {
    if (generatedUrl) redirectToNewTab(hostName + generatedUrl.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedUrl]);

  //Table height
  const [tableHeight, setTableHeight] = useState<number>();
  useEffect(() => {
    setTimeout(() => {
      setTableHeight(window.innerHeight - 163);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenMode]);

  return (
    <div className="pnl">
      <ContentTop>
        <div className="flex">
          {/* <CustomButton className="content-top-btn" height={38} onClick={generateExcel}>
            <div className="content-top-btn-content">
              <DownloadOutlined /> Excel yuklash
            </div>
          </CustomButton> */}
          <FilterDate format="year" />
        </div>
      </ContentTop>

      <div className="table">
        <Table
          scroll={{ x: 1200, y: tableHeight }}
          bordered={true}
          columns={cashFlowPredictedColumns(t)}
          dataSource={data}
          pagination={false}
          loading={loading}
          className="pnl_table"
        />
      </div>
    </div>
  );
}

export default BudgetPnlByMonthPage;
