import { Form, Input, Radio, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddContingentMutation,
  useEditContingentMutation,
} from "src/app/services/management";
import { IContingent, IContingentForm } from "src/app/services/management/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { clearMask, makePhoneMask } from "src/utils";

interface Props {
  customTitle?: string;
  handleGet: () => void;
  editableData?: IContingent;
  setEditableData: React.Dispatch<
    React.SetStateAction<IContingent | undefined>
  >;
}

function AddContingent({
  handleGet,
  editableData,
  setEditableData,
  customTitle = "common.add",
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addAccount, { isLoading: addLoading, isSuccess: addStatus }] =
    useAddContingentMutation();
  const [editAccount, { isLoading: edidtLoading, isSuccess: editStatus }] =
    useEditContingentMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IContingentForm) => {
    const { type, ...rest } = val;
    if (val.phone) rest.phone = clearMask(val.phone);
    rest.is_customer = type === "is_customer";
    rest.is_supplier = type === "is_supplier";
    rest.is_contingent_member = true;

    if (editableData) editAccount({ id: editableData.id, body: rest });
    else addAccount(rest);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Account added successfully!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`${editableData?.first_name} edited successfully!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { first_name, phone, is_customer } = editableData;
      form.setFieldsValue({
        first_name,
        phone: phone ? makePhoneMask(phone) : null,
        type: is_customer ? "is_customer" : "is_supplier",
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t(customTitle)}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? editableData.first_name + " " + t("subHelper.editInformation")
              : t("common.addContingent")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item
            name="first_name"
            label={`${t("common.nameContingent")} *`}
            rules={[{ required: true, message: t("common.enterContingent") }]}
            className="full"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label={t("subInfo.phoneNumber")}
            className="full"
          >
            <MaskedInput
              className="phoneInput"
              addonBefore="+998"
              mask="(00)-000-00-00"
            />
          </Form.Item>

          <Form.Item
            name="type"
            label={t("common.typeContingent")}
            rules={[{ required: true, message: t("common.selectContingent") }]}
            className="full"
          >
            <Radio.Group>
              <Radio value="is_customer">{t("subCash.customer")}</Radio>
              <Radio value="is_supplier">{t("subCash._suplier")}</Radio>
            </Radio.Group>
          </Form.Item>

          <ModalFooter
            onCancel={closeModal}
            okLoading={addLoading || edidtLoading}
          />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddContingent;
