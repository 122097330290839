import { ColumnsType } from "antd/es/table";
import { IAccount } from "src/app/services/finance/type";
import { Statistic } from "antd";
import { TFunctionType } from "src/static/constants";

export const accountColumns = (t: TFunctionType): ColumnsType<IAccount> => [
  {
    title: t("common.name"),
    dataIndex: "title",
    key: "title",
  },
  {
    title: t("subInfo.initial_money"),
    dataIndex: "initial_balance",
    key: "initial_balance",
    render: (val) => <Statistic value={val} className="table-statistic" />,
  },
  {
    title: t("common.currency"),
    dataIndex: "currency",
    key: "currency",
    render: (val) => t(`common.${val}`),
  },
];

export const accountAsInfoColumns = (t: TFunctionType): ColumnsType<IAccount> => [
  {
    title: t("common.name"),
    dataIndex: "title",
    key: "title",
  },
  {
    title: t("subCash.balans"),
    dataIndex: "balance",
    key: "balance",
    render: (val) => (
      <div className="source">
        <div className={`status-column ${+val < 0 ? "outcome" : "income"}`}>
          <Statistic value={val} className="table-statistic" />
        </div>
      </div>
    ),
  },
  {
    title: t("common.currency"),
    dataIndex: "currency",
    key: "currency",
    render: (val) => t(`common.${val}`),
  },
];

export const accountFormData = (
  t: TFunctionType
): {
  name: string;
  label: string;
  placeholder: string;
  message?: string;
}[] => [
  {
    name: "title",
    label: t("subInfo.nameAccount"),
    placeholder: t("subInfo.enterAccount"),
    message: t("subInfo.enterAccount"),
  },
  {
    name: "currency",
    label: t("common.currency"),
    placeholder: t("common.selectCurrency"),
  },
  {
    name: "initial_balance",
    label: t("subInfo.balanceAccount"),
    placeholder: t("subInfo.enterBalanceAccount"),
    message: t("subInfo.enterBalanceAccount"),
  },
];
