import { Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteAccountMutation,
  useGetAccountMutation,
} from "src/app/services/finance";
import { IAccount } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import AddAccount from "./components/AddAccount";
import { accountAsInfoColumns, accountColumns } from "./constants";
import { useTranslation } from "react-i18next";

interface Props {
  asInfo?: boolean;
}

function AccountPage({ asInfo }: Props) {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getAccount, { data: accountsData, isLoading }] =
    useGetAccountMutation();
  const { accounts } = useTypedSelector((state) => state.account);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getAccount(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteAccount, { isSuccess: deleteStatus }] =
    useDeleteAccountMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IAccount>();

  const onEdit = (val: number) => {
    let obj = accounts?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  //Table section
  const columns: ColumnsType<IAccount> = [
    ...accountColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key) => (
        <TableActions
          onDelete={() => deleteAccount(key)}
          onEdit={() => onEdit(key)}
        />
      ),
    },
  ];

  return (
    <div className=" account">
      {!asInfo && (
        <ContentTop>
          <AddAccount
            handleGet={handleGet}
            editableData={editableData}
            setEditableData={setEditableData}
          />
        </ContentTop>
      )}

      <Table
        columns={asInfo ? accountAsInfoColumns(t) : columns}
        dataSource={accounts}
        scroll={{ x: 800 }}
        className="account-table"
        pagination={false}
        style={asInfo ? { borderRadius: 0 } : {}}
        loading={isLoading}
      />
      {accountsData && <PaginationFilter total={accountsData?.count} />}
    </div>
  );
}

export default AccountPage;
