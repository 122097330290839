import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import {
  IAccountStat,
  IBalanceStat,
  IIcomeOutcomeStat,
  ISourceOtcomeStat,
  ISourceStat,
} from "../services/finance/type";
import { isTabletSlider } from "src/static/constants";
import {
  ICashflowPredTable,
  ICashflowPredTableItem,
  ICashflowTable,
  ICashflowTableItem,
} from "src/pages/sub_control_reports/cashflow/type";
import { prettierNumber } from "src/utils";

export interface IStatState {
  balancesStat?: IBalanceStat;
  incomeOutcomeStat?: IIcomeOutcomeStat;
  accountsStat?: IAccountStat;
  sorcesStat?: ISourceStat;
  sorcesOutcomeStat?: ISourceOtcomeStat;
  cashflows?: ICashflowTable;
  cashflowsPred?: ICashflowPredTable;
}

const initialState: IStatState = {
  balancesStat: undefined,
};

const statSlice = createSlice({
  name: "stat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getCashBalanceStat.matchFulfilled, (state, action) => {
      let obj: IBalanceStat = {
        xAxisData: [],
        yAxisData: [],
        total: 0,
        ...action.payload,
      };
      let xAxisArr: string[] = [];
      let yAxisArr: number[] = [];
      let totalVal = 0;
      const date = new Date();

      for (const [key, value] of Object.entries(obj.data)) {
        xAxisArr.push(String(key));
        if (action.payload.year === date.getFullYear() && action.payload.month === date.getMonth() + 1) {
          if (date.getDate() >= +key) {
            yAxisArr.push(Number(value));
          }
          if (date.getDate() === +key) {
            totalVal = Number(value);
          }
        } else {
          yAxisArr.push(Number(value));
          totalVal = Number(value);
        }
      }

      obj.xAxisData = xAxisArr;
      obj.yAxisData = yAxisArr;
      obj.total = totalVal;
      state.balancesStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getIncomeOutcomeStat.matchFulfilled, (state, action) => {
      let obj: IIcomeOutcomeStat = {
        incomeData: [],
        outcomeData: [],
        ...action.payload,
      };
      let incomeArr: number[] = [];
      let outcomeArr: number[] = [];

      for (let item in obj.data) {
        incomeArr.push(obj.data[item]?.income);
        outcomeArr.push(obj.data[item]?.outcome);
      }

      obj.incomeData = incomeArr;
      obj.outcomeData = outcomeArr;
      state.incomeOutcomeStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getAccountsStat.matchFulfilled, (state, action) => {
      let obj: IAccountStat = {
        series: [],
      };

      action.payload?.forEach((item, index) => {
        let valArr: number[] = [];
        for (let el in item.data) {
          valArr.push(Number(item.data[el]));
        }
        obj.series.push({
          barCategoryGap: "40%",
          name: item.title,
          type: "bar",
          stack: "total",
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 4,
          },

          label: {
            show: true,
            // formatter: "{c}%",
          },
          emphasis: {
            focus: "series",
          },
          data: valArr,
        });
      });

      state.accountsStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getSourcesStat.matchFulfilled, (state, action) => {
      let obj: ISourceStat = {
        series: [],
        total: 0,
      };

      action.payload?.forEach((item) => {
        let val = +item.total_outcome;
        obj.total = obj.total + val;
      });

      action.payload?.forEach((item) => {
        let val = +item.total_outcome;
        obj.series.push({
          value: val,
          name: item.title,
          data: {
            value: Number(val),
            percent: obj.total ? Math.round((val / obj.total) * 100 * 100) / 100 : 0,
            prettier_value: prettierNumber(val),
          },
        });
      });

      state.sorcesStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getDirectionsStat.matchFulfilled, (state, action) => {
      let obj: ISourceStat = {
        series: [],
        total: 0,
      };

      action.payload?.forEach((item) => {
        let val = +item.total_outcome;
        obj.total = obj.total + val;
      });

      action.payload?.forEach((item) => {
        let val = +item.total_outcome;
        obj.series.push({
          value: val,
          name: item.title,
          data: {
            value: Number(val),
            percent: obj.total ? Math.round((val / obj.total) * 100 * 100) / 100 : 0,
            prettier_value: prettierNumber(val),
          },
        });
      });

      state.sorcesStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getSourcesOutcomeStat.matchFulfilled, (state, action) => {
      let obj: ISourceOtcomeStat = {
        series: [],
      };

      action.payload?.forEach((item) => {
        interface IVal {
          value: number;
          percent: number;
          prettier_value: string;
        }
        let valArr: IVal[] = [];
        for (let el in item.data) {
          let total = action.payload[0].data[el] + action.payload[1].data[el] + action.payload[2].data[el];
          let val = item.data[el];

          valArr.push({
            value: Number(val),
            percent: total ? Math.round((val / total) * 100 * 100) / 100 : 0,
            prettier_value: prettierNumber(val),
          });
        }

        obj.series.push({
          barCategoryGap: isTabletSlider ? "20%" : "40%",
          name: item.title,
          type: "bar",
          stack: "total",
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 4,
          },

          label: {
            show: false,
            formatter: "{c}%",
          },
          percent: 25,
          emphasis: {
            focus: "series",
          },
          data: valArr,
        });
      });

      state.sorcesOutcomeStat = obj;
    });

    builder.addMatcher(financeApi.endpoints.getCashflow.matchFulfilled, (state, action) => {
      let obj: ICashflowTable = {
        type1_income: [],
        type1_outcome: [],
        type2_income: [],
        type2_outcome: [],
        type3_income: [],
        type3_outcome: [],
      };

      action.payload?.cashflow.forEach((item, index) => {
        let incomeArr: ICashflowTableItem[] = [];
        let outcomeArr: ICashflowTableItem[] = [];

        let incomeTotal: ICashflowTableItem = {
          key: "income" + item.title,
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          june: 0,
          july: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        };
        let outcomeTotal: ICashflowTableItem = {
          key: "outcome" + item.title,
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          june: 0,
          july: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
        };

        let changeObj = {
          key: item.title + "change",
          jan: item.remaining["1"],
          feb: item.remaining["2"],
          mar: item.remaining["3"],
          apr: item.remaining["4"],
          may: item.remaining["5"],
          june: item.remaining["6"],
          july: item.remaining["7"],
          aug: item.remaining["8"],
          sep: item.remaining["9"],
          oct: item.remaining["10"],
          nov: item.remaining["11"],
          dec: item.remaining["12"],
        };

        item.income_sources.forEach((el) => {
          incomeArr.push({
            key: el.title + index + "income",
            sources: el.title,
            jan: el.data["1"],
            feb: el.data["2"],
            mar: el.data["3"],
            apr: el.data["4"],
            may: el.data["5"],
            june: el.data["6"],
            july: el.data["7"],
            aug: el.data["8"],
            sep: el.data["9"],
            oct: el.data["10"],
            nov: el.data["11"],
            dec: el.data["12"],
          });

          el.data["1"] && (incomeTotal.jan = incomeTotal.jan + el.data["1"]);
          el.data["2"] && (incomeTotal.feb = incomeTotal.feb + el.data["2"]);
          el.data["3"] && (incomeTotal.mar = incomeTotal.mar + el.data["3"]);
          el.data["4"] && (incomeTotal.apr = incomeTotal.apr + el.data["4"]);
          el.data["5"] && (incomeTotal.may = incomeTotal.may + el.data["5"]);
          el.data["6"] && (incomeTotal.june = incomeTotal.june + el.data["6"]);
          el.data["7"] && (incomeTotal.july = incomeTotal.july + el.data["7"]);
          el.data["8"] && (incomeTotal.aug = incomeTotal.aug + el.data["8"]);
          el.data["9"] && (incomeTotal.sep = incomeTotal.sep + el.data["9"]);
          el.data["10"] && (incomeTotal.oct = incomeTotal.oct + el.data["10"]);
          el.data["11"] && (incomeTotal.nov = incomeTotal.nov + el.data["11"]);
          el.data["12"] && (incomeTotal.dec = incomeTotal.dec + el.data["12"]);
        });

        item.outcome_sources.forEach((el) => {
          outcomeArr.push({
            key: el.title + index + "outcome",
            sources: el.title,
            jan: el.data["1"],
            feb: el.data["2"],
            mar: el.data["3"],
            apr: el.data["4"],
            may: el.data["5"],
            june: el.data["6"],
            july: el.data["7"],
            aug: el.data["8"],
            sep: el.data["9"],
            oct: el.data["10"],
            nov: el.data["11"],
            dec: el.data["12"],
          });

          el.data["1"] && (outcomeTotal.jan = outcomeTotal.jan + el.data["1"]);
          el.data["2"] && (outcomeTotal.feb = outcomeTotal.feb + el.data["2"]);
          el.data["3"] && (outcomeTotal.mar = outcomeTotal.mar + el.data["3"]);
          el.data["4"] && (outcomeTotal.apr = outcomeTotal.apr + el.data["4"]);
          el.data["5"] && (outcomeTotal.may = outcomeTotal.may + el.data["5"]);
          el.data["6"] && (outcomeTotal.june = outcomeTotal.june + el.data["6"]);
          el.data["7"] && (outcomeTotal.july = outcomeTotal.july + el.data["7"]);
          el.data["8"] && (outcomeTotal.aug = outcomeTotal.aug + el.data["8"]);
          el.data["9"] && (outcomeTotal.sep = outcomeTotal.sep + el.data["9"]);
          el.data["10"] && (outcomeTotal.oct = outcomeTotal.oct + el.data["10"]);
          el.data["11"] && (outcomeTotal.nov = outcomeTotal.nov + el.data["11"]);
          el.data["12"] && (outcomeTotal.dec = outcomeTotal.dec + el.data["12"]);
        });

        if (item.title === "Жорий пул оқими") {
          obj.type1_income = incomeArr;
          obj.type1_outcome = outcomeArr;
          obj["type1_change"] = changeObj;
          obj["type1_income_total"] = incomeTotal;
          obj["type1_outcome_total"] = outcomeTotal;
        } else if (item.title === "Молиявий пул оқими") {
          obj.type2_income = incomeArr;
          obj.type2_outcome = outcomeArr;
          obj["type2_change"] = changeObj;
          obj["type2_income_total"] = incomeTotal;
          obj["type2_outcome_total"] = outcomeTotal;
        } else if (item.title === "Инвестицион пул оқими") {
          obj.type3_income = incomeArr;
          obj.type3_outcome = outcomeArr;
          obj["type3_change"] = changeObj;
          obj["type3_income_total"] = incomeTotal;
          obj["type3_outcome_total"] = outcomeTotal;
        }
      });

      const { monthly_balances, transactions } = action.payload;

      obj["beginnig_balance"] = {
        key: "beginnig_balance",
        jan: monthly_balances.beginning["1"],
        feb: monthly_balances.beginning["2"],
        mar: monthly_balances.beginning["3"],
        apr: monthly_balances.beginning["4"],
        may: monthly_balances.beginning["5"],
        june: monthly_balances.beginning["6"],
        july: monthly_balances.beginning["7"],
        aug: monthly_balances.beginning["8"],
        sep: monthly_balances.beginning["9"],
        oct: monthly_balances.beginning["10"],
        nov: monthly_balances.beginning["11"],
        dec: monthly_balances.beginning["12"],
      };

      obj["ending_balance"] = {
        key: "ending_balance",
        jan: monthly_balances.end["1"],
        feb: monthly_balances.end["2"],
        mar: monthly_balances.end["3"],
        apr: monthly_balances.end["4"],
        may: monthly_balances.end["5"],
        june: monthly_balances.end["6"],
        july: monthly_balances.end["7"],
        aug: monthly_balances.end["8"],
        sep: monthly_balances.end["9"],
        oct: monthly_balances.end["10"],
        nov: monthly_balances.end["11"],
        dec: monthly_balances.end["12"],
      };

      obj["transfer_income"] = {
        key: "transfer_income",
        jan: transactions["1"].income,
        feb: transactions["2"].income,
        mar: transactions["3"].income,
        apr: transactions["4"].income,
        may: transactions["5"].income,
        june: transactions["6"].income,
        july: transactions["7"].income,
        aug: transactions["8"].income,
        sep: transactions["9"].income,
        oct: transactions["10"].income,
        nov: transactions["11"].income,
        dec: transactions["12"].income,
      };

      obj["transfer_outcome"] = {
        key: "transfer_outcome",
        jan: transactions["1"].outcome,
        feb: transactions["2"].outcome,
        mar: transactions["3"].outcome,
        apr: transactions["4"].outcome,
        may: transactions["5"].outcome,
        june: transactions["6"].outcome,
        july: transactions["7"].outcome,
        aug: transactions["8"].outcome,
        sep: transactions["9"].outcome,
        oct: transactions["10"].outcome,
        nov: transactions["11"].outcome,
        dec: transactions["12"].outcome,
      };

      let total_change: any;
      if (obj.type1_change) {
        total_change = { ...obj.type1_change };

        for (const [key, value] of Object.entries(obj.type1_change)) {
          total_change[key] =
            (value || 0) +
            (obj.type2_change && obj.type2_change[key as keyof ICashflowTableItem]
              ? obj.type2_change[key as keyof ICashflowTableItem]
              : 0) +
            (obj.type3_change && obj.type3_change[key as keyof ICashflowTableItem]
              ? obj.type3_change[key as keyof ICashflowTableItem]
              : 0);

          if (total_change[key] === 0) total_change[key] = "";
        }
      }

      obj["total_change"] = total_change;

      state.cashflows = obj;
    });

    builder.addMatcher(financeApi.endpoints.getCashflowPredicted.matchFulfilled, (state, action) => {
      let obj: ICashflowPredTable = {
        type1_income: [],
        type1_outcome: [],
        type2_income: [],
        type2_outcome: [],
        type3_income: [],
        type3_outcome: [],
      };

      action.payload?.cashflow.forEach((item, index) => {
        let incomeArr: ICashflowPredTableItem[] = [];
        let outcomeArr: ICashflowPredTableItem[] = [];

        let incomeTotal: ICashflowPredTableItem = {
          key: "income_pred" + item.title,
          jan_pred: 0,
          feb_pred: 0,
          mar_pred: 0,
          apr_pred: 0,
          may_pred: 0,
          june_pred: 0,
          july_pred: 0,
          aug_pred: 0,
          sep_pred: 0,
          oct_pred: 0,
          nov_pred: 0,
          dec_pred: 0,
        };
        let outcomeTotal: ICashflowPredTableItem = {
          key: "outcome_pred" + item.title,
          jan_pred: 0,
          feb_pred: 0,
          mar_pred: 0,
          apr_pred: 0,
          may_pred: 0,
          june_pred: 0,
          july_pred: 0,
          aug_pred: 0,
          sep_pred: 0,
          oct_pred: 0,
          nov_pred: 0,
          dec_pred: 0,
        };

        let changeObj = {
          key: item.title + "change_pred",
          jan_pred: item.remaining["1"],
          feb_pred: item.remaining["2"],
          mar_pred: item.remaining["3"],
          apr_pred: item.remaining["4"],
          may_pred: item.remaining["5"],
          june_pred: item.remaining["6"],
          july_pred: item.remaining["7"],
          aug_pred: item.remaining["8"],
          sep_pred: item.remaining["9"],
          oct_pred: item.remaining["10"],
          nov_pred: item.remaining["11"],
          dec_pred: item.remaining["12"],
        };

        item.income_sources.forEach((el) => {
          incomeArr.push({
            key: el.title + index + "income_pred",
            sources: el.title,
            jan_pred: el.data["1"],
            feb_pred: el.data["2"],
            mar_pred: el.data["3"],
            apr_pred: el.data["4"],
            may_pred: el.data["5"],
            june_pred: el.data["6"],
            july_pred: el.data["7"],
            aug_pred: el.data["8"],
            sep_pred: el.data["9"],
            oct_pred: el.data["10"],
            nov_pred: el.data["11"],
            dec_pred: el.data["12"],
          });

          el.data["1"] && (incomeTotal.jan_pred = incomeTotal.jan_pred + el.data["1"]);
          el.data["2"] && (incomeTotal.feb_pred = incomeTotal.feb_pred + el.data["2"]);
          el.data["3"] && (incomeTotal.mar_pred = incomeTotal.mar_pred + el.data["3"]);
          el.data["4"] && (incomeTotal.apr_pred = incomeTotal.apr_pred + el.data["4"]);
          el.data["5"] && (incomeTotal.may_pred = incomeTotal.may_pred + el.data["5"]);
          el.data["6"] && (incomeTotal.june_pred = incomeTotal.june_pred + el.data["6"]);
          el.data["7"] && (incomeTotal.july_pred = incomeTotal.july_pred + el.data["7"]);
          el.data["8"] && (incomeTotal.aug_pred = incomeTotal.aug_pred + el.data["8"]);
          el.data["9"] && (incomeTotal.sep_pred = incomeTotal.sep_pred + el.data["9"]);
          el.data["10"] && (incomeTotal.oct_pred = incomeTotal.oct_pred + el.data["10"]);
          el.data["11"] && (incomeTotal.nov_pred = incomeTotal.nov_pred + el.data["11"]);
          el.data["12"] && (incomeTotal.dec_pred = incomeTotal.dec_pred + el.data["12"]);
        });

        item.outcome_sources.forEach((el) => {
          outcomeArr.push({
            key: el.title + index + "outcome_pred",
            sources: el.title,
            jan_pred: el.data["1"],
            feb_pred: el.data["2"],
            mar_pred: el.data["3"],
            apr_pred: el.data["4"],
            may_pred: el.data["5"],
            june_pred: el.data["6"],
            july_pred: el.data["7"],
            aug_pred: el.data["8"],
            sep_pred: el.data["9"],
            oct_pred: el.data["10"],
            nov_pred: el.data["11"],
            dec_pred: el.data["12"],
          });

          el.data["1"] && (outcomeTotal.jan_pred = outcomeTotal.jan_pred + el.data["1"]);
          el.data["2"] && (outcomeTotal.feb_pred = outcomeTotal.feb_pred + el.data["2"]);
          el.data["3"] && (outcomeTotal.mar_pred = outcomeTotal.mar_pred + el.data["3"]);
          el.data["4"] && (outcomeTotal.apr_pred = outcomeTotal.apr_pred + el.data["4"]);
          el.data["5"] && (outcomeTotal.may_pred = outcomeTotal.may_pred + el.data["5"]);
          el.data["6"] && (outcomeTotal.june_pred = outcomeTotal.june_pred + el.data["6"]);
          el.data["7"] && (outcomeTotal.july_pred = outcomeTotal.july_pred + el.data["7"]);
          el.data["8"] && (outcomeTotal.aug_pred = outcomeTotal.aug_pred + el.data["8"]);
          el.data["9"] && (outcomeTotal.sep_pred = outcomeTotal.sep_pred + el.data["9"]);
          el.data["10"] && (outcomeTotal.oct_pred = outcomeTotal.oct_pred + el.data["10"]);
          el.data["11"] && (outcomeTotal.nov_pred = outcomeTotal.nov_pred + el.data["11"]);
          el.data["12"] && (outcomeTotal.dec_pred = outcomeTotal.dec_pred + el.data["12"]);
        });

        if (item.title === "Жорий пул оқими") {
          obj.type1_income = incomeArr;
          obj.type1_outcome = outcomeArr;
          obj["type1_change"] = changeObj;
          obj["type1_income_total"] = incomeTotal;
          obj["type1_outcome_total"] = outcomeTotal;
        } else if (item.title === "Молиявий пул оқими") {
          obj.type2_income = incomeArr;
          obj.type2_outcome = outcomeArr;
          obj["type2_change"] = changeObj;
          obj["type2_income_total"] = incomeTotal;
          obj["type2_outcome_total"] = outcomeTotal;
        } else if (item.title === "Инвестицион пул оқими") {
          obj.type3_income = incomeArr;
          obj.type3_outcome = outcomeArr;
          obj["type3_change"] = changeObj;
          obj["type3_income_total"] = incomeTotal;
          obj["type3_outcome_total"] = outcomeTotal;
        }
      });

      const { monthly_balances, transactions } = action.payload;

      obj["beginnig_balance"] = {
        key: "beginnig_balance_pred",
        jan_pred: monthly_balances.beginning["1"],
        feb_pred: monthly_balances.beginning["2"],
        mar_pred: monthly_balances.beginning["3"],
        apr_pred: monthly_balances.beginning["4"],
        may_pred: monthly_balances.beginning["5"],
        june_pred: monthly_balances.beginning["6"],
        july_pred: monthly_balances.beginning["7"],
        aug_pred: monthly_balances.beginning["8"],
        sep_pred: monthly_balances.beginning["9"],
        oct_pred: monthly_balances.beginning["10"],
        nov_pred: monthly_balances.beginning["11"],
        dec_pred: monthly_balances.beginning["12"],
      };

      obj["ending_balance"] = {
        key: "ending_balance_pred",
        jan_pred: monthly_balances.end["1"],
        feb_pred: monthly_balances.end["2"],
        mar_pred: monthly_balances.end["3"],
        apr_pred: monthly_balances.end["4"],
        may_pred: monthly_balances.end["5"],
        june_pred: monthly_balances.end["6"],
        july_pred: monthly_balances.end["7"],
        aug_pred: monthly_balances.end["8"],
        sep_pred: monthly_balances.end["9"],
        oct_pred: monthly_balances.end["10"],
        nov_pred: monthly_balances.end["11"],
        dec_pred: monthly_balances.end["12"],
      };

      obj["transfer_income"] = {
        key: "transfer_income_pred",
        jan_pred: transactions["1"].income,
        feb_pred: transactions["2"].income,
        mar_pred: transactions["3"].income,
        apr_pred: transactions["4"].income,
        may_pred: transactions["5"].income,
        june_pred: transactions["6"].income,
        july_pred: transactions["7"].income,
        aug_pred: transactions["8"].income,
        sep_pred: transactions["9"].income,
        oct_pred: transactions["10"].income,
        nov_pred: transactions["11"].income,
        dec_pred: transactions["12"].income,
      };

      obj["transfer_outcome"] = {
        key: "transfer_outcome_pred",
        jan_pred: transactions["1"].outcome,
        feb_pred: transactions["2"].outcome,
        mar_pred: transactions["3"].outcome,
        apr_pred: transactions["4"].outcome,
        may_pred: transactions["5"].outcome,
        june_pred: transactions["6"].outcome,
        july_pred: transactions["7"].outcome,
        aug_pred: transactions["8"].outcome,
        sep_pred: transactions["9"].outcome,
        oct_pred: transactions["10"].outcome,
        nov_pred: transactions["11"].outcome,
        dec_pred: transactions["12"].outcome,
      };

      let total_change: any;
      if (obj.type1_change) {
        total_change = { ...obj.type1_change };

        for (const [key, value] of Object.entries(obj.type1_change)) {
          total_change[key] =
            (value || 0) +
            (obj.type2_change && obj.type2_change[key as keyof ICashflowPredTableItem]
              ? obj.type2_change[key as keyof ICashflowPredTableItem]
              : 0) +
            (obj.type3_change && obj.type3_change[key as keyof ICashflowPredTableItem]
              ? obj.type3_change[key as keyof ICashflowPredTableItem]
              : 0);

          if (total_change[key] === 0) total_change[key] = "";
        }
      }

      obj["total_change"] = total_change;

      state.cashflowsPred = obj;
    });
  },
});

export default statSlice.reducer;
