import { Col, DatePicker, DatePickerProps, Form, FormItemProps } from "antd";
import { useTranslation } from "react-i18next";

type DateFormItemProps = DatePickerProps &
  FormItemProps & {
    col?: number;
    message?: string;
    format?: "date" | "month" | "year";
  };

export default function DateFormItem({
  col = 12,
  name = "date",
  label,
  message,
  format = "date",
  ...rest
}: DateFormItemProps) {
  const { t } = useTranslation();
  return (
    <Col span={col}>
      <Form.Item
        name={name}
        label={label ?? t("common.selectDate")}
        rules={
          !!message
            ? [{ required: true, message: message ?? t("common.selectDate") }]
            : []
        }
      >
        <DatePicker
          picker={format}
          style={{ width: "100%" }}
          placeholder={
            format === "year"
              ? "____"
              : format === "month"
              ? "____-__"
              : "____-__-__"
          }
          {...rest}
        />
      </Form.Item>
    </Col>
  );
}
