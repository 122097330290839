import { Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteCommitmentMutation,
  useGetCommitmentMutation,
} from "src/app/services/finance";
import { ICommitmentTable } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import { commitmentColumns } from "./constants";
import AddCommitment from "./add";
import { useTranslation } from "react-i18next";

function CommitmentPage() {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getFunc, { data }] = useGetCommitmentMutation();
  const { commitments } = useTypedSelector((state) => state.commitment);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getFunc(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteFunc, { isSuccess: deleteStatus }] =
    useDeleteCommitmentMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<ICommitmentTable>();

  const onEdit = (val: number) => {
    let obj = commitments?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  //Table section
  const columns: ColumnsType<ICommitmentTable> = [
    ...commitmentColumns(t),
    {
      title: t("common.comment"),
      dataIndex: "comment",
      key: "comment",
      render: (val) => val,
    },
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key) => (
        <TableActions
          onDelete={() => deleteFunc(key)}
          onEdit={() => onEdit(key)}
        />
      ),
    },
  ];

  return (
    <div>
      <ContentTop>
        <AddCommitment
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      <Table
        columns={columns}
        dataSource={commitments}
        scroll={{ x: 800 }}
        pagination={false}
      />
      {data && <PaginationFilter total={data?.count} />}
    </div>
  );
}

export default CommitmentPage;
