import {
  AboutMoneyBgSvg,
  AnalysesSvg,
  ControlPanelSvg,
  FinanceAdvicesSvg,
  FinanceLessonsSvg,
  RobotSvg,
  VideoSvg,
} from "src/assets/svg/homeIcons";
import "./about.scss";
import { AboutCardData, coinImage } from "./constants";
import { useTranslation } from "react-i18next";

const aboutIcons = [
  <RobotSvg />,
  <AnalysesSvg />,
  <FinanceAdvicesSvg />,
  <FinanceLessonsSvg />,
  <ControlPanelSvg />,
  <VideoSvg />,
];

function About() {
  const { t } = useTranslation();
  return (
    <div className="section-about" id="about">
      <div className="about-wrap container">
        <div className="about-wrap-header">
          <div className="about-wrap-header-img">
            <img
              src={require("src/assets/img/home/about-money.png")}
              alt="money"
            />
            <AboutMoneyBgSvg />
          </div>
          {coinImage.map((img) => (
            <img
              className={`coin ${img}`}
              src={require("src/assets/img/home/coin.jpg")}
              alt="coin"
              key={img}
            />
          ))}
        </div>
        <h2>
          {t("home.about.title1")}
          <span> {t("home.about.title2")}</span>
        </h2>
        <div className="about-cards">
          {AboutCardData.map((card, i) => (
            <div className="about-card" key={i}>
              <div className="about-card-top">{aboutIcons[i]}</div>
              <h4>{t(card.title)}</h4>
              <div
                className={card.offsetLine ? "about-card-offset-line" : ""}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;
