import CountUp from "react-countup";
import "../dashboard.scss";
import CustomSelect from "src/components/common/select";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  amount?: number;
  onFilterChange?: (val: string) => void;
}

function CashCard({ title, amount, onFilterChange }: Props) {
  const { t } = useTranslation();
  const cashflowOptions = [
    { label: t("common.barchasi"), value: "all" },
    { label: t("common.currentMoneyFlow"), value: "Жорий пул оқими" },
    { label: t("common.financialMoneyFlow"), value: "Молиявий пул оқими" },
    { label: t("common.invistationMoneyFlow"), value: "Инвестицион пул оқими" },
  ];
  return (
    <div className="dashboard-card cash-card">
      <div className="dashboard-card-title">
        <p>{title}</p>
        {onFilterChange && (
          <CustomSelect
            height={32}
            colorTextPlaceholder="#fff"
            colorBorder="#878a99"
            options={cashflowOptions}
            defaultValue="all"
            onChange={onFilterChange}
            style={{ width: 160 }}
          />
        )}
      </div>
      <div className="cash-card-body">
        <div>
          <span>
            <CountUp
              start={0}
              end={amount || 0}
              separator=" "
              duration={2}
              useEasing={true}
              style={{ color: amount && amount >= 0 ? "#45cb85" : "#f06548" }}
            />
          </span>
          <p>{t("common.uzs")}</p>
        </div>
      </div>
    </div>
  );
}

export default CashCard;
