import { useTranslation } from "react-i18next";
import CustomButton from "../button";

interface Props {
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  okLoading?: boolean;
  okDisabled?: boolean;
}

function ModalFooter({
  okText,
  cancelText,
  onCancel,
  onOk,
  okLoading,
  okDisabled,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="modal-footer">
      <CustomButton
        fontSize={20}
        paddingContentHorizontal={32}
        onClick={() => onCancel?.()}
        className="modal-cancel-btn"
      >
        {cancelText ?? t("common.cancel")}
      </CustomButton>
      <CustomButton
        fontSize={20}
        type="primary"
        paddingContentHorizontal={32}
        htmlType="submit"
        loading={okLoading}
        onClick={() => onOk?.()}
        className="modal-add-btn"
        disabled={okDisabled}
      >
        {okText || t("common.save")}
      </CustomButton>
    </div>
  );
}

export default ModalFooter;
