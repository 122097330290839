import { ConfigProvider, Select } from "antd";
import { CustomSelectProps } from "./type";
import { useTypedSelector } from "src/app/store";

function CustomSelect({ height, size, colorTextPlaceholder, colorBorder, fontSize = 16, ...rest }: CustomSelectProps) {
  const { colors } = useTypedSelector((state) => state.layout);
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            fontSize,
            controlHeight: height || 46,
            colorTextPlaceholder: colorTextPlaceholder || colors.color_black,
            colorBgContainer: colors.color_white,
            colorBorder: colorBorder || colors.color_border,
            borderRadius: 4,
          },
        },
      }}
    >
      <Select {...rest} size={size} />
    </ConfigProvider>
  );
}

export default CustomSelect;
