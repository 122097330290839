import { useTranslation } from "react-i18next";
import "./chartHeader.scss";
import { Statistic } from "antd";

interface Props {
  name?: string | React.ReactNode;
  amount?: number;
  className?: string;
  suffix?: React.ReactNode;
  filters?: React.ReactNode;
}

function ChartHeader({ name, amount, className, suffix, filters }: Props) {
  const { t } = useTranslation();
  return (
    <div className={`chart-header ${className || ""}`}>
      <div className="chart-header-title">{name}</div>
      {suffix ?? ""}
      {filters ?? ""}
      {!!amount && (
        <div className="chart-header-total">
          <h4>{t("common.all")}:</h4>
          <h3>
            <Statistic value={Math.round(amount)} />
          </h3>
        </div>
      )}
    </div>
  );
}

export default ChartHeader;
