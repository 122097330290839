import { ConfigProvider, Button } from "antd";
import { CustomButtonProps } from "./type";

// import { isTablet } from "src/static/constants";

import { useTypedSelector } from "src/app/store";

function CustomButton({
  fontSize,
  height,
  paddingContentHorizontal,
  colorbgd,
  size,
  ...rest
}: CustomButtonProps) {
  const { colors } = useTypedSelector((state) => state.layout);
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontSize: fontSize || 16,
            controlHeight: height || 37,
            borderRadius: 4,
            paddingContentHorizontal: paddingContentHorizontal || 24,
            colorPrimary: colorbgd || colors.primary,
          },
        },
      }}
    >
      <Button {...rest} />
    </ConfigProvider>
  );
}

export default CustomButton;
