import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "src/app/services/users";
import { POSITION, PROFILE, TOKEN, position, profile, token } from "src/app/services/users/const";
import { IProfile } from "src/app/services/users/type";

export interface IAuthState {
  profile?: IProfile;
  token?: string;
  isAuthenticated: boolean;
}

const initialState: IAuthState = {
  profile: profile ? JSON.parse(profile) : undefined,
  token: token || "",
  isAuthenticated: token ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      state.profile = undefined;
      state.isAuthenticated = false;

      localStorage.removeItem(TOKEN);
      localStorage.removeItem(PROFILE);
      localStorage.removeItem(POSITION);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        state.token = action.payload.access;
        state.isAuthenticated = true;

        localStorage.setItem(TOKEN, action.payload.access);
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        state.token = undefined;
        state.isAuthenticated = false;
      })
      .addMatcher(authApi.endpoints.getProfile.matchFulfilled, (state, action) => {
        state.profile = action.payload;
        if (!position) localStorage.setItem(POSITION, action.payload.position);
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
