import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./auth.scss";
import { useTranslation } from "react-i18next";

interface Props extends PropsWithChildren {
  title: string;
  imgUrl: string;
  helpText?: string;
  navigateText?: string;
  navigateLink?: string;
  signUp?: boolean;
}

function AuthPage({ title, helpText, navigateText, navigateLink, children, signUp }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={`auth ${signUp ? "auth-signUp" : ""}`}>
      <div className={`auth-title ${!signUp ? "hidden" : ""}`}>
        <img src={require("src/assets/img/coin-logo.png")} alt="logo" />
        <h2>
          <span>{t("auth.signUp.title1")}</span> {t("auth.signUp.title2")}
        </h2>
        <p>{t("auth.signUp.description")}</p>
      </div>
      <div className="auth-wrap">
        <div className={`auth-form-container ${signUp ? "signUp-width" : ""}`}>
          <div className="auth-form-header">
            <p className={signUp ? "signUp-title" : ""}>{title}</p>
          </div>
          {children}
          {!!helpText && (
            <div className="auth-form-footer">
              {helpText} <Link to={`${navigateLink}`}>{navigateText}</Link>
            </div>
          )}
        </div>
      </div>

      <div className="auth-logo" onClick={() => navigate("/")}>
        <img src={require("src/assets/img/logo.png")} alt="aqcham logo" />
      </div>
    </div>
  );
}

export default AuthPage;
