import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { ITransactionTable } from "../services/finance/type";

export interface ITransactionState {
  transactions?: ITransactionTable[];
}

const initialState: ITransactionState = {
  transactions: undefined,
};

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getTransactions.matchFulfilled, (state, action) => {
      let arr: ITransactionTable[] = [];

      action.payload.results.forEach((item) => {
        let { key, ...rest } = item;
        arr.push({ key: item.id, ...rest });
      });
      state.transactions = arr;
    });
  },
});

export const {} = transactionSlice.actions;

export default transactionSlice.reducer;
