import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isSidebarFixed } from "src/static/constants";
import { cssThemeColors, themeColors } from "src/static/theme";
import { IThemeColor } from "src/static/theme/type";

export interface ILayoutState {
  collapsed: boolean;
  darkMode: boolean;
  colors: IThemeColor;
  screenMode?: "enter" | "exit";
}

export const THEME_COLOR = "AQCHAM_THEME_COLOR";

export const isDarkTheme = localStorage.getItem(THEME_COLOR) === "dark";

const initialState: ILayoutState = {
  collapsed: isSidebarFixed ? true : false,
  darkMode: isDarkTheme,
  colors: isDarkTheme ? themeColors.dark : themeColors.light,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    changeScreenMode: (state, action: PayloadAction<ILayoutState["screenMode"]>) => {
      state.screenMode = action.payload;
    },
    changeTheme: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === "light") state.darkMode = false;
      else state.darkMode = !state.darkMode;

      let cssColors: any;

      if (state.darkMode) {
        localStorage.setItem(THEME_COLOR, "dark");
        state.colors = themeColors.dark;
        cssColors = cssThemeColors.dark;
      } else {
        localStorage.setItem(THEME_COLOR, "light");
        state.colors = themeColors.light;
        cssColors = cssThemeColors.light;
      }

      for (const key in cssColors) {
        document.documentElement.style.setProperty("--" + key, cssColors[key]);
      }
    },
    setTheme: (state) => {
      let cssColors: any;

      if (state.darkMode) {
        cssColors = cssThemeColors.dark;
      } else {
        cssColors = cssThemeColors.light;
      }

      for (const key in cssColors) {
        document.documentElement.style.setProperty("--" + key, cssColors[key]);
      }
    },
  },
});

export const { changeCollapsed } = layoutSlice.actions;
export const { changeScreenMode } = layoutSlice.actions;
export const { changeTheme } = layoutSlice.actions;
export const { setTheme } = layoutSlice.actions;

export default layoutSlice.reducer;
