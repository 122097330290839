import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetDirectionsStatMutation,
  useGetSourcesStatMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import FilterIncomeOutcome from "src/components/filter/FilterIncomeOutcome";
import { handleMakeStatUrl } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import "./expenses.scss";
import { useTranslation } from "react-i18next";

const { innerWidth } = window;
const isTablet = innerWidth < 1100;
const isMobile = innerWidth < 570;

function ExpensesPage() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  //Get section
  const [searchParams] = useSearchParams();
  const [getSourcesStat] = useGetSourcesStatMutation();
  const [getDirectionsStat] = useGetDirectionsStatMutation();
  const { sorcesStat } = useTypedSelector((state) => state.stat);
  const is_expense = searchParams.get("is_expense");

  const handleGet = () => {
    let url =
      is_expense && is_expense !== "false"
        ? handleMakeStatUrl(searchParams, ["is_expense", "date_month"])
        : handleMakeStatUrl(searchParams, ["date_month"]);
    if (is_expense === "true") getSourcesStat(url);
    else getDirectionsStat(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_expense, searchParams.get("date_month")]);

  const option = {
    legend: {
      type: "scroll",
      orient: "vertical",
      top: isMobile ? -30 : 10,
      bottom: 20,
      right: 10,
      show: !isTablet,
      textStyle: {
        color: colors.color_black,
      },
    },
    tooltip: {
      trigger: "item",
      align: "center",
      formatter: function (params: any) {
        return `${params.seriesName}<br />
                ${params.name}: ${params.data?.data?.prettier_value} (${params.data?.data?.percent}%)`;
      },
    },
    series: [
      {
        name: `${
          is_expense === "true" ? t("common.outcome") : t("common.income")
        } ${t("common.source")}`,
        type: "pie",
        radius: ["30%", "60%"],
        center: ["40%", "50%"],
        avoidLabelOverlap: false,
        startAngle: isTablet ? -60 : 60,
        itemStyle: {
          borderRadius: 10,
          borderColor: colors.color_white,
          borderWidth: 2,
        },
        label: {
          fontSize: 12,
        },
        emphasis: {
          label: {
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: true,
        },
        xAxis: {},
        data: sorcesStat?.series,
      },
    ],
  };

  return (
    <div className="expenses" id="expenses">
      <div className="expenses-content">
        <ChartHeader
          name={t("subControlPanel.expenses")}
          amount={sorcesStat?.total}
          filters={
            <div className="chart-header-filters">
              <FilterIncomeOutcome />
              <FilterDate format="month" name="date_month" />
            </div>
          }
        />

        <div className="expenses-chart balance-chart">
          <div className="balance-chart-content">
            <ReactEcharts
              opts={{
                renderer: "svg",
                height: isMobile ? 450 : isTablet ? 500 : 600,
              }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpensesPage;
