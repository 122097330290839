import Statistic from "antd/es/statistic/Statistic";
import { ColumnsType } from "antd/es/table";
import { ICurrency } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const currencyColumns = (t: TFunctionType): ColumnsType<ICurrency> => [
  {
    title: t("common.date"),
    dataIndex: "date",
    key: "date",
  },
  {
    title: t("common.currencyRate"),
    dataIndex: "exchange_rate",
    key: "exchange_rate",
    render: (val) => <Statistic value={val} className="table-statistic" />,
  },
  {
    title: t("common.currency"),
    dataIndex: "currency",
    key: "currency",
    render: (val) => t(`common.${val}`),
  },
];
