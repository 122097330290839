import { card1Data, card2Data, card3Data } from "./constants";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./priority.scss";
import { useTranslation } from "react-i18next";

function Priority() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div className="section-priority">
      <div className="priority-wrap container">
        <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <span>{t("home.priority.title1")}</span> {t("home.priority.title2")}
        </h2>
        <h3
          className="priority-subtitle"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          {t("home.priority.description")}
        </h3>
        <div className="priority-cards">
          <div
            className="priority-header-card card-1"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <h3>Aqcham</h3>
          </div>
          <div
            className="priority-header-card card-2"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <h3>1C</h3>
          </div>
          <div
            className="priority-header-card card-3"
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <h3>Excel</h3>
          </div>
          <div
            className="priority-main-card aqcham-card card-4"
            data-aos="fade-right"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <ul>
              {card1Data.map((text, i) => (
                <li key={i}>{t(text)}</li>
              ))}
            </ul>
          </div>
          <div
            className="priority-main-card card-5"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <ul>
              {card2Data.map((text, i) => (
                <li key={i}>{t(text)}</li>
              ))}
            </ul>
          </div>
          <div
            className="priority-main-card card-6"
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <ul>
              {card3Data.map((text, i) => (
                <li key={i}>{t(text)}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Priority;
