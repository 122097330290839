import { Popconfirm, Popover, Image, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { hostName } from "src/app/services/api/const";
import { IJournalResData } from "src/app/services/finance/type";
import { DeleteIcon, UserIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";

interface Props extends IJournalResData {
  action?: boolean;
  onConfirm?: (val: number) => void;
  onDelete?: (val: number) => void;
}

function UnConfirmedCard({ id, action, onConfirm, onDelete, outcome, currency, user, comment }: Props) {
  const { t } = useTranslation();

  return (
    <div className="unConfirmed-card">
      <div className="unConfirmed-card-header">
        {user?.avatar ? (
          <Image width={48} src={hostName + user?.avatar} />
        ) : (
          <div className="team-card-user-icon">
            <UserIcon />
          </div>
        )}

        <div className="unConfirmed-card-info">
          <h4>{user?.title}</h4>
          {user?.phone ? <p>+998{user?.phone}</p> : ""}
        </div>
        <Popconfirm
          title="Delete the outcome"
          description={`Are you sure to confirm delete the outcome?"`}
          onConfirm={() => onDelete?.(id)}
          okText="Delete"
          cancelText="Cancel"
        >
          <div className="unConfirmed-card-delete">
            <DeleteIcon />
          </div>
        </Popconfirm>
      </div>
      <div className="unConfirmed-card-main">
        <h3>
          {currency === "usd" && "$"}
          <Statistic value={outcome} />
          {currency !== "usd" && t(`common.${currency}`)}
        </h3>
        <div className="unConfirmed-card-text">
          <p>{comment}</p>
        </div>
        <div className="unConfirmed-card-more">
          <Popover trigger="click" content={comment}>
            <CustomButton
              fontSize={14}
              height={24}
              paddingContentHorizontal={10}
              className="btn-green unConfirmed-card-main-btn"
            >
              more
            </CustomButton>
          </Popover>
        </div>
      </div>
      <div className="unConfirmed-card-footer">
        {action && (
          <Popconfirm
            title="Confirm the outcome"
            description={`Are you sure to confirm ${user?.title || "this"} outcome?"`}
            onConfirm={() => onConfirm?.(id)}
            okText="Tasdiqlash"
            cancelText="Bekor qilish"
          >
            <CustomButton fontSize={14} className="unConfirmed-card-btn" height={28}>
              Tasdiqlash
            </CustomButton>
          </Popconfirm>
        )}
      </div>
    </div>
  );
}

export default UnConfirmedCard;
