import { Form, Input, Tour } from "antd";
import { MaskedInput } from "antd-mask-input";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton from "src/components/common/button";
import AuthPage from "..";
import { commentFormData } from "../constants";
import useComment from "./useComment";
import { useTypedSelector } from "src/app/store";

function CommentPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onFinish, form, isLoading, steps, open } = useComment();
  const { isAuthenticated } = useTypedSelector((state) => state.users);

  return (
    <AuthPage
      title={t("auth.signUp.formTitle")}
      imgUrl="sign-up.png"
      helpText={t("auth.signUp.bottomText")}
      navigateText={t("auth.signUp.signInLink")}
      navigateLink={isAuthenticated ? "/dashboard" : "/auth/signin"}
      signUp={true}
    >
      <Form form={form} className="form-form auth-form" onFinish={onFinish} layout="vertical" requiredMark={false}>
        {commentFormData.map((item) => {
          return (
            <Form.Item
              name={item.name}
              label={t(item.label) + (item.message ? "*" : "")}
              key={item.label}
              rules={[{ required: !!item.message, message: item.message }]}
              className={item.className}
            >
              {item.name === "comment" ? (
                <Input.TextArea className="auth-form-input" />
              ) : item.name === "phone_number" ? (
                <MaskedInput className="phoneInput auth-form-input-phone" addonBefore="+998" mask="(00)-000-00-00" />
              ) : (
                <Input className="auth-form-input" />
              )}
            </Form.Item>
          );
        })}

        {/* Submit button */}
        <CustomButton
          htmlType="submit"
          className="auth-form-btn"
          style={{ marginTop: 24 }}
          height={40}
          fontSize={14}
          type="primary"
          loading={isLoading}
        >
          {t("auth.comment.send")}
        </CustomButton>
      </Form>
      <Tour open={open} onClose={() => navigate("/")} steps={steps} />
    </AuthPage>
  );
}

export default CommentPage;
