import { TFunctionType } from "src/static/constants";

export const statGrid = {
  left: "3%",
  right: "4%",
  // bottom: "10%",
  containLabel: true,
  height: 400,
};

export const monthes = [
  "Yan",
  "Fev",
  "Mar",
  "Apr",
  "May",
  "Iyun",
  "Iyul",
  "Avg",
  "Sen",
  "Okt",
  "Noy",
  "Dek",
];

export const cashflowStatMenu = (t: TFunctionType) => {
  return [
    {
      key: "balance",
      href: "#balance",
      title: t("subControlPanel.balance"),
    },
    {
      key: "profit",
      href: "#profit",
      title: t("subControlPanel.income"),
    },

    {
      key: "portion",
      href: "#portion",
      title: t("common.directions"),
    },
    {
      key: "revenue",
      href: "#revenue",
      title: t("subControlPanel.revenue"),
    },
    {
      key: "expenses",
      href: "#expenses",
      title: t("subControlPanel.expenses"),
    },
  ];
};

export const pnlStatMenu = (t: TFunctionType) => {
  return [
    {
      key: "profit_soft",
      href: "#profit_soft",
      title: t("subControlPanel.profit_soft"),
    },
    {
      key: "overhead",
      href: "#overhead",
      title: t("subControlPanel.overhead"),
    },

    {
      key: "gross",
      href: "#gross",
      title: t("common.grosProfit"),
    },
    {
      key: "operative",
      href: "#operative",
      title: t("common.operativProfit"),
    },
    {
      key: "soft",
      href: "#soft",
      title: t("common.netProfit"),
    },
  ];
};
