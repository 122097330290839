import { Statistic } from "antd";
import { ColumnsType } from "antd/es/table";
import { TFunctionType, isMobile } from "src/static/constants";
import {
  ICashflowColumns,
  ICashflowPredTableItem,
  ICashflowTableItem,
} from "./type";

export const makePredColumn = (
  arr1?: ICashflowTableItem[],
  arr2?: ICashflowPredTableItem[]
) => {
  if (!arr1 || !arr2) return;
  let arr: any = [];
  let keys: string[] = [];

  [...arr1, ...arr2].forEach((item) => {
    if (item.key) {
      let key = item.key.split("_pred")[0];
      if (!keys.includes(key)) keys.push(key);
    }
  });

  keys.forEach((item) => {
    let obj: any;
    let obj1 = arr1.find((el) => el.key?.split("_pred")[0] === item);
    let obj2 = arr2.find((el) => el.key?.split("_pred")[0] === item);

    if (obj1 && !obj2) obj = obj1;
    else if (!obj1 && obj2) obj = obj2;
    else if (obj1 && obj2) {
      let { key, sources, ...rest } = obj2;
      obj = { ...obj1, ...rest };
    }
    if (obj) {
      arr.push(obj);
    }
  });
  return arr;
};

const findClass = (key?: string) => {
  if (key?.includes("income")) return "table-statistic-income";
  else if (key?.includes("outcome")) return "table-statistic-outcome";
  else return "table-statistic";
};

const mobileSourceColumn = (t: TFunctionType) => ({
  title: t("subControlPanel.sourceMonthly"),
  dataIndex: "sources",
  key: "sources",
  width: 200,
});
const computerSourceColumn = (t: TFunctionType) => ({
  title: t("subControlPanel.sourceMontly"),
  dataIndex: "sources",
  key: "sources",
  fixed: "left",
  width: 250,
});

export const cashFlowColumns = (
  t: TFunctionType
): ColumnsType<ICashflowColumns> => {
  return [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      fixed: "left",
      width: 40,
    },
    isMobile ? mobileSourceColumn(t) : computerSourceColumn(t),
    {
      title: t("months.jan"),
      dataIndex: "jan",
      key: "jan",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.feb"),
      dataIndex: "feb",
      key: "feb",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.march"),
      dataIndex: "mar",
      key: "mar",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.apr"),
      dataIndex: "apr",
      key: "apr",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.may"),
      dataIndex: "may",
      key: "may",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.jun"),
      dataIndex: "june",
      key: "june",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.jul"),
      dataIndex: "july",
      key: "july",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.aug"),
      dataIndex: "aug",
      key: "aug",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.sep"),
      dataIndex: "sep",
      key: "sep",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.oct"),
      dataIndex: "oct",
      key: "oct",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.nov"),
      dataIndex: "nov",
      key: "nov",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      title: t("months.dec"),
      dataIndex: "dec",
      key: "dec",
      width: 120,
      render: (val, record) => makeVal(val, record),
    },
    {
      dataIndex: "action",
      key: "action",
    },
  ];
};

const makeVal = (val: any, record: ICashflowColumns) => {
  if (!val) return "";
  if (record.key?.includes("percentages"))
    return String(Number.parseFloat(val).toFixed(2)) + " %";
  else
    return (
      <Statistic value={Math.round(val)} className={findClass(record.key)} />
    );
};

const gapColumn = (val?: number, val_pred?: number, key?: string) => {
  return (
    <Statistic
      value={Math.round((val || 0) - (val_pred || 0))}
      className={findClass(key)}
    />
  );
};

const percentageColumn = (val?: number, val_pred?: number, key?: string) => {
  return (
    <Statistic
      value={val && val_pred && Math.round((val / val_pred) * 100)}
      className={findClass(key)}
      suffix="%"
    />
  );
};

export const cashFlowPredictedColumns = (
  t: TFunctionType
): ColumnsType<any> => [
  {
    title: "",
    dataIndex: "index",
    key: "index",
    fixed: "left",
    width: 40,
  },
  isMobile ? mobileSourceColumn(t) : computerSourceColumn(t),
  {
    title: t("months.jan"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "jan_pred",
        key: "jan_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "jan",
        key: "jan",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "jan",
        key: "jan",
        width: 120,
        render: (_, { jan, jan_pred, key }) => gapColumn(jan, jan_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "jan",
        key: "jan",
        width: 120,
        render: (_, { jan, jan_pred, key }) =>
          percentageColumn(jan, jan_pred, key),
      },
    ],
  },
  {
    title: t("months.feb"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "feb_pred",
        key: "feb_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "feb",
        key: "feb",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "feb",
        key: "feb",
        width: 120,
        render: (_, { feb, feb_pred, key }) => gapColumn(feb, feb_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "feb",
        key: "feb",
        width: 120,
        render: (_, { feb, feb_pred, key }) =>
          percentageColumn(feb, feb_pred, key),
      },
    ],
  },
  {
    title: t("months.march"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "mar_pred",
        key: "mar_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "mar",
        key: "mar",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "mar",
        key: "mar",
        width: 120,
        render: (_, { mar, mar_pred, key }) => gapColumn(mar, mar_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "mar",
        key: "mar",
        width: 120,
        render: (_, { mar, mar_pred, key }) =>
          percentageColumn(mar, mar_pred, key),
      },
    ],
  },
  {
    title: t("months.apr"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "apr_pred",
        key: "apr_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "apr",
        key: "apr",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "apr",
        key: "apr",
        width: 120,
        render: (_, { apr, apr_pred, key }) => gapColumn(apr, apr_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "apr",
        key: "apr",
        width: 120,
        render: (_, { apr, apr_pred, key }) =>
          percentageColumn(apr, apr_pred, key),
      },
    ],
  },
  {
    title: t("months.may"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "may_pred",
        key: "may_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "may",
        key: "may",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "may",
        key: "may",
        width: 120,
        render: (_, { may, may_pred, key }) => gapColumn(may, may_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "may",
        key: "may",
        width: 120,
        render: (_, { may, may_pred, key }) =>
          percentageColumn(may, may_pred, key),
      },
    ],
  },
  {
    title: t("months.jun"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "june_pred",
        key: "june_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "june",
        key: "june",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "june",
        key: "june",
        width: 120,
        render: (_, { june, june_pred, key }) =>
          gapColumn(june, june_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "june",
        key: "june",
        width: 120,
        render: (_, { june, june_pred, key }) =>
          percentageColumn(june, june_pred, key),
      },
    ],
  },
  {
    title: t("months.jul"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "july_pred",
        key: "july_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "july",
        key: "july",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "july",
        key: "july",
        width: 120,
        render: (_, { july, july_pred, key }) =>
          gapColumn(july, july_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "july",
        key: "july",
        width: 120,
        render: (_, { july, july_pred, key }) =>
          percentageColumn(july, july_pred, key),
      },
    ],
  },
  {
    title: t("months.aug"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "aug_pred",
        key: "aug_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "aug",
        key: "aug",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "aug",
        key: "aug",
        width: 120,
        render: (_, { aug, aug_pred, key }) => gapColumn(aug, aug_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "aug",
        key: "aug",
        width: 120,
        render: (_, { aug, aug_pred, key }) =>
          percentageColumn(aug, aug_pred, key),
      },
    ],
  },
  {
    title: t("months.sep"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "sep_pred",
        key: "sep_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "sep",
        key: "sep",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "sep",
        key: "sep",
        width: 120,
        render: (_, { sep, sep_pred, key }) => gapColumn(sep, sep_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "sep",
        key: "sep",
        width: 120,
        render: (_, { sep, sep_pred, key }) =>
          percentageColumn(sep, sep_pred, key),
      },
    ],
  },
  {
    title: t("months.oct"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "oct_pred",
        key: "oct_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "oct",
        key: "oct",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "oct",
        key: "oct",
        width: 120,
        render: (_, { oct, oct_pred, key }) => gapColumn(oct, oct_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "oct",
        key: "oct",
        width: 120,
        render: (_, { oct, oct_pred, key }) =>
          percentageColumn(oct, oct_pred, key),
      },
    ],
  },
  {
    title: t("months.nov"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "nov_pred",
        key: "nov_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "nov",
        key: "nov",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "nov",
        key: "nov",
        width: 120,
        render: (_, { nov, nov_pred, key }) => gapColumn(nov, nov_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "nov",
        key: "nov",
        width: 120,
        render: (_, { nov, nov_pred, key }) =>
          percentageColumn(nov, nov_pred, key),
      },
    ],
  },
  {
    title: t("months.dec"),
    children: [
      {
        title: t("common.plan"),
        dataIndex: "dec_pred",
        key: "dec_pred",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.fact"),
        dataIndex: "dec",
        key: "dec",
        width: 120,
        render: (val, record) => makeVal(val, record),
      },
      {
        title: t("common.difference"),
        dataIndex: "dec",
        key: "dec",
        width: 120,
        render: (_, { dec, dec_pred, key }) => gapColumn(dec, dec_pred, key),
      },
      {
        title: t("common.percent"),
        dataIndex: "dec",
        key: "dec",
        width: 120,
        render: (_, { dec, dec_pred, key }) =>
          percentageColumn(dec, dec_pred, key),
      },
    ],
  },
  {
    dataIndex: "action",
    key: "action",
  },
];
