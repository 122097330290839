import { message } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDeleteJournalMutation, useGetJournalMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import FilterAccount from "src/components/filter/FilterAccount";
import PaginationFilter from "src/components/filter/PaginationFilter";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import UnConfirmedCard from "../unConfirmed/components/UnConfirmedCard";
import "./pending.scss";

function Pending() {
  //Get section
  const [searchParams] = useSearchParams();
  const [getSource, { data }] = useGetJournalMutation();
  const { journals } = useTypedSelector((state) => state.journal);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams, { status: "pending" });
    getSource(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteJournal, { isSuccess: deleteStatus }] = useDeleteJournalMutation();
  const onDelete = (val: number) => {
    deleteJournal(val);
  };
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Successfully deleted!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <div className="pending">
      <ContentTop>
        <FilterAccount />
        <FilterDate />
      </ContentTop>

      <h2>Javob berilmagan hisoblar</h2>
      <div className="pending-cards">
        {journals?.map((el) => (
          <UnConfirmedCard key={el.id} {...el} onDelete={onDelete} />
        ))}
      </div>
      {data && <PaginationFilter total={data?.count} />}
    </div>
  );
}

export default Pending;
