import { Link, useLocation } from "react-router-dom";
import "./content.scss";
import { Breadcrumb } from "antd";
import { findDashboardPageTitle } from "../util";
import { useTypedSelector } from "src/app/store";
import { position } from "src/app/services/users/const";
import { useTranslation } from "react-i18next";

interface Props {
  children?: React.ReactNode;
  title?: string;
}

function ContentTop({ children, title }: Props) {
  const { t } = useTranslation();
  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;
  const location = useLocation();
  const defTitle = findDashboardPageTitle(location.pathname.split("/")[2], t);
  return (
    <div className="content-top">
      <Breadcrumb
        items={[
          {
            title: <Link to="/">{t("common.home")}</Link>,
          },
          {
            title: <Link to="/dashboard">{t("common.dashboard")}</Link>,
          },
          {
            title:
              title ||
              defTitle ||
              (userPostion === "accountant"
                ? "Kassa ma'lumotlari"
                : "Pul qoldig'i"),
          },
        ]}
      />
      {!!children && <div className="content-top-children">{children}</div>}
    </div>
  );
}

export default ContentTop;
