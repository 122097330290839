import { Link, useNavigate } from "react-router-dom";
import { useTypedSelector } from "src/app/store";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./hero.scss";
import { useTranslation } from "react-i18next";

function HeroSection() {
  const { isAuthenticated } = useTypedSelector((state) => state.users);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const { t } = useTranslation();

  return (
    <div className="section-hero">
      <div className="hero-wrap container" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1500">
        <h1>
          {t("home.hero.title1")} <span> {t("home.hero.title2")}</span>
        </h1>
        <h4>{t("home.hero.description")}</h4>
        <div className="hero-buttons">
          <button
            className="button-filled filled"
            onClick={() => (isAuthenticated ? navigate("/dashboard") : navigate("/auth/signin"))}
          >
            {t("home.hero.button1")}
          </button>
          <button className="button-outlined outlined">
            <Link to="/auth/comment">{t("home.hero.button2")}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
