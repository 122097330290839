import { Form, Row } from "antd";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { DateFormItem, InputFormItem, InputNumberFormItem, SelectFormItem } from "src/components/form/common";
import { formGutter } from "src/static/form";
import useAdd, { IAddProps } from "./useAdd";
import { useTranslation } from "react-i18next";

export default function AddStorage(props: IAddProps) {
  const { t } = useTranslation();
  const { isModalOpen, form, typeOptions, isLoading, showModal, closeModal, onFinish } = useAdd(props);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal open={isModalOpen} width={450} onCancel={closeModal} centered={true}>
        <ModalHeader title={t("subHelper.storageAddTitle")} />

        <Form
          form={form}
          name="normal_login"
          className="form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Row gutter={formGutter}>
            <DateFormItem label={`${t("common.date")} *`} format={"month"} message={t("common.selectDate")} col={24} />
            <SelectFormItem
              name="product_type"
              label={`${t("subHelper.storageType")} *`}
              message={t("subHelper.selectStorageType")}
              options={typeOptions}
              col={24}
            />
            <InputNumberFormItem
              name="price"
              label={`${t("common.priceSum")} *`}
              message={t("common.enterPrice")}
              col={24}
            />
          </Row>

          <ModalFooter onCancel={closeModal} okLoading={isLoading} />
        </Form>
      </CustomModal>
    </div>
  );
}
