import { useState } from "react";
import { Dropdown } from "antd";
import { LANGUAGE, language } from "./constants";
import { ArrowDownSvg, LanguageSvg } from "src/assets/svg";
import { useTranslation } from "react-i18next";
import "./selectLang.scss";

interface Props {
  visible: boolean;
}

function SelectLang({ visible }: Props) {
  const items = [
    {
      key: "uz",
      label: "O'zbek tili",
      onClick: () => handleChange("uz"),
    },
    {
      key: "uz_kr",
      label: "Ўзбек тили",
      onClick: () => handleChange("uz_kr"),
    },
    {
      key: "ru",
      label: "Русский",
      onClick: () => handleChange("ru"),
    },
    {
      key: "en",
      label: "English",
      onClick: () => handleChange("en"),
    },
  ];

  //Change section
  const { i18n } = useTranslation();
  const [lan, setLan] = useState(language || "uz");
  const handleChange = (val: string) => {
    i18n.changeLanguage(val);
    localStorage.setItem(LANGUAGE, val);
    setLan(val);
    window.location.reload();
  };
  return (
    <Dropdown
      menu={{ items }}
      arrow={{ pointAtCenter: true }}
      trigger={["click"]}
      placement="bottom"
      className={`select-language ${visible ? "visible" : ""}`}
    >
      <div onClick={(e) => e.preventDefault()}>
        <div className="select-language-wrap">
          <div>
            <LanguageSvg />
            <p>{items.find((el) => el.key === lan)?.label}</p>
          </div>
          <ArrowDownSvg />
        </div>
      </div>
    </Dropdown>
  );
}

export default SelectLang;
