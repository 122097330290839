import { ColumnsType } from "antd/es/table";
import { IContingent } from "src/app/services/management/type";
import { TFunctionType } from "src/static/constants";

export const contingentsColumns = (
  t: TFunctionType
): ColumnsType<IContingent> => [
  {
    title: t("common.name"),
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: t("subInfo.phoneNumber"),
    dataIndex: "phone",
    key: "phone",
    render: (phone) =>
      phone ? <a href={`tel:+998${phone}`}>+998{phone}</a> : "",
  },
  {
    title: t("common.typeContingent"),
    dataIndex: "is_customer",
    key: "is_customer",
    render: (is_customer) =>
      is_customer ? t("common.customer") : t("common._suplier"),
  },
];

export const contingentsFormData = (t: TFunctionType) => [
  {
    name: "first_name",
    label: t("common.nameContingent"),
    placeholder: t("common.enterContingent"),
    message: t("common.enterContingent"),
  },
  {
    name: "phone",
    label: t("subInfo.phoneNumber"),
    placeholder: "__ ___ __ __",
    message: t("subInfo.enterCtrPhone"),
    className: "full",
    prefix: "+998",
  },
];
