import { Form, Row } from "antd";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import {
  DateFormItem,
  InputFormItem,
  InputNumberFormItem,
} from "src/components/form/common";
import { formGutter } from "src/static/form";
import useAdd, { IAddProps } from "./useAdd";
import { useTranslation } from "react-i18next";

export default function AddBasicTools(props: IAddProps) {
  const { t } = useTranslation();
  const { editableData } = props;
  const { isModalOpen, form, showModal, closeModal, onFinish } = useAdd(props);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? t("subHelper.editMainTitle")
              : t("subHelper.addMainTitle")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Row gutter={formGutter}>
            <InputFormItem
              name="title"
              label={`${t("common.name")} *`}
              message={t("common.enterName")}
            />
            <DateFormItem
              label={`${t("subHelper.purchaseDate")} *`}
              className="half"
              message={t("common.selectDate")}
            />
            <InputNumberFormItem
              name="initial_balance"
              label={`${t("subHelper.initial_balance")} *`}
              message={t("common.enterPrice")}
            />
            <InputNumberFormItem
              name="total_months"
              label={`${t("subHelper.total_months")} *`}
              message={t("common.enterMonth")}
            />
          </Row>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}
