import { Divider, Form, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetContingentMutation } from "src/app/services/management";
import { IOption } from "src/app/services/type";
import { updateContingent } from "src/app/slices/contingentSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import AddContingent from "src/pages/sub_info/contingent/components/AddContingent";

interface Props {
  name?: string;
  title?: string;
  required?: boolean;
  className?: string;
  message?: string;
  is_customer?: boolean;
  is_supplier?: boolean;
}

function ContingentFormItem({
  name = "contingent",
  required = false,
  className,
  title,
  message,
  is_customer,
  is_supplier,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [getContingent, { data }] = useGetContingentMutation();
  const [options, setOptions] = useState<IOption[]>([]);
  const { contingents } = useTypedSelector((state) => state.contingent);

  useEffect(() => {
    !contingents && getContingent("limit=1000");
  }, []);

  useEffect(() => {
    if (data || contingents) {
      let arr: IOption[] = [];
      (data?.results || contingents)?.forEach((item) => {
        let obj = { label: item.first_name, value: item.id.toString() };
        if (is_customer) {
          if (item.is_customer) arr.push(obj);
        } else if (is_supplier) {
          if (item.is_supplier) arr.push(obj);
        } else arr.push(obj);
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, contingents]);

  return (
    <>
      <Form.Item
        name={name}
        label={`${title ?? t("common.contingent")}${required ? " *" : ""}`}
        rules={
          required
            ? [
                {
                  required: true,
                  message: message ?? t("common.selectContingent"),
                },
              ]
            : []
        }
        className={className || "full"}
      >
        <Select
          allowClear
          options={options}
          showSearch
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            optionA.label
              ?.toLowerCase()
              .localeCompare(optionB.label.toLowerCase())
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 4px 4px" }}>
                <AddContingent
                  customTitle={t("common.addcontingent")}
                  setEditableData={() => {}}
                  handleGet={() =>
                    getContingent("limit=1000")
                      .unwrap()
                      .then((val) => {
                        dispatch(updateContingent(val.results));
                      })
                  }
                />
              </Space>
            </>
          )}
        />
      </Form.Item>
    </>
  );
}

export default ContingentFormItem;
