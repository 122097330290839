import { Form, Select } from "antd";
import { expenseTypeOptions } from "src/static/constants";

interface Props {
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

function ExpenseTypeFormItem({ required, className, disabled }: Props) {
  return (
    <Form.Item
      name="type"
      label={`Xarajat turi${required ? " *" : ""}`}
      rules={required ? [{ required: true, message: "Xarajat turini tanlang!" }] : []}
      className={className || "full"}
    >
      <Select disabled={disabled} allowClear options={expenseTypeOptions} />
    </Form.Item>
  );
}

export default ExpenseTypeFormItem;
