import { Form, Radio, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  onChange?: (val: RadioChangeEvent) => void;
  className?: string;
}

function SourceTypeFormItem({ onChange, className }: Props) {
  const { t } = useTranslation();
  const sourceTypeOptions = [
    {
      label: t("common.income"),
      value: false,
    },
    {
      label: t("common.outcome"),
      value: true,
    },
  ];
  return (
    <Form.Item
      name="is_expense"
      label={`${t("common.sourceType")} *`}
      rules={[{ required: true, message: t("common.selectSource") }]}
      className={className || "full"}
    >
      <Radio.Group options={sourceTypeOptions} onChange={onChange} />
    </Form.Item>
  );
}

export default SourceTypeFormItem;
