import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetDebtCreditMutation } from "src/app/services/finance";
import { IDebtCredit, IDebtCreditObj } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import { findMonth, prettierNumber } from "src/utils";
import { IDebtCreditTable, defCreditTableObj } from "./components/columns";

export default function useDebitCredit() {
  const [get, { isLoading }] = useGetDebtCreditMutation();
  const [data, setData] = useState<IDebtCreditTable>();
  const { colors } = useTypedSelector((state) => state.layout);
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get("date");

  const handleGet = () => {
    if (!year) {
      const date = new Date();
      setSearchParams({ date: date.getFullYear().toString() });
      return;
    }
    get(`?from_date=${Number(year) - 1}-12-15&to_date=${year}-12-15`)
      .unwrap()
      .then((response) => {
        let res = response as IDebtCredit;
        setData({
          customer: makeData(res.customer),
          employee: makeData(res.employee),
          rent: makeData(res.rent),
          supplier: makeData(res.supplier),
        });
        updateNegativeCellColor();
      });
  };

  useEffect(() => {
    handleGet();
  }, [searchParams]);

  const makeData = (arr: IDebtCreditObj[]) => {
    let list: any[] = [];
    arr.forEach((item) => {
      const date = new Date();
      const month = findMonth(item.date.slice(5, 7));
      const currentMonth = date.getMonth() + 1;
      const isCurrentMonth = Number(item.date.slice(5, 7)) === currentMonth;
      const nextMonth = findMonth(currentMonth < 9 ? "0" + (1 + currentMonth) : (1 + currentMonth).toString());
      const currentYear = date.getFullYear();
      const isCurrentYear = Number(item.date.slice(0, 4)) === currentYear;
      const nextMonthRemaining = prettierNumber(item.remaining + item.debt - item.credit);
      const current = list.find((el) => el.contingent?.id === item?.contingent.id);

      if (current) {
        current[month + "_gap"] = prettierNumber(item.remaining);
        current[month + "_debt"] = prettierNumber(item.debt);
        current[month + "_credit"] = prettierNumber(item.credit);
        if (isCurrentMonth) current[nextMonth + "_gap"] = nextMonthRemaining;
      } else {
        let obj: any = {
          contingent: item.contingent,
          ...defCreditTableObj,
        };
        if (isCurrentYear) {
          if (!(month === "jan" && !item.remaining)) obj[month + "_gap"] = prettierNumber(item.remaining);
          obj[month + "_debt"] = prettierNumber(item.debt);
          obj[month + "_credit"] = prettierNumber(item.credit);
        } else {
          obj["jan_gap"] = nextMonthRemaining;
        }

        if (isCurrentMonth) obj[nextMonth + "_gap"] = nextMonthRemaining;
        list.push(obj);
      }
    });
    return list;
  };

  const updateNegativeCellColor = async () => {
    var intervalId = setInterval(updateColor, 100);

    function updateColor() {
      document.querySelectorAll("td").forEach(function (td) {
        let prefix = td?.textContent?.[0];
        if (prefix === "-") {
          td.style.color = colors.color_red;
        }
        if (td?.textContent === "0") {
          td.style.color = colors.light_gray;
        }
      });
    }

    setTimeout(function () {
      clearInterval(intervalId);
    }, 400);
  };

  return {
    isLoading,
    data,
  };
}
