import { Alert, Form, InputNumber, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAddMonitorMutation, useEditMonitorMutation, useGetMonitorMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import CustomButton from "src/components/common/button";
import CustomSwitch from "src/components/common/switch";
import FilterAccount from "src/components/filter/FilterAccount";
import FilterDate from "src/components/filter/FilterDate";
import PaginationFilter from "src/components/filter/PaginationFilter";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import MonitorCard from "./components/MonitorCard";
import { monitorColumns } from "./constants";
import "./monitor.scss";
import { position } from "src/app/services/users/const";
import { useTranslation } from "react-i18next";

function MonitorPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { accountsAll } = useTypedSelector((state) => state.account);
  const [searchparams] = useSearchParams();
  const accountId = searchparams.get("account_id");
  const account = accountsAll?.find((el) => el.id === Number(accountId));

  const [form] = Form.useForm();
  const [bankBalance, setBankBalance] = useState<number>();
  const [gapBalance, setGapBalance] = useState<number>();

  const [addMonitor, { data: addData, isSuccess: addStatus, isLoading: addLaoding }] = useAddMonitorMutation();
  const [editMonitor, { data: editData, isSuccess: editStatus, isLoading: editLoading }] = useEditMonitorMutation();
  const [monitorId, setMonitorId] = useState<number>();

  const date_param = searchparams.get("date");
  const onCheck = () => {
    form.validateFields().then((val: { bank_balance: number }) => {
      let { bank_balance } = val;
      setBankBalance(bank_balance);
      account?.balance && setGapBalance(bank_balance - account?.balance);
    });
  };
  const onFinish = (val: { bank_balance: number }) => {
    let { bank_balance } = val;
    setBankBalance(bank_balance);
    account?.balance && setGapBalance(bank_balance - account?.balance);

    if (account) {
      let obj = {
        bank_balance,
        account_balance: account.balance,
        remaining: bank_balance - account.balance,
        account: account.id,
        date: date_param ?? undefined,
      };

      if (monitorId) editMonitor({ id: monitorId, body: obj });
      else addMonitor(obj);
    }
  };
  useEffect(() => {
    if (addStatus || editStatus) {
      message.success("Kassa muvaffqiyatli saqlandi!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, editStatus]);

  useEffect(() => {
    if (addData || editData) setMonitorId(addData?.id ?? editData?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addData, editData]);

  const [history, setHistory] = useState(false);
  const onChangeHistory = (val: boolean) => {
    searchParams.delete("date");
    setSearchParams(searchParams);
    setHistory(val);
    if (val) handleGet();
  };

  //Get section
  const [getMonitor, { data: monitorData }] = useGetMonitorMutation();
  const { monitors } = useTypedSelector((state) => state.monitor);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getMonitor(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (monitors) {
      let this_monitor;

      if (date_param) {
        this_monitor = monitors.find((el) => el.date === date_param);
      } else {
        const date = new Date();
        const this_date = ("" + date.getFullYear() + (date.getMonth() + 1) + date.getDate()).replaceAll("0", "");
        this_monitor = monitors.find((el) => el.date?.replaceAll("0", "").replaceAll("-", "") === this_date);
      }

      if (this_monitor) {
        setBankBalance(this_monitor.bank_balance);
        setGapBalance(this_monitor.remaining);
        setMonitorId(this_monitor.id);
        form.setFieldsValue({ bank_balance: this_monitor.bank_balance });
      } else {
        setBankBalance(undefined);
        setGapBalance(undefined);
        setMonitorId(undefined);
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitors]);

  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;

  return (
    <div className="monitor">
      <ContentTop>
        <FilterAccount />
        {userPostion !== "accountant" && history && <FilterDate />}
      </ContentTop>

      {accountId ? (
        <div>
          <div className="monitor-header">
            <h2>{account?.title}</h2>
            <div className="monitor-header-right">
              <p>{t("subCash.historyCash")}</p>
              <CustomSwitch onChange={onChangeHistory} />
            </div>
          </div>
          {history ? (
            <div>
              <Table
                columns={monitorColumns(t)}
                dataSource={monitors}
                scroll={{ x: 800 }}
                pagination={false}
                style={{ width: "100%" }}
              />
              {monitorData && <PaginationFilter total={monitorData?.count} />}
            </div>
          ) : (
            <div className="monitor-content">
              <div className="monitor-cards">
                <MonitorCard name="bank" balance={bankBalance} currency={account?.currency} />
                <MonitorCard name="system" balance={account?.balance} currency={account?.currency} />
                {(gapBalance || gapBalance === 0) && (
                  <MonitorCard name="gap" balance={gapBalance} currency={account?.currency} />
                )}
              </div>
              <Form className="monitor-form" form={form} requiredMark={false} onFinish={onFinish} layout={"vertical"}>
                <Form.Item
                  name="bank_balance"
                  label={`${t("subCash.exactAccountBalance")} (${
                    account?.currency ? t(`common.${account?.currency}`) : ""
                  })`}
                  rules={[
                    {
                      required: true,
                      message: t("subCash.enterExactAccountBalance"),
                    },
                  ]}
                  className="half"
                  style={{ marginTop: 24 }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>

                <div className="monitor-form-buttons">
                  <Form.Item>
                    <CustomButton
                      onClick={onCheck}
                      fontSize={20}
                      paddingContentHorizontal={32}
                      className="modal-cancel-btn"
                    >
                      {t("common.check")}
                    </CustomButton>
                  </Form.Item>

                  <Form.Item>
                    <CustomButton
                      fontSize={20}
                      type="primary"
                      paddingContentHorizontal={32}
                      htmlType="submit"
                      loading={addLaoding || editLoading}
                      className="modal-add-btn"
                    >
                      {t("common.save")}
                    </CustomButton>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </div>
      ) : (
        <Alert message={t("subCash.selectAccount")} banner />
      )}
    </div>
  );
}

export default MonitorPage;
