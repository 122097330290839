import { Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteSourceMutation,
  useGetSourceMutation,
} from "src/app/services/finance";
import { ISourceResData } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import AddSource from "./components/AddSource";
import { sourceColumns } from "./constants";
import "./source.scss";
import { useTranslation } from "react-i18next";

function SourcePage() {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getSource, { data: sourceData, isLoading }] = useGetSourceMutation();
  const { sources } = useTypedSelector((state) => state.source);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getSource(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteSource, { isSuccess: deleteStatus }] = useDeleteSourceMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<ISourceResData>();

  const onEdit = (val: number) => {
    let obj = sources?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  const columns: ColumnsType<ISourceResData> = [
    ...sourceColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      render: (key) => (
        <TableActions
          onDelete={() => deleteSource(key)}
          onEdit={() => onEdit(key)}
        />
      ),
    },
  ];

  return (
    <div className="source">
      <ContentTop>
        <AddSource
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      <Table
        columns={columns}
        dataSource={sources}
        scroll={{ x: 800 }}
        className="source-table"
        pagination={false}
        loading={isLoading}
      />
      {sourceData && <PaginationFilter total={sourceData?.count} />}
    </div>
  );
}

export default SourcePage;
