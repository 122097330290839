export const languageEn = {
  home: {
    hero: {
      title1: "Finance without accounting",
      title2: "= lost money",
      description: "If you don't manage your money properly from today, you will be driven by lack of money tomorrow",
      button1: "Log in",
      button2: "Leave your contacts",
    },
    explain: {
      title1: "Financial сalculation",
      title2: "complex process",
      card1Title: "Strategic planning with dynamic dashboards",
      card1Text:
        "Make detailed financial plans and track your company's financial performence in real time, making it easier to make decision and plan for continued success",
      card2Title: "Calculate Net Profit accurately",
      card2Text:
        "Accurately calculate the net profit by fully managing your financial data. Make your financial stability more clear, plan strategically by adding material and intangible income flows to your analyzes.",
      card3Title: "Personalized access",
      card3Text:
        "Tailor visibility and functionality based on each employee's role, allowing team members to focus on the information and tasks relevant to their responsibilities",
      card4Title: "Artifiacial intelegence understands your businees",
      card4Text:
        "Using the power of artificial intelligence, get personalized financial advice tailored to your company's financial situation. Our intelligent system understands your financial situation, offers insights and recommendations to optimize your financial strategy.",
      card5Title: "Clearly analyze your financial situation",
      card5Text:
        "Completely monitor your business's financial position. Analyze your financial performance in-depth with our wide range of tables and line graphs, improve your decision-making process with detailed analysis",
      card6Title: "Confirmation of financial income and expenditure",
      card6Text:
        "The Aqcham Telegram Bot will receive notifications of expenses and income used by the team, increasing accountability and transparency across your team.",
    },
    about: {
      title1: "Direct advantages of ",
      title2: "the program",
      card1Title: "Integrated with ChatGPT 4",
      card2Title: "Automatic analysis",
      card3Title: "Finance advices",
      card4Title: "Free textbooks on finance",
      card5Title: "Handy control panel",
      card6Title: "Video tutorials for using the program",
    },
    priority: {
      title1: "Aqcham - a financial model",
      title2: "that works with artificial intelligence",
      description:
        "There will be no errors and omissions here. Financial opportunities are not limited by simple and boring templates",
      card1line1: "Every information will collect in one place",
      card1line2: "Automatic calculation",
      card1line3: "Automatically generated graphs and ready-made visual reports on the dashboard",
      card1line4: "Optimization with artificial Intelligence",
      card1line5: "Deleted operations can be restored. All data is stored on the server",
      card1line6: "Preventing cash gaps and preparing financial forecasts for any period in advance",
      card2line1: "Complexity of the interface",
      card2line2: "The slowness of some calculations",
      card2line3: "No creative freedom",
      card2line4: "Complexity of entering and retrieving data",
      card2line5: "Calculations are slow",
      card2line6: "Accordingly, the number of documents and reports will also increase",
      card3line1: "Too many tables",
      card3line2: "Errors in calculations due to incorrect formulas",
      card3line3: "Complexity of data analysis and limited reporting options",
      card3line4: "Manual Data Entry",
      card3line5: "Security is low",
      card3line6: "It is impossible to prepare a cash flow plan in Excel",
    },
    team: {
      title: "Our team",
      card1Title: "Annual experience",
      card2Title: "Fianancial modules",
      card3Title: "FINANCE DIRECTORS",
      card4Title: "The project",
    },
    partners: {
      title1: "Our partners",
      title2: "who belive us",
    },
    contact: {
      title1: "Contact us",
      title2: "at any time",
      formTitle: "Leave your number and we will contact you soon",
      button: "Send",
    },
    footer: {
      description: "Online program for financial management. With this program you manage money, income, profit",
      linkTitle: "Links",
      blogTitle: "Blog",
      blogLine1: "Terms of use",
      blogLine2: "Privicy policy",
      adressTitle: "Our Adress",
      adressLine1: "Tashkent city, Shota Rustaveli str., 1. In the MFaktor building",
      contactButton: "Contact us",
    },
    menu: {
      menu1: "About software",
      menu2: "Opportunities",
      menu3: "Partners",
      menu4: "Prices",
      menu5: "Connect",
    },
  },
  auth: {
    signUp: {
      title1: "Welcome",
      title2: "To Aqcham",
      description:
        "In business, if you don't know the exact numbers, if you're not familiar with profit and loss, income and expenditure statements, you'll definitely lose",
      formTitle: "Welcome to the Aqcham",
      firstNameLabel: "Name",
      firstNamePlaceholder: "Your name",
      lastNameLabel: "Last name",
      lastNamePlaceholder: "Your last name",
      emailLabel: "Email",
      emailPlaceholder: "Your email",
      passwordLabel: "Password",
      passwordPlaceholder: "Your password",
      confirmPasswordLabel: "Re-type password",
      confirmPasswordPlaceholder: "Re-type password",
      bottomText: "If you have an `AQCHAM` account",
      button: "Sign up",
      signInLink: "Sign in",
    },
    signIn: {
      formTitle: "Welcome",
      emailLabel: "Email",
      emailPlaceholder: "Your Email",
      passwordLabel: "Password",
      passwordPlaceholder: "Enter password",
      forgetPassword: "Forgot password",
      button: "Access",
      bottomText: "If you don't have an AQCHAM account",
      signInLink: "Sign up",
      commentLink: "Leave a request",
    },
    comment: {
      businessLabel: "About your business",
      send: "Send",
      phone: "Phone Number",
    },
  },
  sidebar: {
    statistics: {
      title: "Control panel",
      menu1: "Cash Flow",
      menu2: "Profit and loss",
    },
    check: {
      title: "Confirm",
      menu1: "Edited",
    },
    cash: {
      title: "Cash Flow",
      menu1: "Cash journal",
      menu2: "Cash withdrawal",
      menu3: "Transfer",
    },
    cashflow: {
      title: "Cash Flow",
      selectTitle: "Select Cash Flow",
    },
    pnl: {
      title: "Profit and loss",
      menu1: "Profit and loss",
      menu2: "Liability",
      menu3: "ACT",
      statistcCashflow: "Cash Flow statistics",
      statistcPnl: "Profit and loss statistics",
      debitor: "Debtor & Creditor",
    },
    planned: {
      title: "Planning",
      menu1: "Planning income and expenditure",
      menu2: "Budjet Cash Flow",
    },
    info: {
      title: "Data",
      menu1: "Currencies",
      menu2: "Categories",
      menu3: "Accounts",
      menu4: "Directions",
      menu5: "Employees",
      menu6: "Counterparties",
    },
    kompany: {
      title: "Company",
    },
  },
  common: {
    all: "Total",
    uzs: "sum",
    rubl: "Ruble",
    name: "Name",
    view: "See",
    enterName: "Enter name",
    dollar: "USD",
    income: "Income",
    outcome: "Expenditure",
    currency: "Currency",
    currencyRate: "Exchange rate",
    currencyRateForDollar: "Exchange rate (for 1 $)",
    transfer: "Transfer",
    today: "Today",
    thisMonth: "This month",
    lastMonth: "Last month",
    difference: "Difference",
    date: "Date",
    intervalDate: "Intermediate date",
    actions: "Actions",
    plan: "Plan",
    home: "Home page",
    dashboard: "Dashboard",
    fact: "Fact",
    percent: "Percentage",
    position: `Position`,
    profit: "Revenue",
    comment: "Comment",
    enterComment: "Enter a comment",
    total: "General",
    barchasi: "All",
    directions: "Directions",
    direction: "Direction",
    search: "Search",
    clear: "Clean up",
    currentMoneyFlow: "Operating Cash Flow",
    financialMoneyFlow: "Financing Cash Flow",
    invistationMoneyFlow: "Investing Cash Flow",
    naklodnoyOutcome: "Overhead expense",
    administrativeOutcome: "Administrative expense",
    commersialOutcome: "Commercial expense",
    expense: "Expense",
    expenseType: "Expense type",
    subSources: "Sub categories",
    enterSubSources: "Sub categories",
    taxes: "Taxes",
    users: "Employees",
    user: "Employee",
    addUuser: `Add employee`,
    month: "Month",
    monthly: "Monthly",
    whichMonth: "Which month",
    whichMonthInfo: "For which month did the employee receive salary?",
    value: "Cost",
    enterValue: "Enter cost",
    netProfit: "Net profit",
    grosProfit: "Gross profit",
    operativProfit: "Operating profit",
    morjanalProfit: "Marginal profit",
    totalBalance: "Total balance",
    paymentDifference: "Payment difference",
    accounts: "Accounts",
    sources: "Categories",
    source: "category",
    Usource: "Category",
    sourceType: "Category type",
    addsource: "Add category",
    entersource: "Enter category name",
    editsource: "edit category",
    selectAccount: "Select an account",
    account: "Account",
    contingents: "Counterparties",
    contingent: "Counterparty",
    addcontingent: `Adding a new counterparty`,
    typeContingent: "Counterparty type",
    selectContingent: "Select counterparty",
    addContingent: "Add counterparty",
    nameContingent: "Counterparty name",
    enterContingent: "Enter the counterparty name",
    selectYear: "Select year",
    selectDate: "Select date",
    selectMonth: "Select month",
    selectSource: "Select category",
    selectCurrency: "Select currency",
    enterCurrencyRate: "Enter exchange rate",
    selectUser: "Select employee",
    more: "Detailed",
    filter: "Filter",
    check: "Check",
    save: "save",
    cancel: "cancel",
    downloadExcel: "Download Excel",
    amount: "Quantity",
    enterAmount: "enter the quantity",
    add: "Add",
    _add: "add",
    enterPrice: "Enter the price",
    remain: "balance",
    entitlement: "debtor",
    deposit: "debt",
    logout: "Log out",
    doTransfer: "Transfer",
    editInfo: ` data editing`,
    priceSum: "Price (Sum)",
    successMessage: "Added successfully",
  },
  months: {
    jan: "January",
    feb: "February",
    march: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  dashboard: {
    title: "Dashboard",
    incomeFact: "Income plan & fact",
    outcomeFact: "Expenditure plan & fact",
    restFact: "Operational balance",
    daromadFact: "Revenue plan & fact",
    xarajatFact: "expense plan & fact",
    foydaFact: "Profit plan & fact",
    paymentYear: "Payment calendar year",
    paymentCalendar: "Payment calendar",
  },
  subControlPanel: {
    title: "Management reports",
    balance: "Cash balance",
    expenses: "Category shares",
    income: "Income and expenditure",
    revenue: "Cash flow shares",
    profit_soft: "Revenue and Net profit",
    overhead: "Marginality",
    sourceMontly: "Category/Months",
  },
  subCheck: {
    uncorfirmedAcc: "Unconfirmed accounts",
    uncorfirmed: "Unconfirmed",
    unAnswered: "Unanswered",
  },
  subCash: {
    historyCash: "Cash history",
    historyTransfer: "Transfer history",
    exitCash: "Cash out",
    from: "From account",
    to: "To account",
    size: "Transferred amount",
    transferAmount: "Transfer amount",
    enterTransferAmount: "Transfer amount",
    transferDate: "Transfer date",
    toTransferDate: "Date of transfer",
    selectToAcc: "Select incoming account",
    selectFromAcc: "Select outgoing account",
    hisoboti: "Report",
    exactAccount: "Matching account number",
    exactAccountBalance: "Matching account balance",
    enterExactAccountBalance: "Enter matching account balance!",
    system: "System",
    difesit: "Shortfall",
    bankBalance: "Bank balance",
    systemBalance: "System balance",
    startMonthBalance: "Initial balance",
    currentBalance: "Current cash variance",
    financialBalance: "Financial cash variance",
    invsetialBalance: "Investment cash variance",
    changeAccount: "Account transfer",
    total_change: "Total variance",
    remains_last: "Month-end balance",
    profit: "Revenue",
    enterProfit: "Enter revenue",
    profitPercent: "Revenue percentage",
    marginProfit: "Marginal revenue",
    marginPercent: "Marginal percentage",
    overheadOutcome: "overhead costs",
    grossAll: "Gross Profit (+)/Loss(-)",
    grossRentabl: "Gross profit profitability %",
    operativeAll: "Operating Profit (+)/Loss(-)",
    operativRentabl: "Operating profit profitability %",
    deprecation: "Amortization",
    sof: "Net Profit (+)/Loss(-)",
    sofRentabl: "Net profit profitability %",
    remansTitle: "At the end of the month in sum",
    mainTools: "Fixed assets",
    partProfit: "Retained earnings",
    warehause: "Warehouse",
    material: "Raw material",
    halfProduct: "semi-finished product",
    product: "Finished product",
    debit: "Debtor",
    credit: "Creditor",
    duties: "liabilities",
    suplier: "From goods supplier",
    _suplier: "Goods supplier",
    customers: "From customer",
    customer: "Customer",
    selectCustomer: "Select a customer",
    emplyee: "From employees",
    rent: "From TAX",
    moneys: "money",
    balans: "Balance",
    personalCapital: "Personal capital",
    active: "Assets",
    passive: "Liabilities",
    workingCapital: "Working capital",
    budgetTitle: "Budget cash flow",
    budgetPnlTitle: "Budget Profit & Loss",
    budget_pnl_list: "Budget revenue & expenses",
    budget_pnl_by_month: "Budget Profit & Loss",
    selectAccount: "Please select one of the accounts",
  },
  subHelper: {
    storage: "Storage",
    storageAddTitle: "Add storage information",
    storageType: "Product Type",
    selectStorageType: "Select a product type",
    storageRowMaterials: "Raw materials",
    storageSemiFinished: "Semi-finished product",
    storageFinished: "Finished product",
    salary: "Salary",
    debitCredit: "Debit-credit",
    purchase: "Purchases",
    mainToll: "Fixed assets",
    title: "Supplementary reports",
    editTitle: "Edit salary",
    addTitle: "Add salary",
    editDutyTitle: "Edit liability",
    addDutyTitle: "Add liability",
    aktNumber: "Act number",
    enterAktNumber: "Enter the act number",
    aktDate: "Date of the act",
    serviceType: "Type of service",
    editAktTitle: "Edit liability",
    addAktTitle: "Add liability",
    editPurchaseTitle: "Edit purchase",
    addPurchaseTitle: "Add purchase",
    purchaseDate: "Purchase date",
    attritionMonthly: "Depreciation (by months)",
    monthly_rate: "One-month amortization",
    total_months: "Number of months used",
    current_balance: "Remaining balance (UZS)",
    initial_balance: "Initial price (UZS)",
    editMainTitle: "Edit fixed asset",
    addMainTitle: "Add fixed asset",
    editInformation: "Edit details",
    bookWithCustomers: "Settlements with customers",
    madeService: "Service provided",
    payedCustomer: "Customer paid",
    bookWithDillers: "Settlements with suppliers",
    buyed: "Purchased",
    payed: "Paid",
    bookWithEmployes: "Settlements with suppliers",
    calculated: "Calculated",
  },
  subInfo: {
    initial_money: "Initial balance",
    enterInitial_money: "Enter the initial balance",
    addAccount: "Add account",
    editAccount: "Edit account",
    nameAccount: "Account name",
    enterAccount: "Enter the account name",
    balanceAccount: "Account balance",
    enterBalanceAccount: "Enter the account balance",
    addDirection: "Add division",
    editDirection: "Edit division details",
    nameDirection: "The name of the division",
    enterDirection: "Enter the name of the division",
    phoneNumber: "Phone number",
    enterCtrPhone: "Enter the counterparty's phone number",
  },
  company: {
    alert: "Please enter your company first to use the system",
    addCompany: "Add company",
    nameCompany: "Company name",
    enterCompany: "Enter the company name",
  },
};
