import { Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteSalaryMutation,
  useGetSalaryMutation,
} from "src/app/services/finance";
import { ISalaryTable } from "src/app/services/finance/type";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { addKeyForData, handleMakeUrl } from "src/utils";
import AddSalary from "./add";
import { salaryColumns } from "./constants";
import { useTranslation } from "react-i18next";

function SalaryPage() {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getFunc, { data: total }] = useGetSalaryMutation();
  const [data, setData] = useState<ISalaryTable[]>([]);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getFunc(url)
      .unwrap()
      .then((res) => {
        setData(addKeyForData(res.results));
      });
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteFunc, { isSuccess: deleteStatus }] = useDeleteSalaryMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<ISalaryTable>();

  const onEdit = (val: number) => {
    let obj = data?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  //Table section
  const columns: ColumnsType<ISalaryTable> = [
    ...salaryColumns(t),
    {
      title: t("common.comment"),
      dataIndex: "comment",
      key: "comment",
      render: (val) => val,
    },
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (key, val) => (
        <TableActions
          onDelete={() => deleteFunc(key)}
          onEdit={() => setEditableData(val)}
        />
      ),
    },
  ];

  return (
    <div>
      <ContentTop>
        <AddSalary
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 800 }}
        pagination={false}
      />
      {total && <PaginationFilter total={total?.count} />}
    </div>
  );
}

export default SalaryPage;
