import { Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddSourceMutation,
  useEditSourceMutation,
} from "src/app/services/finance";
import { IAddSource, ISourceResData } from "src/app/services/finance/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import CategoryFormItem from "src/components/formItem/CategoryFormItem";
import ExpenseTypeFormItem from "src/components/formItem/ExpenseTypeFormItem";
import SourceTypeFormItem from "src/components/formItem/SourceTypeFormItem";

interface Props {
  editableData?: ISourceResData;
  setEditableData: React.Dispatch<
    React.SetStateAction<ISourceResData | undefined>
  >;
  handleGet: () => void;
}

function AddSource({ handleGet, editableData, setEditableData }: Props) {
  const { t } = useTranslation();
  //Modal section
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
    setIsExpense(false);
  };

  //Form section
  const [addSource] = useAddSourceMutation();
  const [editSource] = useEditSourceMutation();
  const [form] = Form.useForm();
  const onFinish = ({ type, ...rest }: IAddSource) => {
    let obj = { ...rest, type: type || null };
    if (editableData)
      editSource({ id: editableData.id, body: obj })
        .unwrap()
        .then(() => {
          message.success(`${editableData?.title} edited successfully!`);
          handleGet();
          closeModal();
        });
    else
      addSource(obj)
        .unwrap()
        .then(() => {
          message.success("Source added successfully!");
          handleGet();
          closeModal();
        });
  };

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { category, is_expense, ...rest } = editableData;
      form.setFieldsValue({
        category: category?.id?.toString(),
        is_expense,
        ...rest,
      });
      setIsExpense(is_expense);
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  //Helper functions
  const [isExpense, setIsExpense] = useState(false);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={500}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? editableData.title + ` ${t("common.editsource")}`
              : t("common.addsource")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item
            name="title"
            label={`${t("common.Usource")} *`}
            rules={[{ required: true, message: t("common.entersource") }]}
            className="full"
          >
            <Input />
          </Form.Item>

          <CategoryFormItem />

          <SourceTypeFormItem onChange={(e) => setIsExpense(e.target.value)} />

          {isExpense && <ExpenseTypeFormItem />}

          <Form.Item
            name="comment"
            label={`${t("common.subSources")} *`}
            rules={[{ required: true, message: t("common.enterSubSources") }]}
            className="full"
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddSource;
