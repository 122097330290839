import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCategoriesQuery } from "src/app/services/finance";
import { IOption } from "src/app/services/type";

interface Props {
  className?: string;
}

function CategoryFormItem({ className }: Props) {
  const { t } = useTranslation();
  const { data } = useGetCategoriesQuery();
  const [options, setOptions] = useState<IOption[]>([]);
  useEffect(() => {
    if (data) {
      let arr: IOption[] = [];
      data.results.forEach((item) => {
        arr.push({ label: item.title, value: item.id.toString() });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form.Item
      name="category"
      label={`${t("sidebar.cashflow.title")} *`}
      rules={[{ required: true, message: t("sidebar.cashflow.selectTitle") }]}
      className={className || "full"}
    >
      <Select allowClear options={options} />
    </Form.Item>
  );
}

export default CategoryFormItem;
