import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IContingent } from "src/app/services/management/type";
import { DebtCreditAttributes } from "src/app/services/type";
import { IDebtCreditTableObj, makeDebtCreditColumns } from "./columns";
import { useTranslation } from "react-i18next";
const { innerWidth } = window;

interface Props {
  title: string;
  titleColumn: string;
  titleColumn2: string;
  isLoading?: boolean;
  data?: IDebtCreditTableObj[];
  type?: DebtCreditAttributes;
}

interface IPerson extends IContingent {
  full_name: string;
}

export default function DebtCreditTable({ title, titleColumn, titleColumn2, isLoading, data, type }: Props) {
  const { t } = useTranslation();

  const Title = () => (
    <div className="debt_credit-table-title">
      {title}{" "}
      <span>
        {t("common.entitlement")} (+); {t("common.deposit")} (-)
      </span>
    </div>
  );

  const titleItem = {
    title: <Title />,
    dataIndex: "contingent",
    key: "contingent",
    width: 220,
    render: (val: IPerson) => val?.first_name,
  };

  if (innerWidth > 700) Object.assign(titleItem, { fixed: "left" });

  const columns: ColumnsType<IDebtCreditTableObj> = [
    titleItem,
    ...makeDebtCreditColumns({ title: titleColumn, title2: titleColumn2, t }),
  ];

  return (
    <div className="debt_credit-table">
      <Table
        scroll={{ x: 1200 }}
        bordered={true}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isLoading}
        className={"debt_credit-table-" + type}
      />
    </div>
  );
}
