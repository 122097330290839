import { ColumnsType } from "antd/es/table";
import { IDirection } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const directionColumns = (t: TFunctionType): ColumnsType<IDirection> => [
  {
    title: t("common.name"),
    dataIndex: "title",
    key: "title",
  },
];
