import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./team.scss";

// Import Swiper styles
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobilePartnersSlider2, isTablet, isTabletPartnersSlider } from "src/static/constants";
import { useGetTeamLandingQuery } from "src/app/services/management";
import { useTranslation } from "react-i18next";

const Team = () => {
  const swiperRef = useRef<SwiperCore>();
  const { data: team } = useGetTeamLandingQuery();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const { t } = useTranslation();

  return (
    <div className="section-team">
      <div className="team-wrap container">
        <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          {t("home.team.title")}
        </h2>
        <div className="team-experience">
          <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
            <span>18</span>
            <p>{t("home.team.card1Title")}</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="350" data-aos-duration="1000">
            <span>30+</span>
            <p>{t("home.team.card2Title")}</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
            <span>7</span>
            <p>{t("home.team.card3Title")}</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="650" data-aos-duration="1000">
            <span>100+</span>
            <p>{t("home.team.card4Title")}</p>
          </div>
        </div>
        <div className="team-wrap-members">
          <Swiper
            spaceBetween={24}
            slidesPerView={isMobilePartnersSlider2 ? "auto" : isTablet ? "auto" : isTabletPartnersSlider ? "auto" : 4}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="team-slide-cards"
          >
            {team?.map((member) => (
              <SwiperSlide className="team-slide-card" key={member.id}>
                <div className="team-slide-card-img">
                  <img src={member.image} alt="team member" />
                </div>
                <div className="team-slide-card-info">
                  <h4>{member.name}</h4>
                  <p>{member.position}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <LeftOutlined className="slider-prev" onClick={() => swiperRef.current?.slidePrev()} />
          <RightOutlined className="slider-next" onClick={() => swiperRef.current?.slideNext()} />
        </div>
      </div>
    </div>
  );
};

export default Team;
