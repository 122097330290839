import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  useGetAccountBalancesStatMutation,
  useGetDebtCreditMutation,
  useGetPnlMutation,
} from "src/app/services/finance";
import { IDebtCreditObj } from "src/app/services/finance/type";
import { getRootState } from "src/app/store";
import useParamsHook from "src/hooks/params";
import { findMonth, findMonthByIndex } from "src/utils";
import { IPnlData, IPnlObj } from "../pnl/type";
import { useTranslation } from "react-i18next";
import { TFunctionType } from "src/static/constants";

export default function useBalance() {
  const { t } = useTranslation();
  //Get section
  const { searchParams, handleMakeParams } = useParamsHook();
  const year = searchParams.get("date");

  const [data, setData] = useState<IBalanceItem[]>([]);
  const [loading, setLoading] = useState(false);

  const [getDebtCredit] = useGetDebtCreditMutation();
  const [getPnl] = useGetPnlMutation();
  const [getAccounts] = useGetAccountBalancesStatMutation();

  const handleGet = async () => {
    if (!year) {
      const date = new Date();
      handleMakeParams("date", date.getFullYear().toString());
      return;
    }
    let active_arr: IBalanceItem[] = [];
    let passive_arr: IBalanceItem[] = [];

    setLoading(true);
    try {
      const debtData = await getDebtCredit(
        `?from_date=${Number(year) - 1}-12-15&to_date=${year}-12-15`
      ).unwrap();
      const pnlData = await getPnl("").unwrap();
      const accountsData = await getAccounts(
        "?date=" + year + "&yearly=true"
      ).unwrap();

      const { customer, employee, rent, supplier } = debtData;
      const { soft, types } = pnlData;
      const { deprecation } = types;
      const { others } = accountsData;

      // Pnl data
      if (deprecation) {
        const { obj } = makePnlData(
          t("subCash.mainTools"),
          "_deprecation",
          deprecation
        );
        active_arr.push(obj);
      }
      if (soft) {
        const { obj } = makePnlData(t("subCash.partProfit"), "_soft", soft);
        passive_arr.push(obj);
      }

      active_arr.push(makeDefObj(t("subCash.warehause"), "_store"));
      active_arr.push(makeDefObj(t("subCash.material")));
      active_arr.push(makeDefObj(t("subCash.halfProduct"), "_store"));
      active_arr.push(makeDefObj(t("subCash.product"), "_store"));

      // Debit credit data
      const debt_obj = makeDefObj(t("subCash.debit"), "_debt");
      const credit_obj = makeDefObj(t("subCash.credit"), "_credit");
      const commitment_obj = makeDefObj(t("subCash.duties"), "_commitment");
      active_arr.push(debt_obj);
      passive_arr.push(commitment_obj);
      passive_arr.push(credit_obj);

      if (supplier) {
        const { active_obj, passive_obj } = makeDebtCreditData(
          t("subCash.suplier"),
          supplier,
          debt_obj,
          credit_obj
        );
        active_arr.push(active_obj);
        passive_arr.push(passive_obj);
      }
      if (customer) {
        const { active_obj, passive_obj } = makeDebtCreditData(
          t("subCash.customers"),
          customer,
          debt_obj,
          credit_obj
        );
        active_arr.push(passive_obj);
        passive_arr.push(active_obj);
      }
      if (employee) {
        const { active_obj, passive_obj } = makeDebtCreditData(
          t("subCash.emplyee"),
          employee,
          debt_obj,
          credit_obj
        );
        active_arr.push(active_obj);
        passive_arr.push(passive_obj);
      }
      if (rent) {
        const { active_obj, passive_obj } = makeDebtCreditData(
          t("subCash.rent"),
          rent,
          debt_obj,
          credit_obj
        );
        active_arr.push(active_obj);
        passive_arr.push(passive_obj);
      }

      // Accounts data
      let cash_obj = makeDefObj(t("subCash.moneys"), "_cash");
      active_arr.push(cash_obj);
      if (others) {
        others.forEach((item) => {
          let obj = makeDefObj(item.title, "_account");
          for (const [key, value] of Object.entries(item.data)) {
            obj[findMonthByIndex(Number(key))] = Math.round(
              value?.converted_balance
            );
            cash_obj[findMonthByIndex(Number(key))] =
              cash_obj[findMonthByIndex(Number(key))] +
              (Math.round(value?.converted_balance) || 0);
          }
          active_arr.push(obj);
        });
      }

      // Working capital data
      const working_capital_obj = handleAddData(
        t("subCash.workingCapital"),
        "working_capital",
        active_arr,
        [t("subCash.debit"), t("subCash.moneys")]
      );
      active_arr.splice(1, 0, working_capital_obj);

      // // Working capital data
      // const working_capital_obj = handleAddData(
      //   "Aylanma mablag`lar",
      //   "working_capital",
      //   active_arr,
      //   ["Debitorka", "Pullar"]
      // );
      // active_arr.splice(1, 0, working_capital_obj);

      // Aktive balance data
      const active_balance_obj = handleAddData(
        t("subCash.balans"),
        "active_balance",
        active_arr,
        [t("subCash.mainTools"), t("subCash.workingCapital")]
      );
      active_arr.push(active_balance_obj);

      //Personal capital data
      const personal_capital_obj = handleDivorceData(
        t("subCash.personalCapital"),
        "personal_capital",
        active_balance_obj,
        commitment_obj
      );
      passive_arr.unshift(personal_capital_obj);

      // Passive balance data
      const passive_balance_obj = handleAddData(
        t("subCash.balans"),
        "passive_balance",
        passive_arr,
        [t("subCash.personalCapital"), t("subCash.duties")]
      );
      passive_arr.push(passive_balance_obj);

      setData([
        { key: "active", title: t("subCash.active") },
        ...active_arr,
        { key: "passive", title: t("subCash.passive") },
        ...passive_arr,
      ]);
      setLoading(false);
      updateNegativeCellColor();
    } catch (error) {}
  };

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    data,
    columns,
    loading,
  };
}

const handleAddData = (
  title: string,
  key: string,
  arr: IBalanceItem[],
  inludes_keys: string[]
) => {
  let obj = makeDefObj(title, key);
  arr.forEach((item) => {
    if (item.title && inludes_keys.includes(item.title)) {
      obj = {
        key: title + key,
        title,
        jan: obj.jan + (item.jan || 0),
        feb: obj.feb + (item.feb || 0),
        mar: obj.mar + (item.mar || 0),
        apr: obj.apr + (item.apr || 0),
        may: obj.may + (item.may || 0),
        june: obj.june + (item.june || 0),
        july: obj.july + (item.july || 0),
        aug: obj.aug + (item.aug || 0),
        sep: obj.sep + (item.sep || 0),
        oct: obj.oct + (item.oct || 0),
        nov: obj.nov + (item.nov || 0),
        dec: obj.dec + (item.dec || 0),
      };
    }
  });

  return {
    ...obj,
  };
};

const handleDivorceData = (
  title: string,
  key: string,
  obj1: IBalanceItem,
  obj2: IBalanceItem
) => {
  let obj = {
    key: title + key,
    title,
    jan: (obj1.jan || 0) - (obj2.jan || 0),
    feb: (obj1.feb || 0) - (obj2.feb || 0),
    mar: (obj1.mar || 0) - (obj2.mar || 0),
    apr: (obj1.apr || 0) - (obj2.apr || 0),
    may: (obj1.may || 0) - (obj2.may || 0),
    june: (obj1.june || 0) - (obj2.june || 0),
    july: (obj1.july || 0) - (obj2.july || 0),
    aug: (obj1.aug || 0) - (obj2.aug || 0),
    sep: (obj1.sep || 0) - (obj2.sep || 0),
    oct: (obj1.oct || 0) - (obj2.oct || 0),
    nov: (obj1.nov || 0) - (obj2.nov || 0),
    dec: (obj1.dec || 0) - (obj2.dec || 0),
  };

  return {
    ...obj,
  };
};

const makePnlData = (title: string, key: string, object: IPnlData<IPnlObj>) => {
  let obj = makeDefObj(title, key);
  for (const [key, value] of Object.entries(object)) {
    obj[findMonthByIndex(Number(key))] = Math.round(value?.amount);
  }

  return {
    obj,
  };
};

const makeDebtCreditData = (
  title: string,
  arr: IDebtCreditObj[],
  debt_obj: IBalanceItem,
  credit_obj: IBalanceItem
) => {
  let active_obj = makeDefObj(title, "_active");
  let passive_obj = makeDefObj(title, "_passive");
  const is_customer = title === "Xaridorlardan";
  arr.forEach((item) => {
    const month = findMonth(item.date.slice(5, 7));
    let { remaining } = item;
    remaining = Math.round(remaining);
    if (!month) return;
    if (remaining < 0) {
      active_obj[month] = active_obj[month] + remaining * -1;
      if (is_customer)
        credit_obj[month] = (credit_obj[month] || 0) + remaining * -1;
      else debt_obj[month] = (debt_obj[month] || 0) + remaining * -1;
    } else if (remaining > 0) {
      passive_obj[month] = passive_obj[month] + remaining;
      if (is_customer) debt_obj[month] = (debt_obj[month] || 0) + remaining;
      else credit_obj[month] = (credit_obj[month] || 0) + remaining;
    }
  });

  return {
    active_obj,
    passive_obj,
  };
};

const makeDefObj = (title: string, key = "") => {
  return {
    key: title + key,
    title,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    june: 0,
    july: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
  };
};

const updateNegativeCellColor = async () => {
  var intervalId = setInterval(updateColor, 100);
  function updateColor() {
    document.querySelectorAll("td").forEach(function (td) {
      let prefix = td.textContent?.[0];
      if (prefix === "-" && typeof Number(td.textContent) === "number") {
        td.style.color = getRootState().layout.colors.color_red;
      }
    });
  }
  setTimeout(function () {
    clearInterval(intervalId);
  }, 400);
};

const { innerWidth } = window;

const titleColumn = (t: TFunctionType) => ({
  title: t("subCash.remansTitle"),
  dataIndex: "title",
  key: "title",
  width: 200,
});

if (innerWidth > 700) Object.assign(titleColumn, { fixed: "left" });

const columns = (t: TFunctionType): ColumnsType<IBalanceItem> => [
  titleColumn(t),
  {
    title: t("months.jan"),
    dataIndex: "jan",
    key: "jan",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.feb"),
    dataIndex: "feb",
    key: "feb",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.march"),
    dataIndex: "mar",
    key: "mar",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.apr"),
    dataIndex: "apr",
    key: "apr",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.may"),
    dataIndex: "may",
    key: "may",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.jun"),
    dataIndex: "june",
    key: "june",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.jul"),
    dataIndex: "july",
    key: "july",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.aug"),
    dataIndex: "aug",
    key: "aug",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.sep"),
    dataIndex: "sep",
    key: "sep",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.oct"),
    dataIndex: "oct",
    key: "oct",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.nov"),
    dataIndex: "nov",
    key: "nov",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
  {
    title: t("months.dec"),
    dataIndex: "dec",
    key: "dec",
    width: 80,
    render: (val) => val?.toLocaleString(),
  },
];

export interface IBalanceItem {
  key?: string;
  title?: string;
  jan?: number;
  feb?: number;
  mar?: number;
  apr?: number;
  may?: number;
  june?: number;
  july?: number;
  aug?: number;
  sep?: number;
  oct?: number;
  nov?: number;
  dec?: number;
}

export interface IBalanceMonths extends Omit<IBalanceItem, "key" | "title"> {}
