import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { ICommitmentTable } from "../services/finance/type";

export interface ICommitmentState {
  commitments?: ICommitmentTable[];
}

const initialState: ICommitmentState = {
  commitments: undefined,
};

const commitmentSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getCommitment.matchFulfilled, (state, action) => {
      let arr: ICommitmentTable[] = [];
      action.payload.results.forEach((item) => {
        let { key, ...rest } = item;
        arr.push({ key: item.id, ...rest });
      });
      state.commitments = arr;
    });
  },
});

export default commitmentSlice.reducer;
