import { useEffect } from "react";
import { changeTheme } from "src/app/slices/layoutSlice";
import { useAppDispatch } from "src/app/store";
import SectionAbout from "./components/about/about";
import SectionContact from "./components/contact";
import SectionExplain from "./components/explain/explain";
import Footer from "./components/footer";
import Header from "./components/header";
import HeroSection from "./components/hero/hero";
import Partners from "./components/partners/partners";
import Priority from "./components/priority/Priority";
import Team from "./components/team/team";
import "./home.scss";

function HomePage() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeTheme("light"));
  }, []);
  return (
    <div className="home">
      <div className="background-lines-container">
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
        <div className="backline"></div>
      </div>
      <Header />
      <HeroSection />
      <SectionExplain />
      <SectionAbout />
      <Priority />
      <Team />
      <Partners />
      <SectionContact />
      <Footer />
    </div>
  );
}

export default HomePage;
