import { message } from "antd";
import { useEffect, useState } from "react";
import { useDeletePurchaseMutation, useGetPurchaseMutation } from "src/app/services/finance";
import { IPurchaseRes } from "src/app/services/finance/type";
import useParamsHook from "src/hooks/params";
import { addKeyForData, handleMakeUrl } from "src/utils";

export default function usePurchase() {
  //Get section
  const { searchParams } = useParamsHook();

  const [getFunc, { data: total, isLoading }] = useGetPurchaseMutation();
  const [data, setData] = useState<IPurchaseRes[]>([]);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getFunc(url)
      .unwrap()
      .then((res) => {
        setData(addKeyForData(res.results));
      });
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteFunc, { isSuccess: deleteStatus }] = useDeletePurchaseMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<IPurchaseRes>();
  return {
    data,
    total,
    editableData,
    isLoading,
    handleGet,
    setEditableData,
    deleteFunc,
  };
}
