import { Col, Form, FormItemProps, Select, SelectProps } from "antd";

type CombinedType = FormItemProps & SelectProps;

export interface SelectFormItemProps extends CombinedType {
  col?: number;
  message?: string;
}

function SelectFormItem({
  col = 12,
  name,
  label,
  message,
  placeholder,
  disabled,
  onChange,
  options,
  ...rest
}: SelectFormItemProps) {
  return (
    <Col span={col}>
      <Form.Item name={name} label={label} rules={!!message ? [{ required: true, message }] : []}>
        <Select
          placeholder={placeholder}
          allowClear
          options={options}
          onChange={onChange}
          disabled={disabled}
          variant="filled"
          {...rest}
        />
      </Form.Item>
    </Col>
  );
}

export default SelectFormItem;
