import { useEffect, useState } from "react";
import { useDeleteBudgetPnlMutation, useGetBudgetPnlMutation } from "src/app/services/finance";
import { IBudgetPnlRes } from "src/app/services/finance/type";

export default function useBudjetPnlList() {
  //Fetch
  const [data, setData] = useState<IBudgetPnlRes[]>();
  const [get, { isLoading, data: total }] = useGetBudgetPnlMutation();
  const handleGet = () => {
    get()
      .unwrap()
      .then((res) => {
        let arr = res.results.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setData(arr);
      });
  };

  //Delete
  const [del] = useDeleteBudgetPnlMutation();
  const handleDelete = (id: number) => {
    del(id)
      .unwrap()
      .then(() => {
        handleGet();
      });
  };

  useEffect(() => {
    handleGet();
  }, []);

  //Edit
  const [editData, setEditData] = useState<IBudgetPnlRes>();
  const handleEdit = (val: IBudgetPnlRes) => {
    setEditData(val);
  };

  return {
    isLoading,
    data,
    total,
    handleGet,
    handleDelete,
    handleEdit,
    editData,
    setEditData,
  };
}
