import ReactEcharts from "echarts-for-react";
import CountUp from "react-countup";
import CustomSelect from "src/components/common/select";
import "../dashboard.scss";
import { isMobile } from "src/static/constants";
import { useGetDirectionMutation } from "src/app/services/finance";
import { useEffect, useState } from "react";
import { IOption } from "src/app/services/type";
import { useTypedSelector } from "src/app/store";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  plan?: number;
  fact?: number;
  type?: "cashflow" | "pnl" | "pnl_expence" | "pnl_direction";
  onFilterChange?: (val: string) => void;
}

function PlanFactCard({
  title,
  plan,
  fact,
  type = "cashflow",
  onFilterChange,
}: Props) {
  const { t } = useTranslation();
  const option = {
    tooltip: {
      trigger: "axis",
      align: "center",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      textStyle: {
        color: "#495057",
        fontSize: 12,
      },
    },
    xAxis: {
      type: "category",
      data: [t("common.plan"), t("common.fact")],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    // legend: {},
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      containLabel: true,
      bottom: "20%",
    },
    series: [
      {
        type: "bar",
        barWidth: "50%",
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: "#8275ff",
        },
        data: [plan, fact],
      },
    ],
  };

  const gap = fact && plan ? fact - plan : fact ? fact : plan ? -plan : 0;

  const percentage =
    (!fact && !plan) || (fact && !plan)
      ? 100
      : !fact && plan
      ? 0
      : plan && fact
      ? Math.round((fact / plan) * 100).toLocaleString()
      : 0;

  const cashflowOptions = [
    { label: t("common.barchasi"), value: "all" },
    { label: t("common.currentMoneyFlow"), value: "Жорий пул оқими" },
    { label: t("common.financialMoneyFlow"), value: "Молиявий пул оқими" },
    { label: t("common.invistationMoneyFlow"), value: "Инвестицион пул оқими" },
  ];

  const pnlOptions = [
    { label: t("common.netProfit"), value: "soft" },
    { label: t("common.grosProfit"), value: "gross" },
    { label: t("common.operativProfit"), value: "operative" },
    { label: t("common.morjanalProfit"), value: "margin" },
  ];

  const pnlExpenceOptions = [
    { label: t("common.barchasi"), value: "all" },
    { label: t("common.naklodnoyOutcome"), value: "overhead" },
    { label: t("common.administrativeOutcome"), value: "administrative" },
    { label: t("common.commersialOutcome"), value: "commercial" },
    { label: t("common.taxes"), value: "tax" },
    { label: t("common.value"), value: "cost" },
  ];

  const directionOptions = [
    { label: t("common.naklodnoyOutcome"), value: "overhead" },
  ];

  return (
    <div className="income-card dashboard-card" draggable>
      <div className="dashboard-card-title">
        <p>{title}</p>
        {onFilterChange && (
          <CustomSelect
            height={32}
            colorTextPlaceholder="#fff"
            colorBorder="#878a99"
            options={
              type === "cashflow"
                ? cashflowOptions
                : type === "pnl"
                ? pnlOptions
                : type === "pnl_direction"
                ? directionOptions
                : pnlExpenceOptions
            }
            defaultValue={
              type === "pnl" ? "soft" : type === "pnl_direction" ? "" : "all"
            }
            onChange={onFilterChange}
            style={{ width: 160 }}
            fontSize={isMobile ? 14 : 16}
          />
        )}
      </div>
      <div className="income-card-body">
        <div className="income-card-left">
          <div className="dashboard-card-left-title">
            <p>{t("common.difference")}</p>
          </div>
          <div className="income-card-left-content">
            <h4>{percentage}%</h4>
            <p>
              <CountUp
                start={0}
                end={gap}
                separator=" "
                duration={1.75}
                useEasing={true}
                style={{ color: gap >= 0 ? "#45cb85" : "#f06548" }}
              />
            </p>
          </div>
        </div>
        <div className="dashboard-card-right">
          <div className="dashboard-card-right-top">
            <p>{t("common.plan")}</p>
            <div></div>
            <p>{t("common.fact")}</p>
          </div>
          <ReactEcharts
            opts={{ renderer: "svg", height: 230 }}
            option={option}
          />
        </div>
      </div>
    </div>
  );
}
export default PlanFactCard;
