import { ColumnsType } from "antd/es/table";
import { ICommitmentTable } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const commitmentColumns = (
  t: TFunctionType
): ColumnsType<ICommitmentTable> => [
  {
    title: t("common.date"),
    dataIndex: "date",
    key: "date",
    render: (val) => val.slice(0, 7),
    width: 84,
  },
  {
    title: `${t("common.amount")} (${t("common.uzs")})`,
    dataIndex: "amount",
    key: "amount",
    width: 200,
    render: (el: number) => el.toLocaleString(),
  },
  {
    title: t("common.contingent"),
    dataIndex: "contingent",
    key: "contingent",
    render: (val) => val?.title,
  },
  {
    title: t("common.Usource"),
    dataIndex: "source",
    key: "source",
    render: (val) => val?.title,
  },
];
