import { Anchor } from "antd";
import BalancePage from "./balance";
import { cashflowStatMenu } from "../constants";
import ExpensesPage from "./expenses";
import PortionPage from "./portion";
import ProfitPage from "./profit";
import RevenuePage from "./revenue";
import "../control_panel.scss";
import { useTranslation } from "react-i18next";

function CashflowStats() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="control_panel">
        <div className="control_panel-menu">
          <Anchor items={cashflowStatMenu(t)} direction="horizontal" />
        </div>
        <div className="control_panel-content">
          <BalancePage />
          <ProfitPage />
          <PortionPage />
          <RevenuePage />
          <ExpensesPage />
        </div>
      </div>
    </div>
  );
}

export default CashflowStats;
