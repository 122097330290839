export const signUpFormData = [
  {
    name: "first_name",
    label: "auth.signUp.firstNameLabel",
    message: "Please input your name!",
    className: "half auth-form-half",
  },
  {
    name: "last_name",
    label: "auth.signUp.lastNameLabel",
    message: "Please input your surname!",
    className: "half auth-form-half",
  },
  {
    name: "email",
    label: "auth.signUp.emailLabel",
    message: "Please input your email!",
    className: "full",
  },
  {
    name: "password",
    label: "auth.signUp.passwordLabel",
    message: "Please input your password!",
    className: "half auth-form-half",
    password: true,
  },
  {
    name: "confirm_password",
    label: "auth.signUp.confirmPasswordLabel",
    message: "Please confirm your password!",
    className: "half auth-form-half",
    password: true,
    confirm: true,
  },
];

export const signInFormData = [
  {
    name: "email",
    label: "auth.signIn.emailLabel",
    message: "Please input your email!",
    className: "full",
    type: "email",
  },
  {
    name: "password",
    label: "auth.signIn.passwordLabel",
    message: "Please input your password!",
    className: "full",
    password: true,
  },
];

export const commentFormData = [
  {
    name: "first_name",
    label: "auth.signUp.firstNameLabel",
    message: "Please input your name!",
    className: "half auth-form-half",
  },
  {
    name: "last_name",
    label: "auth.signUp.lastNameLabel",
    className: "half auth-form-half",
  },
  {
    name: "email",
    label: "auth.signUp.emailLabel",
    className: "full",
  },
  {
    name: "phone_number",
    label: "auth.comment.phone",
    message: "Please input your phone number!",
    className: "full",
  },
  {
    name: "comment",
    label: "auth.comment.businessLabel",
    className: "full",
  },
];
