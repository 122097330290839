import { Form, InputNumber, Select, Table, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAddTransactionMutation,
  useGetAccountMutation,
  useGetTransactionsMutation,
} from "src/app/services/finance";
import { IAccount, ITransactionForm } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import { TransferIcon, TransferIcon1, TransferIcon2 } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomSwitch from "src/components/common/switch";
import { dateFormat } from "src/components/filter/FilterDate";
import PaginationFilter from "src/components/filter/PaginationFilter";
import DateFormItem from "src/components/formItem/DateFormItem";
import ContentTop from "src/layout/content/ContentTop";
import { amountFormatter, amountParser } from "src/static/form";
import { handleMakeUrl } from "src/utils";
import TrasnsferCard from "./components/TrasnsferCard";
import { transferColumns } from "./constants";
import "./transfer.scss";
import { useTranslation } from "react-i18next";

function TransferPage() {
  const { t } = useTranslation();
  const [getAccount, { data }] = useGetAccountMutation();
  const setToday = () => {
    const date = new Date();
    form.setFieldsValue({
      date: dayjs(date.toISOString().split("T")[0], dateFormat),
    });
  };
  useEffect(() => {
    getAccount("limit=10000");
    setToday();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = Form.useForm();
  const { Option } = Select;

  const [fromAccount, setFromAccount] = useState<IAccount>();
  const [toAccount, setToAccount] = useState<IAccount>();

  const handleChange = (val: string, type: string) => {
    let obj = data?.results.find((el) => el.id === +val);
    if (obj) {
      if (type === "from") setFromAccount(obj);
      else setToAccount(obj);
    }
    if (!val) {
      if (type === "from") setFromAccount(undefined);
      else setToAccount(undefined);
    }
  };

  const [addTransaction] = useAddTransactionMutation();

  const onFinish = (val: ITransactionForm) => {
    if (fromAccount && toAccount) {
      let { date, ...rest } = val;
      let obj = {
        date: date?.format(dateFormat),
        transaction_from: fromAccount.id,
        transaction_to: toAccount.id,
        ...rest,
      };
      addTransaction(obj)
        .unwrap()
        .then(() => {
          message.success("O'tkazma muvaffaqiyatli yakunlandi!");
          getAccount("limit=10000");
          handleReset();
        });
    }
  };

  //History section
  const [searchParams] = useSearchParams();
  const [history, setHistory] = useState(false);
  const onChangeHistory = (val: boolean) => {
    setHistory(val);
    if (val) {
      handleGet();
      handleReset();
    }
  };
  const [getTransactions, { data: transactionData, isLoading }] =
    useGetTransactionsMutation();
  const { transactions } = useTypedSelector((state) => state.transaction);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getTransactions(url);
  };

  const handleReset = () => {
    setFromAccount(undefined);
    setToAccount(undefined);
    form.resetFields();
    setToday();
  };

  const isCurrency =
    fromAccount && toAccount && fromAccount.currency !== toAccount.currency;

  return (
    <div className="transfer">
      <ContentTop>
        <div className="transfer-switch">
          <p>{t("subCash.historyTransfer")}</p>{" "}
          <CustomSwitch onChange={onChangeHistory} />
        </div>
      </ContentTop>
      {history ? (
        <div>
          <Table
            columns={transferColumns(t)}
            dataSource={transactions}
            scroll={{ x: 800 }}
            pagination={false}
            style={{ width: "100%" }}
            loading={isLoading}
          />
          {transactionData && (
            <PaginationFilter total={transactionData?.count} />
          )}
        </div>
      ) : (
        <div className="monitor-main">
          <div className="transfer-content">
            <div className="transfer-content-column">
              <Select
                allowClear
                onChange={(val) => handleChange(val, "from")}
                placeholder={t("subCash.selectFromAcc")}
                value={fromAccount && String(fromAccount?.id)}
              >
                {data?.results.map((item) => {
                  return (
                    <Option
                      disabled={item.id === toAccount?.id}
                      value={String(item.id)}
                      key={item.id}
                    >
                      {item.title}
                    </Option>
                  );
                })}
              </Select>
              <TrasnsferCard
                name={fromAccount?.title}
                price={fromAccount?.balance}
                currency={fromAccount?.currency}
                icon={<TransferIcon1 />}
                className="transfercard-1"
              />
            </div>
            <TransferIcon />
            <div className="transfer-content-column">
              <Select
                value={toAccount && String(toAccount?.id)}
                allowClear
                onChange={(val) => handleChange(val, "to")}
                placeholder={t("subCash.selectToAcc")}
              >
                {data?.results.map((item) => {
                  return (
                    <Option
                      disabled={item.id === fromAccount?.id}
                      value={String(item.id)}
                      key={item.id}
                    >
                      {item.title}
                    </Option>
                  );
                })}
              </Select>
              <TrasnsferCard
                name={toAccount?.title}
                price={toAccount?.balance}
                currency={toAccount?.currency}
                icon={<TransferIcon2 />}
                className="transfercard-2"
              />
            </div>
          </div>

          <div className="transfer-action">
            <Form
              form={form}
              name="normal_login"
              className="form-form"
              onFinish={onFinish}
              layout={"vertical"}
              requiredMark={false}
            >
              <Form.Item
                name="amount"
                label={`${t("subCash.transferAmount")} (${t(
                  `common.${fromAccount?.currency ?? "uzs"}`
                )}) *`}
                rules={[
                  { required: true, message: t("subCash.enterTransferAmount") },
                ]}
                className="form_item"
              >
                <InputNumber
                  disabled={!fromAccount && !toAccount}
                  style={{ width: "100%" }}
                  formatter={amountFormatter}
                  parser={amountParser}
                  max={fromAccount?.balance}
                />
              </Form.Item>

              <DateFormItem
                className="form_item"
                label={t("subCash.toTransferDate")}
              />

              {isCurrency && (
                <Form.Item
                  name="exchange_rate"
                  label={t("common.currencyRateForDollar")}
                  rules={[
                    { required: true, message: t("common.enterCurrencyRate") },
                  ]}
                  className="form_item"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
              )}

              <Form.Item style={{ width: 110 }}>
                <CustomButton
                  className="transfer-action-btn"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("common.doTransfer")}
                </CustomButton>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}

export default TransferPage;
