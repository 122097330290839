import { Table } from "antd";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import "./balance.scss";
import useBalance from "./useBalance";
import { useTranslation } from "react-i18next";

function BalancePage() {
  const { t } = useTranslation();
  const { data, columns, loading } = useBalance();

  return (
    <div className="final_balance">
      <ContentTop>
        <FilterDate format="year" />
      </ContentTop>

      <Table
        columns={columns(t)}
        dataSource={data}
        loading={loading}
        scroll={{ x: 2000 }}
        pagination={false}
        bordered
      />
    </div>
  );
}

export default BalancePage;
