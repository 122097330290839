import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPnlProfitSoftStatMutation } from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import { window_757 } from "src/static/constants";
import { handleMakeUrlNoPage } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import { monthes } from "../../constants";
import { useTranslation } from "react-i18next";

export default function ProfitAndSoftStat() {
  const { t } = useTranslation();
  const { colors } = useTypedSelector((state) => state.layout);
  //Get section
  const [searchParams] = useSearchParams();
  const [getPnl] = useGetPnlProfitSoftStatMutation();
  const { profit_and_soft } = useTypedSelector((state) => state.pnl);

  const handleGet = () => {
    let url = "type=profit_and_soft" + handleMakeUrlNoPage(searchParams);
    getPnl(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {
      textStyle: {
        color: colors.color_black,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "15%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: monthes,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        height: 200,
      },
    },
    series: [
      {
        data: profit_and_soft?.profit,
        name: t("common.profit"),
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#EB4C42",
        },
      },
      {
        data: profit_and_soft?.soft,
        name: t("common.netProfit"),
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#E5AE88",
        },
      },
    ],
  };

  return (
    <div className="balance" id="profit_soft">
      <div className="balance-content">
        <ChartHeader name={t("subControlPanel.profit_soft")} />
        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content">
            <ReactEcharts
              opts={{ renderer: "svg", height: window_757 ? 500 : 586 }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
