export const partners = [
  "partner1.1",
  "partner1.2",
  "partner1.3",
  "partner1.4",
  "partner1.5",
  "partner1.6",
  "partner1.7",
  // "partner-8",
  // "partner-9",
  "partner1.10",
  "partner1.11",
  // "partner-12",
  "partner1.13",
  "partner1.14",
  "partner1.15",
];
