import { Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddDirectionMutation,
  useEditDirectionMutation,
} from "src/app/services/finance";
import { IDirection, IAddDirection } from "src/app/services/finance/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";

interface Props {
  handleGet: () => void;
  editableData?: IDirection;
  setEditableData: React.Dispatch<React.SetStateAction<IDirection | undefined>>;
}

function AddDirection({ handleGet, editableData, setEditableData }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addDirection, { isSuccess: addStatus }] = useAddDirectionMutation();
  const [editDirection, { isSuccess: editStatus }] = useEditDirectionMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IAddDirection) => {
    if (editableData) editDirection({ id: editableData.id, body: val });
    else addDirection(val);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Yo'nalish muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`${editableData?.title} muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { title } = editableData;
      form.setFieldsValue({
        title,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? t("subInfo.editDirection")
              : t("subInfo.addDirection")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item
            name="title"
            label={t("subInfo.nameDirection")}
            rules={[{ required: true, message: t("subInfo.enterDirection") }]}
            className="full"
          >
            <Input />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddDirection;
