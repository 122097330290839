import { Form, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAddBasicToolsMutation, useEditBasicToolsMutation } from "src/app/services/finance";
import { IBasicTools, IBasicToolsRes, IFormBasicTools } from "src/app/services/finance/type";
import { IBaseAddProps } from "src/app/services/type";
import { dateFormat } from "src/components/filter/FilterDate";

export interface IAddProps extends IBaseAddProps<IBasicToolsRes> {}

export default function useAdd({ handleGet, editableData, setEditableData }: IAddProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData?.(undefined);
    form.resetFields();
  };

  //Form section
  const [addBasicTools, { isSuccess: addStatus }] = useAddBasicToolsMutation();
  const [editBasicTools, { isSuccess: editStatus }] = useEditBasicToolsMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormBasicTools) => {
    let { date, ...rest } = val;
    let obj: IBasicTools = {
      ...rest,
      date: date?.format(dateFormat),
    };

    if (editableData) editBasicTools({ id: editableData.id, body: obj });
    else addBasicTools(obj);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Asosiy vosita muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`Asosiy vosita muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { date, ...rest } = editableData;

      form.setFieldsValue({
        date: dayjs(date, dateFormat),
        ...rest,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);
  return { isModalOpen, form, showModal, closeModal, onFinish };
}
