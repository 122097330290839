import { createSlice } from "@reduxjs/toolkit";
import { ICashflowPredTableItem, ICashflowTableItem } from "src/pages/sub_control_reports/cashflow/type";
import { IPnlData, IPnlObj, IPnlObjDetail, IPnlRes, IPnlTable, IProfit } from "src/pages/sub_control_reports/pnl/type";
import { financeApi } from "../services/finance";

interface IProfitAndSoft {
  profit: number[];
  soft: number[];
  total_profit: number;
  total_soft: number;
}

interface IPnlStat {
  data: number[];
  total: number;
}

export interface IPnlState {
  profit_and_soft?: IProfitAndSoft;
  pnl_stat?: IPnlStat;
  pnl?: Partial<IPnlTable<ICashflowTableItem>>;
  budget_pnl?: Partial<IPnlTable<ICashflowPredTableItem>>;
}

const initialState: IPnlState = {};

const pnlSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getPnlProfitSoftStat.matchFulfilled, (state, action) => {
      let obj: IProfitAndSoft = {
        profit: [],
        soft: [],
        total_profit: 0,
        total_soft: 0,
      };

      for (let index = 1; index < 13; index++) {
        let { profit, soft } = action.payload;
        let profit_value = profit[index.toString()]?.amount || 0;
        let soft_value = soft[index.toString()]?.amount || 0;
        obj.profit.push(profit_value);
        obj.soft.push(soft_value);
        obj.total_profit += profit_value;
        obj.total_soft += soft_value;
      }

      state.profit_and_soft = obj;
    });
    builder.addMatcher(financeApi.endpoints.getPnlStat.matchFulfilled, (state, action) => {
      let obj: IPnlStat = {
        data: [],
        total: 0,
      };

      for (let index = 1; index < 13; index++) {
        let value = action.payload[index.toString()]?.amount || 0;
        obj.data.push(value);
        obj.total += value;
      }

      state.pnl_stat = obj;
    });
    builder.addMatcher(financeApi.endpoints.getPnl.matchFulfilled, (state, action) => {
      state.pnl = makePnlData(action.payload);
    });
    builder.addMatcher(financeApi.endpoints.getBudgetPnlDiagram.matchFulfilled, (state, action) => {
      state.budget_pnl = makePnlPredData(action.payload);
    });
  },
});

const makePnlData = (payload: IPnlRes) => {
  const { data, monthly_profits, margin, gross, operative, soft, types } = payload;
  const { administrative, commercial, overhead, tax, deprecation } = types;
  const {
    profit: profit_d,
    margin: margin_d,
    overhead: overhead_d,
    administrative: administrative_d,
    commercial: commercial_d,
    tax: tax_d,
    deprecation: deprecation_d,
  } = data;

  let obj: Partial<IPnlTable<ICashflowTableItem>> = {};

  //Profit
  if (profit_d) {
    let profit_detail_arr: ICashflowTableItem[] = [];
    let cost_detail_arr: ICashflowTableItem[] = [];
    profit_d.forEach((el) => {
      profit_detail_arr.push({
        key: el.title + "_profit_detail_green",
        sources: el.title,
        ...makePnlProfit(el.data, "amount"),
      });
      cost_detail_arr.push({
        key: el.title + "_cost_detail_pink",
        sources: el.title,
        ...makePnlProfit(el.data, "cost"),
      });
      cost_detail_arr.push({
        key: el.title + "_percentages_pink",
        sources: "Daromaddagi %",
        ...makePnlProfit(el.data, "percentage"),
      });
    });
    obj.profit_detail = profit_detail_arr;
    obj.cost_detail = cost_detail_arr;
  }

  if (monthly_profits) {
    obj.profit = makePnlProfit(monthly_profits, "amount");
    obj.cost = makePnlProfit(monthly_profits, "cost");
    obj.cost_percentages = makePnlProfit(monthly_profits, "percentage");
  }

  // Marganal;
  if (margin) {
    obj.margin = makePnlTypeData(margin, "amount");
    obj.margin_percentages = makePnlTypeData(margin, "percentage");
  }

  if (margin_d) {
    let margin_detail_arr: ICashflowTableItem[] = [];
    margin_d.forEach((el) => {
      margin_detail_arr.push({
        key: el.title + "_margin_detail_green",
        sources: el.title,
        ...makePnlProfit(el.data, "amount"),
      });
      margin_detail_arr.push({
        key: el.title + "_margin_detail_percentages_green",
        sources: "Marjanalnist %",
        ...makePnlProfit(el.data, "percentage"),
      });
    });
    obj.margin_detail = margin_detail_arr;
  }

  // Overhead;
  if (overhead) {
    obj.overhead = makePnlTypeData(overhead, "amount");
    obj.overhead_percentages = makePnlTypeData(overhead, "percentage");
  }

  if (overhead_d) {
    let overhead_detail_arr: ICashflowTableItem[] = [];
    overhead_d.forEach((el) => {
      overhead_detail_arr.push(makePnlDetail(el, "pink"));
    });
    obj.overhead_detail = overhead_detail_arr;
  }

  // Gross;
  if (gross) {
    obj.gross = makePnlTypeData(gross, "amount");
    obj.gross_percentages = makePnlTypeData(gross, "percentage");
  }

  // Administrative;
  if (administrative) {
    obj.administrative = makePnlTypeData(administrative, "amount");
    obj.administrative_percentages = makePnlTypeData(administrative, "percentage");
  }

  if (administrative_d) {
    let administrative_detail_arr: ICashflowTableItem[] = [];
    administrative_d.forEach((el) => {
      administrative_detail_arr.push(makePnlDetail(el, "pink"));
    });
    obj.administrative_detail = administrative_detail_arr;
  }

  // Commercial;
  if (commercial) {
    obj.commercial = makePnlTypeData(commercial, "amount");
    obj.commercial_percentages = makePnlTypeData(commercial, "percentage");
  }

  if (commercial_d) {
    let commercial_detail_arr: ICashflowTableItem[] = [];
    commercial_d.forEach((el) => {
      commercial_detail_arr.push(makePnlDetail(el, "pink"));
    });
    obj.commercial_detail = commercial_detail_arr;
  }

  // Operative;
  if (operative) {
    obj.operative = makePnlTypeData(operative, "amount");
    obj.operative_percentages = makePnlTypeData(operative, "percentage");
  }

  // Tax;
  if (tax) {
    obj.tax = makePnlTypeData(tax, "amount");
    obj.tax_percentages = makePnlTypeData(tax, "percentage");
  }

  if (tax_d) {
    let tax_detail_arr: ICashflowTableItem[] = [];
    tax_d.forEach((el) => {
      tax_detail_arr.push(makePnlDetail(el, "pink"));
    });
    obj.tax_detail = tax_detail_arr;
  }

  // Deprecation;
  if (deprecation) {
    obj.deprecation = makePnlTypeData(deprecation, "amount");
    obj.deprecation_percentages = makePnlTypeData(deprecation, "percentage");
  }

  if (deprecation_d) {
    let deprecation_detail_arr: ICashflowTableItem[] = [];
    deprecation_d.forEach((el) => {
      deprecation_detail_arr.push(makePnlDetail(el));
    });
    obj.deprecation_detail = deprecation_detail_arr;
  }

  // Soft;
  if (soft) {
    obj.soft = makePnlTypeData(soft, "amount");
    obj.soft_percentages = makePnlTypeData(soft, "percentage");
  }

  return obj;
};

const makePnlProfit = (el: IPnlData<IProfit>, type: "amount" | "cost" | "percentage") => {
  let obj: ICashflowTableItem = {
    jan: el["1"]?.[type] || 0,
    feb: el["2"]?.[type] || 0,
    mar: el["3"]?.[type] || 0,
    apr: el["4"]?.[type] || 0,
    may: el["5"]?.[type] || 0,
    june: el["6"]?.[type] || 0,
    july: el["7"]?.[type] || 0,
    aug: el["8"]?.[type] || 0,
    sep: el["9"]?.[type] || 0,
    oct: el["10"]?.[type] || 0,
    nov: el["11"]?.[type] || 0,
    dec: el["12"]?.[type] || 0,
  };
  return obj;
};

const makePnlDetail = (el: IPnlObjDetail, color?: "green" | "pink") => {
  let obj: ICashflowTableItem = {
    key: el.title + "_detail_" + el.type + (color ? "_" + color : ""),
    sources: el.title,
    jan: el.data["1"] || 0,
    feb: el.data["2"] || 0,
    mar: el.data["3"] || 0,
    apr: el.data["4"] || 0,
    may: el.data["5"] || 0,
    june: el.data["6"] || 0,
    july: el.data["7"] || 0,
    aug: el.data["8"] || 0,
    sep: el.data["9"] || 0,
    oct: el.data["10"] || 0,
    nov: el.data["11"] || 0,
    dec: el.data["12"] || 0,
  };
  return obj;
};

const makePnlTypeData = (el: IPnlData<IPnlObj>, type: "amount" | "percentage") => {
  let obj: ICashflowTableItem = {
    jan: el["1"]?.[type] || 0,
    feb: el["2"]?.[type] || 0,
    mar: el["3"]?.[type] || 0,
    apr: el["4"]?.[type] || 0,
    may: el["5"]?.[type] || 0,
    june: el["6"]?.[type] || 0,
    july: el["7"]?.[type] || 0,
    aug: el["8"]?.[type] || 0,
    sep: el["9"]?.[type] || 0,
    oct: el["10"]?.[type] || 0,
    nov: el["11"]?.[type] || 0,
    dec: el["12"]?.[type] || 0,
  };
  return obj;
};

//Pred
const makePnlPredData = (payload: IPnlRes) => {
  const { data, monthly_profits, margin, gross, operative, soft, types } = payload;
  const { administrative, commercial, overhead, tax, deprecation } = types;
  const {
    profit: profit_d,
    margin: margin_d,
    overhead: overhead_d,
    administrative: administrative_d,
    commercial: commercial_d,
    tax: tax_d,
    deprecation: deprecation_d,
  } = data;

  let obj: Partial<IPnlTable<ICashflowPredTableItem>> = {};

  //Profit
  if (profit_d) {
    let profit_detail_arr: ICashflowPredTableItem[] = [];
    let cost_detail_arr: ICashflowPredTableItem[] = [];
    profit_d.forEach((el) => {
      profit_detail_arr.push({
        key: el.title + "_profit_detail_green",
        sources: el.title,
        ...makePnlPredProfit(el.data, "amount"),
      });
      cost_detail_arr.push({
        key: el.title + "_cost_detail_pink",
        sources: el.title,
        ...makePnlPredProfit(el.data, "cost"),
      });
      cost_detail_arr.push({
        key: el.title + "_percentages_pink",
        sources: "Daromaddagi %",
        ...makePnlPredProfit(el.data, "percentage"),
      });
    });
    obj.profit_detail = profit_detail_arr;
    obj.cost_detail = cost_detail_arr;
  }

  if (monthly_profits) {
    obj.profit = makePnlPredProfit(monthly_profits, "amount");
    obj.cost = makePnlPredProfit(monthly_profits, "cost");
    obj.cost_percentages = makePnlPredProfit(monthly_profits, "percentage");
  }

  // Marganal;
  if (margin) {
    obj.margin = makePnlPredTypeData(margin, "amount");
    obj.margin_percentages = makePnlPredTypeData(margin, "percentage");
  }

  if (margin_d) {
    let margin_detail_arr: ICashflowPredTableItem[] = [];
    margin_d.forEach((el) => {
      margin_detail_arr.push({
        key: el.title + "_margin_detail_green",
        sources: el.title,
        ...makePnlPredProfit(el.data, "amount"),
      });
      margin_detail_arr.push({
        key: el.title + "_margin_detail_percentages_green",
        sources: "Marjanalnist %",
        ...makePnlPredProfit(el.data, "percentage"),
      });
    });
    obj.margin_detail = margin_detail_arr;
  }

  // Overhead;
  if (overhead) {
    obj.overhead = makePnlPredTypeData(overhead, "amount");
    obj.overhead_percentages = makePnlPredTypeData(overhead, "percentage");
  }

  if (overhead_d) {
    let overhead_detail_arr: ICashflowPredTableItem[] = [];
    overhead_d.forEach((el) => {
      overhead_detail_arr.push(makePnlPredDetail(el, "pink"));
    });
    obj.overhead_detail = overhead_detail_arr;
  }

  // Gross;
  if (gross) {
    obj.gross = makePnlPredTypeData(gross, "amount");
    obj.gross_percentages = makePnlPredTypeData(gross, "percentage");
  }

  // Administrative;
  if (administrative) {
    obj.administrative = makePnlPredTypeData(administrative, "amount");
    obj.administrative_percentages = makePnlPredTypeData(administrative, "percentage");
  }

  if (administrative_d) {
    let administrative_detail_arr: ICashflowPredTableItem[] = [];
    administrative_d.forEach((el) => {
      administrative_detail_arr.push(makePnlPredDetail(el, "pink"));
    });
    obj.administrative_detail = administrative_detail_arr;
  }

  // Commercial;
  if (commercial) {
    obj.commercial = makePnlPredTypeData(commercial, "amount");
    obj.commercial_percentages = makePnlPredTypeData(commercial, "percentage");
  }

  if (commercial_d) {
    let commercial_detail_arr: ICashflowPredTableItem[] = [];
    commercial_d.forEach((el) => {
      commercial_detail_arr.push(makePnlPredDetail(el, "pink"));
    });
    obj.commercial_detail = commercial_detail_arr;
  }

  // Operative;
  if (operative) {
    obj.operative = makePnlPredTypeData(operative, "amount");
    obj.operative_percentages = makePnlPredTypeData(operative, "percentage");
  }

  // Tax;
  if (tax) {
    obj.tax = makePnlPredTypeData(tax, "amount");
    obj.tax_percentages = makePnlPredTypeData(tax, "percentage");
  }

  if (tax_d) {
    let tax_detail_arr: ICashflowPredTableItem[] = [];
    tax_d.forEach((el) => {
      tax_detail_arr.push(makePnlPredDetail(el, "pink"));
    });
    obj.tax_detail = tax_detail_arr;
  }

  // Deprecation;
  if (deprecation) {
    obj.deprecation = makePnlPredTypeData(deprecation, "amount");
    obj.deprecation_percentages = makePnlPredTypeData(deprecation, "percentage");
  }

  if (deprecation_d) {
    let deprecation_detail_arr: ICashflowPredTableItem[] = [];
    deprecation_d.forEach((el) => {
      deprecation_detail_arr.push(makePnlPredDetail(el));
    });
    obj.deprecation_detail = deprecation_detail_arr;
  }

  // Soft;
  if (soft) {
    obj.soft = makePnlPredTypeData(soft, "amount");
    obj.soft_percentages = makePnlPredTypeData(soft, "percentage");
  }

  return obj;
};

const makePnlPredProfit = (el: IPnlData<IProfit>, type: "amount" | "cost" | "percentage") => {
  let obj: ICashflowPredTableItem = {
    jan_pred: el["1"]?.[type] || 0,
    feb_pred: el["2"]?.[type] || 0,
    mar_pred: el["3"]?.[type] || 0,
    apr_pred: el["4"]?.[type] || 0,
    may_pred: el["5"]?.[type] || 0,
    june_pred: el["6"]?.[type] || 0,
    july_pred: el["7"]?.[type] || 0,
    aug_pred: el["8"]?.[type] || 0,
    sep_pred: el["9"]?.[type] || 0,
    oct_pred: el["10"]?.[type] || 0,
    nov_pred: el["11"]?.[type] || 0,
    dec_pred: el["12"]?.[type] || 0,
  };
  return obj;
};

const makePnlPredDetail = (el: IPnlObjDetail, color?: "green" | "pink") => {
  let obj: ICashflowPredTableItem = {
    key: el.title + "_detail_" + el.type + (color ? "_" + color : ""),
    sources: el.title,
    jan_pred: el.data["1"] || 0,
    feb_pred: el.data["2"] || 0,
    mar_pred: el.data["3"] || 0,
    apr_pred: el.data["4"] || 0,
    may_pred: el.data["5"] || 0,
    june_pred: el.data["6"] || 0,
    july_pred: el.data["7"] || 0,
    aug_pred: el.data["8"] || 0,
    sep_pred: el.data["9"] || 0,
    oct_pred: el.data["10"] || 0,
    nov_pred: el.data["11"] || 0,
    dec_pred: el.data["12"] || 0,
  };
  return obj;
};

const makePnlPredTypeData = (el: IPnlData<IPnlObj>, type: "amount" | "percentage") => {
  let obj: ICashflowPredTableItem = {
    jan_pred: el["1"]?.[type] || 0,
    feb_pred: el["2"]?.[type] || 0,
    mar_pred: el["3"]?.[type] || 0,
    apr_pred: el["4"]?.[type] || 0,
    may_pred: el["5"]?.[type] || 0,
    june_pred: el["6"]?.[type] || 0,
    july_pred: el["7"]?.[type] || 0,
    aug_pred: el["8"]?.[type] || 0,
    sep_pred: el["9"]?.[type] || 0,
    oct_pred: el["10"]?.[type] || 0,
    nov_pred: el["11"]?.[type] || 0,
    dec_pred: el["12"]?.[type] || 0,
  };
  return obj;
};
export default pnlSlice.reducer;
