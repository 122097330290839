import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMonitorResData, IMonitorTable } from "../services/finance/type";
import { financeApi } from "../services/finance";

export interface IMonitorState {
  monitors?: IMonitorResData[];
}

const initialState: IMonitorState = {
  monitors: undefined,
};

const monitorSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    saveProjects: (state, action: PayloadAction<IMonitorState["monitors"]>) => {
      state.monitors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getMonitor.matchFulfilled, (state, action) => {
      let arr: IMonitorTable[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, index: index + 1, ...item });
      });
      state.monitors = arr;
    });
  },
});

export const { saveProjects } = monitorSlice.actions;

export default monitorSlice.reducer;
