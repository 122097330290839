import { ColumnsType } from "antd/es/table";
import { IPurchaseRes } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const purchaseColumns = (
  t: TFunctionType
): ColumnsType<IPurchaseRes> => [
  {
    title: t("common.date"),
    dataIndex: "date",
    key: "date",
    width: 110,
  },
  {
    title: t("common.contingent"),
    dataIndex: "contingent",
    key: "contingent",
    render: (val) => val?.first_name,
  },
  {
    title: `${t("common.all")} (${t("common.uzs")})`,
    dataIndex: "total_amount",
    key: "total_amount",
    width: 200,
    render: (el: number) => el?.toLocaleString(),
  },
  {
    title: t("common.comment"),
    dataIndex: "comment",
    key: "comment",
  },
];
