import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetSourceMutation } from "src/app/services/finance";
import { IOption } from "src/app/services/type";
import CustomSelect from "../common/select";
import { t } from "i18next";

function FilterSource() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (value: string) => {
    handleMakeParams("source_id", value);
  };

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  //Get data
  const [getSource, { data }] = useGetSourceMutation();
  const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    getSource("limit=1000");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      let arr: IOption[] = [];
      data.results.forEach((item) => {
        arr.push({ label: item.title, value: item.id.toString() });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <CustomSelect
      options={options}
      placeholder={t("common.selectSource")}
      style={{ width: 200 }}
      allowClear
      onChange={handleChange}
      defaultValue={searchParams.get("source_id")}
      value={searchParams.get("source_id")}
      height={38}
      className="filter-source"
      showSearch
      optionFilterProp="label"
      filterOption={(input, option: any) =>
        option?.label?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA: any, optionB: any) =>
        optionA.label?.toLowerCase().localeCompare(optionB.label.toLowerCase())
      }
    />
  );
}

export default FilterSource;
