import { Radio } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

function FilterIncomeOutcome() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (value: string) => {
    handleMakeParams("is_expense", value);
  };

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return (
    <Radio.Group
      defaultValue={searchParams.get("is_expense") ?? "false"}
      onChange={(ev) => handleChange(ev.target.value)}
      buttonStyle="solid"
    >
      <Radio.Button value="false">{t("common.income")}</Radio.Button>
      <Radio.Button value="true">{t("common.outcome")}</Radio.Button>
    </Radio.Group>
  );
}

export default FilterIncomeOutcome;
