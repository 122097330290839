import "./modal.scss";

interface Props {
  title: string;
  description?: string;
}

function ModalHeader({ title, description }: Props) {
  return (
    <div className="modal-header">
      <h2>{title}</h2>
      {description && <p>{description}</p>}
    </div>
  );
}

export default ModalHeader;
