import { changeTheme } from "src/app/slices/layoutSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { DarkModeSvg, LightModeSvg } from "src/assets/svg/headerIcons";
import "./darkmode.scss";

function DarkMode() {
  const dispatch = useAppDispatch();
  const { darkMode } = useTypedSelector((state) => state.layout);

  return (
    <div onClick={() => dispatch(changeTheme())} className="darkmode">
      {darkMode ? <DarkModeSvg /> : <LightModeSvg />}
    </div>
  );
}

export default DarkMode;
