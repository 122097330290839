import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetAccountBalancesStatMutation,
  useGetBudgetPnlDiagramMutation,
  useGetCashflowDashboardMutation,
  useGetDirectionMutation,
  useGetPnlMutation,
} from "src/app/services/finance";
import { handleMakeDashboardUrl } from "src/utils";
import {
  ICashflowDashboardRes,
  IPnlData,
  IPnlObj,
  IPnlObjDetail,
  IPnlProfitData,
  IPnlRes,
  IProfit,
} from "../sub_control_reports/pnl/type";
import { IOption } from "src/app/services/type";
import { useTypedSelector } from "src/app/store";

interface IData {
  income_fact?: number;
  income_plan?: number;
  outcome_fact?: number;
  outcome_plan?: number;
  income_remaining?: number;
}

interface IDataPnl {
  income_fact?: number;
  income_plan?: number;
  expence_fact?: number;
  expence_plan?: number;
  benefit_fact?: number;
  benefit_plan?: number;
}

export type DirectionAttributes = "all" | "Жорий пул оқими" | "Молиявий пул оқими" | "Инвестицион пул оқими";
export type PnlTypeAttributes = "gross" | "operative" | "soft" | "margin";
export type PnlExpenceTypeAttributes = "overhead" | "administrative" | "commercial" | "tax" | "cost";
type TypeAttributes = "fact" | "plan";
type CashflowFilterKeys = "income" | "outcome" | "remaining";
type PnlFilterKeys = "income" | "benefit" | "expence";

export default function useDashboard() {
  const [getAccounts, { data: accountsData }] = useGetAccountBalancesStatMutation();

  useEffect(() => {}, []);

  const [get] = useGetCashflowDashboardMutation();
  const [data, setData] = useState<IData>();
  const [cashflowFactData, setCashflowFactData] = useState<ICashflowDashboardRes>();
  const [cashflowPlanData, setCashflowPlanData] = useState<ICashflowDashboardRes>();

  const [getPnl, { data: pnlFactData }] = useGetPnlMutation();
  const [getBudgetPnl, { data: pnlPlanData }] = useGetBudgetPnlDiagramMutation();
  const [pnlData, setPnlData] = useState<IDataPnl>();

  const [searchParams] = useSearchParams();
  let month = searchParams.get("date")?.split("-")[1].replace("0", "");

  useEffect(() => {
    let url = handleMakeDashboardUrl(searchParams);
    getAccounts(url);
    get("source=cashflow" + url)
      .unwrap()
      .then((res) => {
        let obj = makeCashflowData(res, "all", "fact");
        setCashflowFactData(res);
        get("source=predict-cashflow" + url)
          .unwrap()
          .then((res2) => {
            let obj2 = makeCashflowData(res2, "all", "plan");
            setData({ ...obj, ...obj2 });
            setCashflowPlanData(res2);
          });
      });

    let pnl_url = "date_range=month" + handleMakeDashboardUrl(searchParams, "date_range");
    getPnl(pnl_url)
      .unwrap()
      .then((res) => {
        let obj = makePnlData(res, "soft", "fact");
        getBudgetPnl(url)
          .unwrap()
          .then((res2) => {
            let obj2 = makePnlData(res2, "soft", "plan");
            setPnlData({ ...obj, ...obj2 });
          });
      });
  }, [searchParams]);

  const onCashflowFilter = (direction: DirectionAttributes, filter_key: CashflowFilterKeys) => {
    if (cashflowFactData && cashflowPlanData && data) {
      let obj = makeCashflowData(cashflowFactData, direction, "fact", filter_key, data);
      let obj2 = makeCashflowData(cashflowPlanData, direction, "plan", filter_key, obj);
      setData({ ...obj2 });
    }
  };

  const makeCashflowData = (
    res: ICashflowDashboardRes,
    direction: DirectionAttributes,
    type: TypeAttributes,
    filter_key?: CashflowFilterKeys,
    def_obj?: IData
  ) => {
    const { cashflow } = res;
    let income = 0;
    let outcome = 0;
    let remain = 0;
    cashflow.forEach((item) => {
      const { income_sources, outcome_sources, remaining, title } = item;
      if (direction && direction !== "all" && title !== direction) return;
      income += findAllDataCount(income_sources);
      outcome += findAllDataCount(outcome_sources);
      remain += findDataCount(remaining);
    });

    let obj: IData = def_obj || {};
    if (filter_key === "income") obj[`income_${type}`] = income;
    else if (filter_key === "outcome") obj[`outcome_${type}`] = outcome;
    else if (filter_key === "remaining") {
      if (type === "fact") {
        obj[`income_remaining`] = remain;
      }
    } else {
      obj[`income_${type}`] = income;
      obj[`outcome_${type}`] = outcome;
      if (type === "fact") {
        obj[`income_remaining`] = remain;
      }
    }
    return obj;
  };

  const onPnlFilter = (pnl_type: PnlTypeAttributes | PnlExpenceTypeAttributes, filter_key: PnlFilterKeys) => {
    if (pnlFactData && pnlPlanData && pnlData) {
      let obj = makePnlData(pnlFactData, pnl_type, "fact", filter_key, pnlData);
      let obj2 = makePnlData(pnlPlanData, pnl_type, "plan", filter_key, obj);
      setPnlData({ ...obj2 });
    }
  };

  const makePnlData = (
    res: IPnlRes,
    pnl_type: PnlTypeAttributes | PnlExpenceTypeAttributes,
    type: TypeAttributes,
    filter_key?: PnlFilterKeys,
    def_obj?: IData
  ) => {
    const { monthly_profits, gross, operative, soft, margin, data } = res;
    let benefit = 0;
    let expence = 0;

    if (filter_key !== "expence") {
      if (pnl_type === "gross") benefit = findPnlDataCount(gross);
      else if (pnl_type === "operative") benefit = findPnlDataCount(operative);
      else if (pnl_type === "margin") benefit = findPnlDataCount(margin);
      else benefit = findPnlDataCount(soft);
    }

    if (filter_key !== "benefit") {
      let overhead = findAllDataCount(data.overhead);
      let administrative = findAllDataCount(data.administrative);
      let commercial = findAllDataCount(data.commercial);
      let tax = findAllDataCount(data.tax);
      let cost = findAllPnlDataCount(data.profit, true);
      if (pnl_type === "overhead") expence = overhead;
      else if (pnl_type === "administrative") expence = administrative;
      else if (pnl_type === "commercial") expence = commercial;
      else if (pnl_type === "tax") expence = tax;
      else if (pnl_type === "cost") expence = cost;
      else expence = overhead + administrative + commercial + tax + cost;
    }

    let obj: IDataPnl = def_obj || {};
    if (filter_key === "benefit") obj[`benefit_${type}`] = benefit;
    else if (filter_key === "expence") obj[`expence_${type}`] = expence;
    else {
      obj[`income_${type}`] = findPnlDataCount(monthly_profits);
      obj[`benefit_${type}`] = benefit;
      obj[`expence_${type}`] = expence;
    }

    return obj;
  };

  const findAllDataCount = (arr: IPnlObjDetail[]) => {
    let total = 0;
    arr.forEach((item) => {
      total += findDataCount(item.data);
    });
    return total;
  };

  const findDataCount = (obj: IPnlData<number>) => {
    let total = 0;
    for (const [key, value] of Object.entries(obj)) {
      if (key === month) total += value;
    }
    return total;
  };

  const findAllPnlDataCount = (arr: IPnlProfitData[], isCost?: boolean) => {
    let total = 0;
    arr.forEach((item) => {
      total += findPnlDataCount(item.data, isCost);
      // if (isCost) console.log("findPnlDataCount(item.data, isCost)", findPnlDataCount(item.data, isCost));
    });
    return total;
  };

  const findPnlDataCount = (obj: IPnlData<IPnlObj>, isCost?: boolean) => {
    let total = 0;
    for (const [key, value] of Object.entries(obj)) {
      let val: any = value;
      if (key === month) total += isCost ? val.cost : val.amount;
    }
    return total;
  };

  // //Directions
  // const [getDirection, { data }] = useGetDirectionMutation();
  // const [directionOptions, setDirectionOptions] = useState<IOption[]>([]);
  // const { directions } = useTypedSelector((state) => state.direction);

  // useEffect(() => {
  //   !directions && getDirection("limit=1000");
  // }, []);

  // useEffect(() => {
  //   if (data || directions) {
  //     let arr: IOption[] = [];
  //     (data?.results || directions)?.forEach((item) => {
  //       arr.push({ label: item.title, value: item.id.toString() });
  //     });
  //     setDirectionOptions(arr);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, directions]);

  return {
    data,
    pnlData,
    accountsData,
    onCashflowFilter,
    onPnlFilter,
  };
}
