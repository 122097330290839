import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { ISourceResData, ISourceTable } from "../services/finance/type";

export interface IAccountState {
  sources?: ISourceResData[];
  sourcesAll?: ISourceResData[];
}

const initialState: IAccountState = {
  sources: undefined,
};

const sourceSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getSource.matchFulfilled, (state, action) => {
      let arr: ISourceTable[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, index: index + 1, ...item });
      });
      state.sources = arr;
    });
    builder.addMatcher(financeApi.endpoints.getSourceAll.matchFulfilled, (state, action) => {
      state.sourcesAll = action.payload.results;
    });
  },
});

export default sourceSlice.reducer;
