export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    className="clock-icon"
    width={14}
    height={14}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const CreditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="credit-icon icon"
    width={24}
    height={24}
  >
    <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
    <path
      fillRule="evenodd"
      d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
      clipRule="evenodd"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="arrow-down icon"
  >
    <path
      fillRule="evenodd"
      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-.53 14.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V8.25a.75.75 0 00-1.5 0v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
      clipRule="evenodd"
    />
  </svg>
);

export const DollorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    className="dollor-icon icon"
    fill="currentColor"
    width={24}
    height={24}
  >
    <g data-name="Layer 2">
      <path d="M18.5 17H17v3h1.5a1.5 1.5 0 000-3zM12 13.5A1.5 1.5 0 0013.5 15H15V12H13.5A1.5 1.5 0 0012 13.5z"></path>
      <path d="M16,2A14,14,0,1,0,30,16,14.015,14.015,0,0,0,16,2Zm2.5,20H17v2a1,1,0,0,1-2,0V22H12a1,1,0,0,1,0-2h3V17H13.5a3.5,3.5,0,0,1,0-7H15V8a1,1,0,0,1,2,0v2h3a1,1,0,0,1,0,2H17v3h1.5a3.5,3.5,0,0,1,0,7Z"></path>
    </g>
  </svg>
);

export const ChartIncomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="chart-income-icon"
  >
    <path d="M15.167 18.8668V4.66665H12.8337V18.8668L6.57569 12.6088L4.92578 14.2587L14.0004 23.3333L23.0748 14.2587L21.4249 12.6088L15.167 18.8668Z" />
  </svg>
);

export const ChartOutcomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="chart-outcome-icon"
  >
    <path d="M15.167 9.13319V23.3334H12.8337V9.13319L6.57569 15.3912L4.92578 13.7413L14.0004 4.66669L23.0748 13.7413L21.4249 15.3912L15.167 9.13319Z" />
  </svg>
);
export const ChartCurrencyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="26px"
    height="26px"
    fill="#fff"
  >
    <path d="M 24 4 C 12.972066 4 4 12.972074 4 24 C 4 35.027926 12.972066 44 24 44 C 35.027934 44 44 35.027926 44 24 C 44 12.972074 35.027934 4 24 4 z M 24 7 C 33.406615 7 41 14.593391 41 24 C 41 33.406609 33.406615 41 24 41 C 14.593385 41 7 33.406609 7 24 C 7 14.593391 14.593385 7 24 7 z M 23.476562 10.978516 A 1.50015 1.50015 0 0 0 22 12.5 L 22 13.652344 C 19.207791 14.038344 17 16.357587 17 19.25 C 17 22.407822 19.592178 25 22.75 25 L 24.75 25 C 26.562096 25 28 26.437904 28 28.25 C 28 30.062096 26.562096 31.5 24.75 31.5 L 22.5 31.5 C 21.322258 31.5 20.358942 30.70345 20.080078 29.632812 A 1.50015 1.50015 0 1 0 17.177734 30.388672 C 17.752415 32.595033 19.686351 34.197787 22 34.416016 L 22 35.5 A 1.50015 1.50015 0 1 0 25 35.5 L 25 34.449219 C 28.313006 34.309282 31 31.595439 31 28.25 C 31 24.816096 28.183904 22 24.75 22 L 22.75 22 C 21.213822 22 20 20.786178 20 19.25 C 20 17.713822 21.213822 16.5 22.75 16.5 L 23.253906 16.5 A 1.50015 1.50015 0 0 0 23.740234 16.5 L 24.5 16.5 C 25.669144 16.5 26.62787 17.286788 26.914062 18.345703 A 1.5002527 1.5002527 0 1 0 29.810547 17.5625 C 29.220588 15.379651 27.296986 13.800333 25 13.583984 L 25 12.5 A 1.50015 1.50015 0 0 0 23.476562 10.978516 z" />
  </svg>
);

export const ChartSavingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="savings-icon"
    viewBox="0 0 512 512"
    width="24"
    height="24"
  >
    <rect
      x="48"
      y="144"
      width="416"
      height="288"
      rx="48"
      ry="48"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path
      d="M411.36 144v-30A50 50 0 00352 64.9L88.64 109.85A50 50 0 0048 159v49"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <path d="M368 320a32 32 0 1132-32 32 32 0 01-32 32z" />
  </svg>
);

export const ChartIncreaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="upper-right-arrow"
    className="increase-icon icon"
    width="14"
    height="14"
  >
    <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z"></path>
  </svg>
);

export const ChartDecreaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="lower-right-arrow"
    className="decrease-icon icon"
    width="14"
    height="14"
  >
    <path d="M17,6a1,1,0,0,0-1,1v7.59L7.71,6.29A1,1,0,0,0,6.29,7.71L14.59,16H7a1,1,0,0,0,0,2H17a1,1,0,0,0,.38-.08,1,1,0,0,0,.54-.54A1,1,0,0,0,18,17V7A1,1,0,0,0,17,6Z"></path>
  </svg>
);
