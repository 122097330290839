import { Form, Input, message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddCurrencyMutation,
  useEditCurrencyMutation,
} from "src/app/services/finance";
import { ICurrency, IFormCurrency } from "src/app/services/finance/type";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import CurrencyFormItem from "src/components/formItem/CurrencyFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";

interface Props {
  handleGet: () => void;
  editableData?: ICurrency;
  setEditableData: React.Dispatch<React.SetStateAction<ICurrency | undefined>>;
}

function AddCurrency({ handleGet, editableData, setEditableData }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addCurrency, { isSuccess: addStatus }] = useAddCurrencyMutation();
  const [editCurrency, { isSuccess: editStatus }] = useEditCurrencyMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormCurrency) => {
    let obj = {
      exchange_rate: val.exchange_rate,
      date: val.date?.format("YYYY-MM-DD") || "",
    };

    if (editableData) editCurrency({ id: editableData.id, body: obj });
    else addCurrency(obj);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Yo'nalish muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(
        `${editableData?.date} sana valyutasi muvaffaqiyatli tahrirlandi!`
      );
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { currency, exchange_rate, date } = editableData;

      form.setFieldsValue({
        currency,
        exchange_rate,
        date: moment(date),
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      {/* <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> Qo'shish
        </div>
      </CustomButton> */}
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${editableData ? "Edit the" : "Add a"} Currency`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <DateFormItem className="half" disabled />
          <div className="half" />

          <CurrencyFormItem disabled required className="half" />

          <Form.Item
            name="exchange_rate"
            label={t("common.currencyRate")}
            className="half"
          >
            <Input type="number" />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddCurrency;
