import { Form, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddStorageMutation } from "src/app/services/management";
import { IStorage, IStorageForm, IStorageRes } from "src/app/services/management/type";
import { IBaseAddProps } from "src/app/services/type";
import { monthFormat } from "src/components/filter/FilterDate";

export interface IAddProps extends IBaseAddProps<IStorageRes> {}

export default function useAdd({ handleGet }: IAddProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  //Form section
  const [add, { isLoading }] = useAddStorageMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IStorageForm) => {
    let { date, ...rest } = val;
    if (!date) return;
    let obj: IStorage = {
      ...rest,
      date: date?.format(monthFormat) + "-01" || "",
    };
    add(obj)
      .unwrap()
      .then(() => {
        message.success(t("common.successMessage"));
        closeModal();
        handleGet();
      });
  };

  const typeOptions = [
    {
      label: t("subHelper.storageRowMaterials"),
      value: "raw_material",
    },
    {
      label: t("subHelper.storageSemiFinished"),
      value: "semi_finished_product",
    },
    {
      label: t("subHelper.storageFinished"),
      value: "finished_product",
    },
  ];

  return { isModalOpen, form, typeOptions, isLoading, showModal, closeModal, onFinish };
}
