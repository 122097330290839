import { EditOutlined } from "@ant-design/icons";
import { Image, Popconfirm, message } from "antd";
import { useEffect } from "react";
import {
  useDeleteTeamMutation,
  useGetTeamMMutation,
} from "src/app/services/management";
import { IProfile } from "src/app/services/users/type";
import { DeleteIcon, UserIcon } from "src/assets/svg";
import { PhoneSVG } from "src/assets/svg/homeIcons";
import CustomButton from "src/components/common/button";
import { positionOptions } from "src/static/constants";
import "./cards.scss";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { position } from "src/app/services/users/const";
import { PositionAttributes } from "src/app/services/type";
import AddRemaining from "../../contingent/remaining";
import { updateTeams } from "src/app/slices/contingentSlice";

interface Props extends Omit<IProfile, "position"> {
  onEdit: (val: number) => void;
  memberPostion: PositionAttributes;
}

function TeamCard({
  onEdit,
  id,
  first_name,
  last_name,
  telegram,
  telegram_id,
  phone,
  avatar,
  memberPostion,
}: Props) {
  const dispatch = useAppDispatch();
  const [getTeam] = useGetTeamMMutation();
  const [deleteTeam, { isSuccess }] = useDeleteTeamMutation();
  const handleConfirm = () => {
    deleteTeam(id)
      .unwrap()
      .then(() => {
        getTeam("limit=1000")
          .unwrap()
          .then((val) => {
            dispatch(updateTeams(val.results));
          });
      });
  };

  useEffect(() => {
    isSuccess && message.success("Member deleted succesfully!");
  }, [isSuccess]);

  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;

  return (
    <div className="team-card">
      <div className="team-card-user">
        {avatar ? (
          <Image width={48} src={avatar} />
        ) : (
          <div className="team-card-user-icon">
            <UserIcon />
          </div>
        )}
        <div>
          <h4>{first_name + " " + last_name}</h4>
          <p>
            {positionOptions.find((el) => el.value === memberPostion)?.label}
          </p>
        </div>
      </div>

      {/* {telegram && (
        <a href={`https://t.me/${telegram}`} target="_blank" rel="noreferrer" className="team-card-telegram">
          <TelegramSvg /> {telegram}
        </a>
      )} */}

      <AddRemaining
        id={id}
        title={first_name + " " + last_name}
        type="employee"
      />
      {phone && (
        <a href={`tel:+998${phone}`} className="team-card-phone">
          <PhoneSVG /> +998{phone}
        </a>
      )}

      {/* <Badge.Ribbon
        placement="start"
        text={telegram_id ? "Ulangan" : "Ulanmagan"}
        color={telegram_id ? "cyan" : "pink"}
      >
        <p className="team-card-bot">
          <RobotFilled style={{ color: "#4b38b3" }} /> Telegram bot
        </p>
      </Badge.Ribbon> */}

      <div className="team-card-detail">
        <CustomButton
          onClick={() => onEdit(id)}
          className="team-card-detail-btn edit"
          height={38}
          paddingContentHorizontal={14}
        >
          <EditOutlined />
        </CustomButton>
        {userPostion !== "accountant" && (
          <Popconfirm
            title="Delete the member."
            description="Are you sure to delete this member?"
            onConfirm={handleConfirm}
            okText="Yes"
            cancelText="No"
            style={{ fontSize: 16 }}
          >
            <CustomButton
              className="team-card-detail-btn delete"
              paddingContentHorizontal={16}
              height={38}
            >
              <DeleteIcon />
            </CustomButton>
          </Popconfirm>
        )}
      </div>
    </div>
  );
}

export default TeamCard;
