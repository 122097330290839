import { message } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useConfirmJournalMutation,
  useDeleteJournalMutation,
  useGetJournalMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterDate from "src/components/filter/FilterDate";
import FilterAccount from "src/components/filter/FilterAccount";
import PaginationFilter from "src/components/filter/PaginationFilter";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import UnConfirmedCard from "./components/UnConfirmedCard";
import "./unConfirmed.scss";
import { useTranslation } from "react-i18next";

function UnConfirmed() {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getSource, { data }] = useGetJournalMutation();
  const { journals } = useTypedSelector((state) => state.journal);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams, { status: "cancelled" });
    getSource(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Confirm section
  const [confirmJournal, { isSuccess: confirmStatus }] =
    useConfirmJournalMutation();
  const onConfirm = (val: number) => {
    confirmJournal(val);
  };
  useEffect(() => {
    if (confirmStatus) {
      handleGet();
      message.success("Successfully confirmed!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmStatus]);

  //Confirm section
  const [deleteJournal, { isSuccess: deleteStatus }] =
    useDeleteJournalMutation();
  const onDelete = (val: number) => {
    deleteJournal(val);
  };
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Successfully deleted!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <div className="unConfirmed">
      <ContentTop>
        <FilterAccount />
        <FilterDate />
      </ContentTop>

      <h2>{t("subCheck.uncorfirmedAcc")}</h2>
      <div className="unConfirmed-cards">
        {journals?.map((el) => (
          <UnConfirmedCard
            key={el.id}
            {...el}
            action={true}
            onConfirm={onConfirm}
            onDelete={onDelete}
          />
        ))}
      </div>
      {data && <PaginationFilter total={data?.count} />}
    </div>
  );
}

export default UnConfirmed;
