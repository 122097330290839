export const themeColors = {
  light: {
    primary: "#6c5ffc",
    color_content_primary: "#4b38b3",
    color_content_tertiary: "#878a99",
    color_content_quaternary: "#f3f6f9",

    color_sidebar: "#fff",
    color_input: "#fff",
    color_input_hover: "#b7afe1",
    color_border: "#e0e4ea",
    color_switch: "#fff",
    color_scroll: "#edeff0",
    color_placeholder: "#282f53",

    color_white: "#fff",
    color_green: "#45cb85",
    color_black: "#495057",
    color_red: "#F44E43",

    color_light_green: "#e3f7ed",
    light_gray: "#E5E5E5",

    color_green_chart: "#3dc54a",

    gray_50: "#F9FAFB",
    gray_300: "#D1D5DB",
    gray_900: "#111928",

    bar_chart: {
      red: "#f06548",
      yellow: "#ffbe05",
      green: "#3dc54a",
      blue: "#3577f1",
      ink: "#4b38b3",
    },
    pie_chart: {
      dark_green: "#3BA272",
      blue: "#5470C6",
      sky_blue: "#73C0DE",
      red: "#EE6666",
      yellow: "#FAC858",
      light_green: "#91CC75",
    },
    chart_colors: ["#5470C6", "#91CC75", "#FAC858", "#ee6666", "#73C0DE", "#3dc54a", "#3577f1", "#4b38b3"],
  },
  dark: {
    primary: "#6c5ffc",
    color_content_primary: "#4b38b3",
    color_content_tertiary: "#878a99",
    color_content_quaternary: "#2F343A",

    color_sidebar: "#02121e",
    color_input: "#262A2F",
    color_input_hover: "#312474",
    color_border: "#ffffff1a",
    color_switch: "#4b38b3a3",
    color_scroll: "#808293",
    color_placeholder: "#dedefd",

    color_white: "#02121e",
    // color_white: "#2a2a4a",
    color_green: "#35a069",
    color_black: "#CED4DA",
    color_red: "#d85b41",

    color_light_green: "#263D37",
    light_gray: "#E5E5E5",

    color_green_chart: "#3dc54a",

    gray_50: "#F9FAFB",
    gray_300: "#D1D5DB",
    gray_900: "#111928",

    bar_chart: {
      red: "#f06548",
      yellow: "#ffbe05",
      green: "#3dc54a",
      blue: "#3577f1",
      ink: "#4b38b3",
    },
    pie_chart: {
      dark_green: "#3BA272",
      blue: "#5470C6",
      sky_blue: "#73C0DE",
      red: "#EE6666",
      yellow: "#FAC858",
      light_green: "#91CC75",
    },
    chart_colors: ["#5470C6", "#91CC75", "#FAC858", "#ee6666", "#73C0DE", "#3dc54a", "#3577f1", "#4b38b3"],
  },
};

export const cssThemeColors = {
  light: {
    color_white: "#fff",
    color_black: "#495057",
    color_primary: "#6c5ffc",

    bgd_color_primary: "#f0f0f5",
    color_header_border: "#e9edf4",
    bg_color_header: "#fff",
    color_sidebar: "#fff",
    bg_info: "#dff0fa",
    color_input_border: "#D1D5DB",
    color_scroll: "#edeff0",
    color_scroll_dash: "#bababa",
    color_menu_icon: "#6c5ffc",
    color_gray_bg: "#f3f6f9",

    color_content_primary: "#4b38b3",
    color_content_tertiary: "#878a99",
    color_content_quaternary: "#f3f6f9",

    color_green: "#45cb85",
    color_yellow: "#ffbe0b",
    color_red: "#f06548",
    color_blue: "#299cdb",

    color_light_green: "#e3f7ed",
    color_light_yellow: "#fff5da",
    color_light_red: "#fde8e4",
    color_light_blue: "#e9f0fb",
    color_table_blue: "#DEEDFA",

    color_green_hover: "#3ab375",
    color_grey_hover: "#ced4da",

    color_pnl_header: "#D6E5F6",
    color_pnl_green: "#e3f7ed",
    color_pnl_green_hover: "#33c481",
    color_pnl_pink: "#fde8e4",
    color_pnl_pink_hover: "#d5a6a9",
  },
  dark: {
    // color_white: "#2a2a4a",
    color_white: "#02121e",
    color_black: "#CED4DA",
    color_primary: "#6c5ffc",

    bgd_color_primary: "#010E18",
    bg_color_header: "#262A2F",
    color_header_border: "#ffffff1a",
    color_sidebar: "#02121e",
    bg_info: "#1B2934",
    color_input_border: "#2A2F34",
    color_scroll: "#808293",
    color_scroll_dash: "#808293",
    color_menu_icon: "#dedefd",
    color_gray_bg: "#1B2934",

    color_content_primary: "#4b38b3",
    color_content_tertiary: "#878a99",
    color_content_quaternary: "#2F343A",

    color_green: "#35a069",
    color_yellow: "#ffbe0b",
    color_red: "#d85b41",
    color_blue: "#299cdb",

    color_light_green: "#263D37",
    color_light_yellow: "#423B25",
    color_light_red: "#402E2E",
    color_light_blue: "#e9f0fb",
    color_table_blue: "#262A2F",

    color_green_hover: "#49ac79",
    color_grey_hover: "#535659",

    color_pnl_header: "#344559",
    color_pnl_green: "#11a944",
    color_pnl_green_hover: "#0d8134",
    color_pnl_pink: "#a47579",
    color_pnl_pink_hover: "#7b5154",
  },
};
