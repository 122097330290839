import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useState } from "react";
import { position } from "src/app/services/users/const";
import { useTypedSelector } from "src/app/store";
import CustomButton from "../common/button";
import "./action.scss";

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  deleteText?: string;
  deleteVisible?: boolean;
}

function TableActions({ onEdit, onDelete, deleteText, deleteVisible = true }: Props) {
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    onDelete?.();
    setOpen(false);
  };

  //Role configurations
  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;

  return (
    <>
      <div className="action">
        <CustomButton height={24} paddingContentHorizontal={8} className="action-btn-yellow" onClick={onEdit}>
          <EditOutlined />
        </CustomButton>

        {userPostion !== "accountant" && deleteVisible && (
          <CustomButton
            height={24}
            paddingContentHorizontal={8}
            className="action-btn-red"
            onClick={() => setOpen(true)}
          >
            <DeleteOutlined />
          </CustomButton>
        )}
      </div>

      <Popconfirm
        title={`Delete the ${deleteText || "item"}`}
        description={`Are you sure to delete this ${deleteText || "item"}?`}
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
        okText="Yes"
        cancelText="No"
        open={open}
      />
    </>
  );
}

export default TableActions;
