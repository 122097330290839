import { useState } from "react";
import { CloseIcon, MenuIcon } from "src/assets/svg/homeIcons";
import SelectLang from "src/layout/header/components/selectLangs";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "src/app/store";
import "./header.scss";
import { useTranslation } from "react-i18next";

const menu = [
  {
    name: "home.menu.menu1",
    link: "#explain",
  },
  {
    name: "home.menu.menu2",
    link: "#about",
  },
  {
    name: "home.menu.menu3",
    link: "#partners",
  },
  {
    name: "home.menu.menu4",
    link: "#price",
  },
  {
    name: "home.menu.menu5",
    link: "#contact",
  },
];

function Header() {
  const [openNav, setOpenNav] = useState(false);
  const { isAuthenticated } = useTypedSelector((state) => state.users);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="home-header">
      <div className="home-header-wrap container">
        <div className="home-header-logo">
          <img src={require("src/assets/img/logo.png")} alt="logo" />
        </div>
        <div className={`home-header-menu ${openNav ? "home-header-menu-active" : ""}`}>
          {menu.map((el) => (
            <div
              className="home-header-menu-items"
              key={el.link}
              onClick={() => {
                setOpenNav(false);
                document.body.style.overflowY = "auto";
              }}
            >
              <a href={el.link}>{t(el.name)}</a>
            </div>
          ))}
        </div>
        <div className="home-header-buttons">
          <div className={`hidden ${openNav ? "shown" : ""}`}>
            <SelectLang visible={openNav} />
          </div>
          <div className="signIn-buttons">
            <button
              className="button-outlined"
              onClick={() => (isAuthenticated ? navigate("/dashboard") : navigate("/auth/signin"))}
            >
              Kirish
            </button>
            {/* <button
              className="button-filled"
              onClick={() =>
                isAuthenticated
                  ? navigate("/dashboard")
                  : navigate("/auth/signup")
              }
            >
              Sign Up
            </button> */}
          </div>
          <button
            onClick={() => {
              setOpenNav(!openNav);
              !openNav ? (document.body.style.overflowY = "hidden") : (document.body.style.overflowY = "auto");
            }}
            className="home-header-nav"
          >
            {openNav ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
