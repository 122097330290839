import { Form, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string;
}

const { RangePicker } = DatePicker;

function IntervalDateFormItem({
  className,
  disabled,
  required = true,
  name = "date",
  label = "Date",
}: Props) {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={name}
      label={`${label}${required ? " *" : ""}`}
      rules={
        required ? [{ required: true, message: t("common.selectDate") }] : []
      }
      className={className || "full"}
    >
      <RangePicker
        placeholder={["____-__-__", "____-__-__"]}
        disabled={disabled}
      />
    </Form.Item>
  );
}

export default IntervalDateFormItem;
