import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { ICurrency } from "../services/finance/type";

export interface ICurrencyState {
  currencies?: ICurrency[];
}

const initialState: ICurrencyState = {
  currencies: undefined,
};

const currencySlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    saveProjects: (state, action: PayloadAction<ICurrencyState["currencies"]>) => {
      state.currencies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getCurrency.matchFulfilled, (state, action) => {
      let arr: ICurrency[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, ...item });
      });
      state.currencies = arr;
    });
  },
});

export const { saveProjects } = currencySlice.actions;

export default currencySlice.reducer;
