import { Form, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAddPurchaseMutation, useEditPurchaseMutation } from "src/app/services/finance";
import { IFormPurchase, IPurchase, IPurchaseRes } from "src/app/services/finance/type";
import { IBaseAddProps } from "src/app/services/type";
import { dateFormat } from "src/components/filter/FilterDate";

export interface IAddProps extends IBaseAddProps<IPurchaseRes> {}

export default function useAdd({ handleGet, editableData, setEditableData }: IAddProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData?.(undefined);
    form.resetFields();
  };

  //Form section
  const [addPurchase, { isSuccess: addStatus }] = useAddPurchaseMutation();
  const [editPurchase, { isSuccess: editStatus }] = useEditPurchaseMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormPurchase) => {
    let { date, ...rest } = val;
    let obj: IPurchase = {
      ...rest,
      date: date?.format(dateFormat) || null,
    };

    if (editableData) editPurchase({ id: editableData.id, body: obj });
    else addPurchase(obj);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Xarid muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`Xarid muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { date, contingent, ...rest } = editableData;

      form.setFieldsValue({
        date: dayjs(date, dateFormat),
        contingent: String(contingent.id),
        ...rest,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);
  return { isModalOpen, form, showModal, closeModal, onFinish };
}
