import { Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteCurrencyMutation,
  useGetCurrencyMutation,
} from "src/app/services/finance";
import { ICurrency } from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import { currencyColumns } from "./constants";
import AddCurrency from "./components/AddCurrency";
import { useTranslation } from "react-i18next";

function CurrencyPage() {
  const { t } = useTranslation();
  //Get section
  const [searchParams] = useSearchParams();
  const [getCurrency, { data: currenciesData, isLoading }] =
    useGetCurrencyMutation();
  const { currencies } = useTypedSelector((state) => state.currency);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getCurrency(url);
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //Delete section
  const [deleteCurrency, { isSuccess: deleteStatus }] =
    useDeleteCurrencyMutation();
  useEffect(() => {
    if (deleteStatus) {
      handleGet();
      message.success("Item deleted successfully!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  //Edit section
  const [editableData, setEditableData] = useState<ICurrency>();

  return (
    <div>
      <ContentTop>
        <AddCurrency
          handleGet={handleGet}
          editableData={editableData}
          setEditableData={setEditableData}
        />
      </ContentTop>

      <Table
        columns={currencyColumns(t)}
        dataSource={currencies}
        scroll={{ x: 800 }}
        pagination={false}
        loading={isLoading}
      />
      {currenciesData && <PaginationFilter total={currenciesData?.count} />}
    </div>
  );
}

export default CurrencyPage;
