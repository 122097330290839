import { Form, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  useAddDebtCreditMutation,
  useEditDebtCreditMutation,
  useGetDebtCreditMutation,
} from "src/app/services/finance";
import { IDebtCreditObj, IRemaining, IRemainingForm } from "src/app/services/finance/type";
import { DebtCreditAttributes } from "src/app/services/type";
import { monthFormat } from "src/components/filter/FilterDate";

export interface IAddProps {
  id: number;
  title: string;
  type?: DebtCreditAttributes;
}

export default function useAddRemaining({ id, type }: IAddProps) {
  const dataType = type === "employee" ? "employee" : "contingent";
  // Modal section
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  // Get def value
  const [editData, setEditData] = useState<IDebtCreditObj | null>();
  const [get] = useGetDebtCreditMutation();
  const handleGet = () => {
    if (!type) return;
    get(`?${dataType}=${id}`)
      .unwrap()
      .then((response) => {
        const res = response as IDebtCreditObj;
        if (res.date) {
          setEditData(res);
          form.setFieldsValue({ date: dayjs(res.date, monthFormat), remaining: res.remaining });
        }
      });
  };

  useEffect(() => {
    if (isModalOpen) handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isModalOpen]);

  //Form section
  const [form] = Form.useForm();
  const [add] = useAddDebtCreditMutation();
  const [edit] = useEditDebtCreditMutation();
  const onFinish = (val: IRemainingForm) => {
    if (!type) return;
    let { date, remaining } = val;
    let obj: IRemaining = {
      [dataType]: id,
      date: date?.format(monthFormat) + "-15",
      remaining,
      type,
    };

    if (editData)
      edit({ id: editData.id, body: obj })
        .unwrap()
        .then(() => {
          message.success("Boshlang`ich qoldiq muvaffaqiyatli tahrirlandi!");
          onSuccess();
        });
    else
      add(obj)
        .unwrap()
        .then(() => {
          message.success("Boshlang`ich qoldiq muvaffaqiyatli qo'shildi!");
          onSuccess();
        });
  };

  const onSuccess = () => {
    closeModal();
    setEditData(null);
  };

  return { isModalOpen, form, showModal, closeModal, onFinish };
}
