import { ConfigProvider, Modal } from "antd";
import { CustomModalProps } from "./type";

import "./modal.scss"; 
import { useTypedSelector } from "src/app/store";

function CustomModal({ paddingMD, padding, type, children, ...rest }: CustomModalProps) {
  const { colors } = useTypedSelector((state) => state.layout);
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            padding: padding || 50,
            paddingMD: paddingMD || 0,
            borderRadiusLG: type === "add" ? 24 : 4,
            colorTextBase: colors.color_white,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal {...rest} footer={null}>
        {children}
      </Modal>
    </ConfigProvider>
  );
}

export default CustomModal;
