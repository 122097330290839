import { Table } from "antd";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetCashflowMutation,
  useGetCashflowPredictedMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import FilterAccount from "src/components/filter/FilterAccount";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl } from "src/utils";
import "./cashFlow.scss";
import { cashFlowPredictedColumns, makePredColumn } from "./constants";
import { ICashflowPredColumns } from "./type";
import { tableHeight } from "src/static/constants";
import { useTranslation } from "react-i18next";

function CashFlowPredictedPage() {
  const { t } = useTranslation();
  const [getCashflowPredicted, { isLoading: preditedLoading }] =
    useGetCashflowPredictedMutation();
  const [getCashflow, { isLoading }] = useGetCashflowMutation();

  const [searchParams] = useSearchParams();
  const { screenMode } = useTypedSelector((state) => state.layout);

  useEffect(() => {
    let url = handleMakeUrl(searchParams);
    getCashflowPredicted(url);
    getCashflow(url);
  }, [searchParams]);

  const { cashflows, cashflowsPred } = useTypedSelector((state) => state.stat);

  const data: ICashflowPredColumns[] | undefined = cashflows &&
    cashflowsPred && [
      {
        key: "remains_first",
        sources: t("subCash.startMonthBalance"),
        ...cashflows?.beginnig_balance,
        ...cashflowsPred?.beginnig_balance,
      },

      {
        key: "type1_title",
        sources: t("subCash.currentBalance"),
        ...cashflows?.type1_change,
        ...cashflowsPred?.type1_change,
      },
      {
        key: "type1_income",
        sources: t("common.income"),
        ...cashflows?.type1_income_total,
        ...cashflowsPred?.type1_income_total,
        children: makePredColumn(
          cashflows.type1_income,
          cashflowsPred.type1_income
        ),
      },
      {
        key: "type1_outcome",
        sources: t("common.outcome"),
        ...cashflows?.type1_outcome_total,
        ...cashflowsPred?.type1_outcome_total,
        children: makePredColumn(
          cashflows.type1_outcome,
          cashflowsPred.type1_outcome
        ),
      },
      {
        key: "type2_title",
        sources: t("subCash.financialBalance"),
        ...cashflows?.type2_change,
        ...cashflowsPred?.type2_change,
      },
      {
        key: "type2_income",
        sources: t("common.income"),
        ...cashflows?.type2_income_total,
        ...cashflowsPred?.type2_income_total,
        children: makePredColumn(
          cashflows.type2_income,
          cashflowsPred.type2_income
        ),
      },
      {
        key: "type2_outcome",
        sources: t("common.outcome"),
        ...cashflows?.type2_outcome_total,
        ...cashflowsPred?.type2_outcome_total,
        children: makePredColumn(
          cashflows.type2_outcome,
          cashflowsPred.type2_outcome
        ),
      },
      {
        key: "type3_title",
        sources: t("subCash.invsetialBalance"),
        ...cashflows?.type3_change,
        ...cashflowsPred?.type3_change,
      },
      {
        key: "type3_income",
        sources: t("common.income"),
        ...cashflows?.type3_income_total,
        ...cashflowsPred?.type3_income_total,
        children: makePredColumn(
          cashflows.type3_income,
          cashflowsPred.type3_income
        ),
      },
      {
        key: "type3_outcome",
        sources: t("common.outcome"),
        ...cashflows?.type3_outcome_total,
        ...cashflowsPred?.type3_outcome_total,
        children: makePredColumn(
          cashflows.type3_outcome,
          cashflowsPred.type3_outcome
        ),
      },
      {
        key: "transfer",
        sources: t("subCash.changeAccount"),
      },
      {
        key: "transfer_income",
        sources: t("common.income"),
        ...cashflows?.transfer_income,
        ...cashflowsPred?.transfer_income,
      },
      {
        key: "transfer_outcome",
        sources: t("common.outcome"),
        ...cashflows?.transfer_outcome,
        ...cashflowsPred?.transfer_outcome,
      },
      {
        key: "total_change",
        sources: t("subCash.total_change"),
        ...cashflows?.total_change,
        ...cashflowsPred?.total_change,
      },
      {
        key: "remains_last",
        sources: t("subCash.remains_last"),
        ...cashflows?.ending_balance,
        ...cashflowsPred?.ending_balance,
      },
    ];

  return (
    <div className="cashflow">
      <ContentTop>
        <div className="flex">
          <FilterAccount />
          <FilterDate format="year" />
        </div>
      </ContentTop>

      <div className="table">
        <Table
          scroll={{ x: 1200, y: tableHeight }}
          bordered={true}
          columns={cashFlowPredictedColumns(t)}
          dataSource={data}
          pagination={false}
          loading={isLoading || preditedLoading}
          className="cashflow_table"
        />
      </div>
    </div>
  );
}

export default CashFlowPredictedPage;
