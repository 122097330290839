import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IBudgetPnlRes } from "src/app/services/finance/type";
import PaginationFilter from "src/components/filter/PaginationFilter";
import TableActions from "src/components/view/TableActions";
import ContentTop from "src/layout/content/ContentTop";
import { budjetPnlColumns } from "src/static/columns";
import AddBudjetPnl from "./add";
import useBudjetPnlList from "./useList";
import { useTranslation } from "react-i18next";

function BudjetPnlListPage() {
  const { t } = useTranslation();
  const {
    isLoading,
    data,
    total,
    handleGet,
    handleDelete,
    handleEdit,
    editData,
    setEditData,
  } = useBudjetPnlList();

  const columns: ColumnsType<IBudgetPnlRes> = [
    ...budjetPnlColumns(t),
    {
      title: t("common.actions"),
      dataIndex: "key",
      key: "key",
      width: 90,
      fixed: "right",
      render: (id, val) => (
        <TableActions
          onDelete={() => handleDelete(id)}
          onEdit={() => handleEdit(val)}
        />
      ),
    },
  ];

  return (
    <div className="journal">
      <ContentTop>
        <AddBudjetPnl
          handleGet={handleGet}
          editData={editData}
          setEditData={setEditData}
        />
      </ContentTop>

      <div className="journal-table-wrap">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1200 }}
          bordered={true}
          className="journal-table"
          pagination={false}
          loading={isLoading}
        />
        {total && <PaginationFilter total={total?.count} />}
      </div>
    </div>
  );
}

export default BudjetPnlListPage;
