export const AboutCardData = [
  {
    title: "home.about.card1Title",
  },
  {
    title: "home.about.card2Title",
  },
  {
    title: "home.about.card3Title",
  },
  {
    title: "home.about.card4Title",
    offsetLine: true,
  },
  {
    title: "home.about.card5Title",
    offsetLine: true,
  },
  {
    title: "home.about.card6Title",
    offsetLine: true,
  },
];

export const coinImage = ["coin-1", "coin-2", "coin-3", "coin-4"];
