import { useState } from "react";
import { useGetTeamQuery } from "src/app/services/management";
import { IProfile } from "src/app/services/users/type";
import ContentTop from "src/layout/content/ContentTop";
import AddTeam from "./addTeam";
import TeamCard from "./teamCard";
import "./team.scss";

function TeamPage() {
  const { data: teams } = useGetTeamQuery();
  const [editableData, setEditableData] = useState<IProfile>();

  const onEdit = (val: number) => {
    let obj = teams?.results?.find((el) => el.id === val);
    if (obj) setEditableData(obj);
  };

  return (
    <div className="team">
      <ContentTop>
        <AddTeam editableData={editableData} setEditableData={setEditableData} />
      </ContentTop>

      <div className="team-cards">
        {teams?.results.map((el, index) => (
          <TeamCard key={index} {...el} onEdit={onEdit} memberPostion={el.position} />
        ))}
      </div>
    </div>
  );
}

export default TeamPage;
