import { Select } from "antd";
import { useSearchParams } from "react-router-dom";
import CustomSelect from "../common/select";
import { useGetAccountAllQuery } from "src/app/services/finance";
import { useTranslation } from "react-i18next";

function FilterAccount() {
  const { Option } = Select;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useGetAccountAllQuery();

  const handleChange = (value: string) => {
    handleMakeParams("account_id", value);
  };

  const handleMakeParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) searchParams.set(key, value);
      else searchParams.append(key, value);
    } else searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return (
    <CustomSelect
      placeholder={t("common.selectAccount")}
      style={{ width: 200 }}
      allowClear
      onChange={handleChange}
      defaultValue={searchParams.get("account_id")}
      value={searchParams.get("account_id")}
      height={38}
      className="filter-source"
      showSearch
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option?.children?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA: any, optionB: any) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {data?.results.map((item) => {
        return (
          <Option value={String(item.id)} key={item.id}>
            {item.title}
          </Option>
        );
      })}
    </CustomSelect>
  );
}

export default FilterAccount;
