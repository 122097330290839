import { Popover, Statistic } from "antd";
import { ColumnsType } from "antd/es/table";
import { IJournalResData } from "src/app/services/finance/type";
import CustomButton from "src/components/common/button";
import { TFunctionType, currencyOptions } from "src/static/constants";

const fixPrice = (price: string) => {
  if (!price) return;
  if (price.toString().split(".").length > 1) return Number(price).toFixed(2);
  else return Number(price).toFixed();
};

export const journalColumns = (
  t: TFunctionType
): ColumnsType<IJournalResData> => {
  return [
    {
      title: t("common.income"),
      dataIndex: "income",
      key: "income",
      width: 130,
      sorter: (a, b) => +a.income - +b.income,
      render: (el) => {
        return el && el !== "0.000" ? (
          <div className="income-column">
            <Statistic value={fixPrice(el)} />
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: t("common.outcome"),
      dataIndex: "outcome",
      key: "outcome",
      width: 130,
      sorter: (a, b) => +a.outcome - +b.outcome,
      render: (el) =>
        el && el !== "0.000" ? (
          <div className="outcome-column">
            <Statistic value={fixPrice(el)} />
          </div>
        ) : (
          ""
        ),
    },
    {
      title: t("common.currency"),
      dataIndex: "currency",
      key: "currency",
      width: 80,
      render: (el) => currencyOptions.find((item) => item.value === el)?.label,
    },
    {
      title: t("common.account"),
      dataIndex: "account",
      key: "account",
      width: 120,
      render: (el) => el.title,
    },
    {
      title: t("common.contingents"),
      dataIndex: "contingent",
      key: "contingent",
      width: 130,
      render: (el) => el?.title,
    },
    {
      title: t("common.directions"),
      dataIndex: "direction",
      key: "direction",
      width: 130,
      render: (el) => el?.title,
    },
    {
      title: t("common.sources"),
      dataIndex: "source",
      key: "source",
      width: 200,
      render: (el) => el.title,
    },
    {
      title: t("common.comment"),
      dataIndex: "comment",
      key: "comment",
      width: 320,
      render: (el) => (
        <Popover content={<div>{el}</div>} trigger="click">
          <div className="line-clamp1">{el}</div>
        </Popover>
      ),
    },
    {
      title: t("common.users"),
      dataIndex: "user",
      key: "user",
      width: 158,
      render: (el) => (el ? el.title : ""),
    },
    {
      title: t("common.month"),
      dataIndex: "month",
      key: "month",
      render: (val) => val?.slice(0, 7),
      width: 84,
    },
    {
      title: t("common.monthly"),
      dataIndex: "key",
      key: "key",
      width: 128,
      render: (el) => (
        <CustomButton className="btn-green" style={{ fontSize: 14 }}>
          {t("common.more")}
        </CustomButton>
      ),
    },
  ];
};
export const cashFormData = [
  {
    name: "account",
    label: "Hisob raqam",
    placeholder: "Hisob raqamni tanlang",
    className: "third",
    action: "select",
  },
  {
    name: "source",
    label: "Manba",
    placeholder: "Manbani tanlang",
    className: "third",
    action: "select",
  },
  {
    name: "employee",
    label: "Xodim",
    placeholder: "Xodimni tanlang",
    className: "third",
    action: "select",
  },
  {
    name: "amount",
    label: "Summa",
    placeholder: "$76.000.00",
    className: "third",
  },
  {
    name: "cost",
    label: "Narx",
    placeholder: "11,600 сум",
    className: "third",
  },
  {
    name: "currency",
    label: "Valyuta",
    placeholder: "Valyutani tanlang",
    message: "Please select currency!",
    className: "third",
    action: "select",
  },
  {
    name: "comment",
    label: "Izoh",
    placeholder: "Izoh qoldiring!",
    className: "full",
    textarea: true,
  },
];
