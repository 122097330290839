import { Col, Form, Row } from "antd";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import {
  DateFormItem,
  InputFormItem,
  InputNumberFormItem,
} from "src/components/form/common";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import { formGutter } from "src/static/form";
import useAdd, { IAddProps } from "./useAdd";
import { useTranslation } from "react-i18next";

export default function AddPurchase(props: IAddProps) {
  const { t } = useTranslation();
  const { editableData } = props;
  const { isModalOpen, form, showModal, closeModal, onFinish } = useAdd(props);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? t("subHelper.editPurchaseTitle")
              : t("subHelper.addPurchaseTitle")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Row gutter={formGutter}>
            <DateFormItem
              label={`${t("subHelper.purchaseDate")} *`}
              message="Sanani tanlang"
            />
            <Col span={12}>
              <ContingentFormItem is_supplier required />
            </Col>
            <InputNumberFormItem
              name="total_amount"
              label={`${t("common.all")} (${t("common.uzs")}) *`}
              message="Summani kiriting"
            />
            <InputFormItem
              col={24}
              textarea
              name="comment"
              label={t("common.comment")}
            />
          </Row>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}
