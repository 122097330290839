import { Form, Row } from "antd";
import { Eye } from "iconsax-react";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { DateFormItem, InputNumberFormItem } from "src/components/form/common";
import { formGutter } from "src/static/form";
import useAdd, { IAddProps } from "./useAdd";
import { useTranslation } from "react-i18next";

export default function AddRemaining(props: IAddProps) {
  const { t } = useTranslation();
  const { title, type } = props;
  const { isModalOpen, form, showModal, closeModal, onFinish } = useAdd(props);

  return (
    <div className="add">
      <CustomButton
        icon={<Eye size="18" color={"#1677ff"} />}
        type="link"
        height={38}
        onClick={showModal}
        style={{ display: "flex", alignItems: "center" }}
      >
        {type === "employee" ? t("subInfo.initial_money") : t("common.view")}
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
        style={{ maxHeight: "90%" }}
      >
        <ModalHeader title={title} />

        <Form
          form={form}
          className="form debt_credit-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Row gutter={formGutter}>
            <DateFormItem format={"month"} />
            <InputNumberFormItem
              col={12}
              name="remaining"
              label={`${t("subInfo.initial_money")} (${t("common.uzs")})`}
              message={t("subInfo.enterInital_money")}
            />
          </Row>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}
