import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { IAccount, IAccountTable } from "../services/finance/type";

export interface IAccountState {
  accounts?: IAccount[];
  accountsAll?: IAccount[];
}

const initialState: IAccountState = {
  accounts: undefined,
};

const accountSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    saveProjects: (state, action: PayloadAction<IAccountState["accounts"]>) => {
      state.accounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getAccount.matchFulfilled, (state, action) => {
      let arr: IAccountTable[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, index: index + 1, ...item });
      });
      state.accounts = arr;
    });
    builder.addMatcher(financeApi.endpoints.getAccountAll.matchFulfilled, (state, action) => {
      state.accountsAll = action.payload.results;
    });
  },
});

export const { saveProjects } = accountSlice.actions;

export default accountSlice.reducer;
