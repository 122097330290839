import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { IDirection } from "../services/finance/type";

export interface IDirectionState {
  directions?: IDirection[];
}

const initialState: IDirectionState = {
  directions: undefined,
};

const directionSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    saveProjects: (state, action: PayloadAction<IDirectionState["directions"]>) => {
      state.directions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getDirection.matchFulfilled, (state, action) => {
      let arr: IDirection[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, ...item });
      });
      state.directions = arr;
    });
  },
});

export const { saveProjects } = directionSlice.actions;

export default directionSlice.reducer;
