import { useNavigate } from "react-router-dom";
import Typist from "react-typist";
import { cursorTypist } from "src/static/constants";
import "./custom404.scss";

function Custom404() {
  const navigate = useNavigate();

  return (
    <div className="custom404">
      <div className="caution-tape">
        <Typist avgTypingDelay={60} cursor={cursorTypist}>
          <Typist.Delay ms={2500} />
          <p>404 Sahifa topilmadi</p>
        </Typist>
      </div>

      <button onClick={() => navigate("/")} type="button" name="back">
        <Typist avgTypingDelay={50} cursor={cursorTypist}>
          <Typist.Delay ms={4200} />
          Ortga qaytish
        </Typist>
      </button>
      <Typist stdTypingDelay={0} avgTypingDelay={50} cursor={cursorTypist}>
        <Typist.Delay ms={500} />
        <p className="problem-text">Nimadir xato ketdi.</p>
      </Typist>
    </div>
  );
}

export default Custom404;
