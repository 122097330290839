import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { message } from "antd";
import { language } from "src/layout/header/components/selectLangs/constants";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses createAsyncThunk from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // Local language
    const lang = (language as "uz" | "ru" | "en" | "uz_kr") || "uz";
    const errorList: any = {
      email: {
        uz: `Bu email bilan allaqchon boshqa foydalanuvchi ro'yhatdan o'tgan`,
        ru: `Другой пользователь уже зарегистрирован с этим адресом электронной почты`,
        uz_kr: `Бу емаил билан аллақчон бошқа фойдаланувчи рўйҳатдан ўтган`,
        en: `Another user is already registered with this email`,
      },
      server: {
        uz: `Server bilan bog'liq xatolik. Iltimos bu haqda ma'sul xodimlarga xabar bering`,
        ru: `Ошибка, связанная с сервером. Пожалуйста, сообщите об этом ответственному персоналу.`,
        uz_kr: `Сервер билан боғлиқ хатолик. Илтимос бу ҳақда маъсул ходимларга хабар беринг`,
        en: `Server related error. Please report this to the responsible staff`,
      },
      logout: {
        uz: `Iltimos avval tizimga kiring!`,
        ru: `Пожалуйста, войдите сначала!`,
        uz_kr: `Илтимос аввал тизимга киринг!`,
        en: `Please login first!`,
      },
    };

    const error_message =
      action.payload?.data?.message ?? action.payload?.data?.msg ?? action.payload?.data?.err?.message ?? "";
    error_message && error_message !== "A validation error occurred." && message.warning(error_message);

    const errors = action.payload?.data?.errors ?? action.payload?.data ?? "";

    // Checking error is object or nott
    if (typeof errors === "object" && errors !== null) {
      Object.keys(errors).map((item: string) => message.error(errorList[item]?.[lang]));
    }
    if (errors.length > 0) {
      errors?.forEach((item: string) => {
        item && message.warning(item);
      });
    }
    console.log("action.payload", action.payload);
    const status = action.payload?.originalStatus;

    if (status === 500) {
      message.warning(errorList.server[lang]);
    } else if (status === 401 || status === 403) {
      window.location.href = "/auth/signin";
      message.warning(errorList.logout[lang]);
    }
  }

  return next(action);
};
