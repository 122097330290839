import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./explain.scss";
import { useTranslation } from "react-i18next";
import { explainData } from "./constants";
import { Image } from "antd";

window.addEventListener("scroll", function () {
  const card1 = document.querySelector(".card-4") as HTMLElement;
  const card2 = document.querySelector(".card-6") as HTMLElement;
  if (card1 && card2) {
    var scrollAmount = window.pageYOffset || document.documentElement.scrollTop;
    var moveDistance = scrollAmount * 0.05; // Example multiplier

    // Apply the movement
    card1.style.transform = card2.style.transform = "translate3d(0px, " + moveDistance + "px, 0px)";
  }
});

function SectionExplain() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const { t } = useTranslation();

  return (
    <div className="section-explain" id="explain">
      <div className="explain-wrap container">
        <h2 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <span>{t("home.explain.title1")} </span>
          {t("home.explain.title2")}
        </h2>
        <div className="explain-cards">
          <img
            className="background-effect"
            src={require("src/assets/img/home/gradient.png")}
            alt="background effect"
          />

          {explainData.map((card, i) =>
            card.img ? (
              <div className={`img-card card-${i + 1}`} key={i}>
                <div className="img-card-wrap">
                  {/* <img
                    src={require(`src/assets/img/home/${card.img}.png`)}
                    alt="card"
                  /> */}
                  <Image className="img-card-img" src={require(`src/assets/img/home/${card.img}.png`)} />
                </div>
                <div className="img-card-detail">
                  <h4>{t(card.title)}</h4>
                  <p>{t(card.text)}</p>
                </div>
              </div>
            ) : (
              <div className="text-card card-2" key={i}>
                <h3>{t(card.title)}</h3>
                <p>{t(card.text)}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionExplain;
