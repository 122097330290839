import { Form, TourProps } from "antd";
import { useState } from "react";
import { useCommentMutation } from "src/app/services/users";
import { IComment } from "src/app/services/users/type";
import { clearMask } from "src/utils";

export default function useComment() {
  const [comment, { isLoading }] = useCommentMutation();
  const [form] = Form.useForm();

  const onFinish = (val: IComment) => {
    val.phone_number = "+998" + clearMask(val.phone_number);
    comment(val)
      .unwrap()
      .then((res) => {
        form.resetFields();
        setOpen(true);
      });
  };

  const [open, setOpen] = useState(false);
  const steps: TourProps["steps"] = [
    {
      title: "So'rovingiz qabul qilindi!",
      description: "Siz bilan xodimlarimiz tez orada bog'lanadi.",
      target: null,
    },
  ];

  return { onFinish, form, isLoading, steps, open };
}
