import { Popover, Statistic } from "antd";
import { ColumnsType } from "antd/es/table";
import { IBudgetPnlRes } from "src/app/services/finance/type";
import { TFunctionType } from "../constants";

export const budjetPnlColumns = (
  t: TFunctionType
): ColumnsType<IBudgetPnlRes> => [
  {
    title: t("common.date"),
    dataIndex: "date",
    key: "date",
    fixed: "left",
    render: (val) => val.slice(0, 7),
    width: 110,
  },
  {
    title: t("subCash.profit"),
    dataIndex: "profit",
    key: "profit",
    width: 130,
    sorter: (a, b) => +a.profit - +b.profit,
    render: (el) => {
      return el && el !== "0.000" ? (
        <div className="income-column">
          <Statistic value={el} />
        </div>
      ) : (
        ""
      );
    },
  },
  {
    title: t("common.expense"),
    dataIndex: "loss",
    key: "loss",
    width: 130,
    sorter: (a, b) => +a.loss - +b.loss,
    render: (el, item) =>
      (el && el !== "0.000") || item.cost ? (
        <div className="outcome-column">
          <Statistic value={item.cost || el} />
        </div>
      ) : (
        ""
      ),
  },
  {
    title: t("common.account"),
    dataIndex: "account",
    key: "account",
    width: 150,
    render: (el, item) =>
      el?.title + (item.exchange_rate ? " (do`llar)" : " (so`m)"),
  },
  {
    title: t("common.directions"),
    dataIndex: "direction",
    key: "direction",
    width: 130,
    render: (el) => el?.title,
  },
  {
    title: t("common.sources"),
    dataIndex: "source",
    key: "source",
    width: 200,
    render: (el) => el?.title,
  },
  {
    title: t("common.comment"),
    dataIndex: "comment",
    key: "comment",
    width: 320,
    render: (el) => (
      <Popover content={<div>{el}</div>} trigger="click">
        <div className="line-clamp1">{el}</div>
      </Popover>
    ),
  },
];
