import i18n from "i18next";
import { PropsWithChildren } from "react";
import { initReactI18next } from "react-i18next";
import { languageEn } from "src/language/en";
import { languageRu } from "src/language/ru";
import { languageUz } from "src/language/uz";
import { languageUzKr } from "src/language/uz_kr";
import { language } from "src/layout/header/components/selectLangs/constants";

function LanguageProvider({ children }: PropsWithChildren) {
  i18n.use(initReactI18next).init({
    resources: {
      uz: { translation: languageUz },
      uz_kr: { translation: languageUzKr },
      ru: { translation: languageRu },
      en: { translation: languageEn },
    },
    fallbackLng: `${language || "uz"}`,
    interpolation: { escapeValue: false },
  });
  return <>{children}</>;
}

export default LanguageProvider;
