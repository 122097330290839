import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetPnlStatMutation } from "src/app/services/finance";
import { PnlAttributes } from "src/app/services/finance/type";
import { window_757 } from "src/static/constants";
import { handleMakeUrlNoPage } from "src/utils";
import ChartHeader from "../../components/chartHeader";
import { monthes, pnlStatMenu } from "../../constants";
import { useTranslation } from "react-i18next";

interface Props {
  type: PnlAttributes;
}

interface IPnlStat {
  data: number[];
  total: number;
}

export default function BasePnlStat({ type }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const defTitle = pnlStatMenu(t).find((el) => el.key === type)?.title;

  //Get section
  const [searchParams] = useSearchParams();
  const [getPnl] = useGetPnlStatMutation();
  const [data, setData] = useState<IPnlStat>();

  const handleGet = () => {
    let url = "type=" + type + handleMakeUrlNoPage(searchParams);
    getPnl(url)
      .unwrap()
      .then((res) => {
        let obj: IPnlStat = {
          data: [],
          total: 0,
        };

        for (let index = 1; index < 13; index++) {
          let value = res[index.toString()]?.amount || 0;
          obj.data.push(value);
          obj.total += value;
        }

        setData(obj);
      });
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, location.pathname]);

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true,
      height: 400,
    },
    xAxis: {
      type: "category",
      data: monthes,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        height: 200,
      },
    },
    series: [
      {
        data: data?.data || "",
        type: "line",
        smooth: true,
        areaStyle: {},
        itemStyle: {
          color:
            type === "overhead"
              ? "#3dc54a"
              : type === "gross"
              ? "#4497B1"
              : type === "operative"
              ? "#536872"
              : "#E5AE88",
        },
      },
    ],
  };

  return (
    <div className="balance" id={type}>
      <div className="balance-content">
        <ChartHeader name={defTitle} amount={data?.total} />

        <div className="statistics-chart balance-chart">
          <div className="balance-chart-content">
            <ReactEcharts
              opts={{ renderer: "svg", height: window_757 ? 500 : 586 }}
              option={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
