import { Form, Input, InputNumber, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddCommitmentMutation,
  useEditCommitmentMutation,
} from "src/app/services/finance";
import {
  ICommitmentTable,
  IFormCommitment,
} from "src/app/services/finance/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { dateFormat } from "src/components/filter/FilterDate";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";

interface Props {
  handleGet: () => void;
  editableData?: ICommitmentTable;
  setEditableData: React.Dispatch<
    React.SetStateAction<ICommitmentTable | undefined>
  >;
}

export default function AddCommitment({
  handleGet,
  editableData,
  setEditableData,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addCommitment, { isSuccess: addStatus }] = useAddCommitmentMutation();
  const [editCommitment, { isSuccess: editStatus }] =
    useEditCommitmentMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormCommitment) => {
    let { date, source, contingent, ...rest } = val;
    let obj = {
      ...rest,
      source: +source,
      contingent: contingent ? +contingent : undefined,
      date: val.date?.format("YYYY-MM") + "-01" || "",
    };

    if (editableData) editCommitment({ id: editableData.id, body: obj });
    else addCommitment(obj);
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Majburiyat muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`Majburiyat muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { date, contingent, source, ...rest } = editableData;

      form.setFieldsValue({
        date: dayjs(date, dateFormat),
        contingent: contingent ? String(contingent?.id) : null,
        source: String(source.id),
        ...rest,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? t("subHelper.editDutyTitle")
              : t("subHelper.addDutyTitle")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <DateFormItem className="half" format="month" />

          <Form.Item
            name="amount"
            label={`${t("common.amount")} (${t("common.uzs")})`}
            className="half"
            rules={[{ required: true, message: t("common.enterAmount") }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <ContingentFormItem className="half" required={false} />

          <SourceFormItem className="full" allData />

          <Form.Item
            name="comment"
            label={`${t("common.comment")} *`}
            rules={[{ required: true, message: "Izohni kiriting!" }]}
            className="full"
          >
            <Input.TextArea />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}
