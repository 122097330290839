import { ClockIcon, CreditIcon, DollorIcon } from "src/assets/svg/chartCards";
import { PoundCircleFilled } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { CurrencyAttributes } from "src/app/services/type";
import { Statistic } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  name: "bank" | "system" | "gap";
  balance?: number;
  currency?: CurrencyAttributes;
}

function MonitorCard({ name, balance, currency }: Props) {
  const { t } = useTranslation();
  const [searchparams] = useSearchParams();
  const date = searchparams.get("date");

  return (
    <div className="monitor-card">
      <div className="monitor-card-header">
        <div>
          {name === "bank" ? (
            <DollorIcon />
          ) : name === "system" ? (
            <CreditIcon />
          ) : (
            <PoundCircleFilled className="pound-icon" />
          )}
        </div>
        {balance === 0 && name === "gap" ? (
          <div>
            <h4>Kassa chiqdi</h4>
          </div>
        ) : (
          <div>
            <h4>
              {name === "bank"
                ? t("subCash.exactAccount")
                : name === "system"
                ? t("subCash.system")
                : t("subCash.difesit")}
            </h4>
            <p>{t("subCash.hisoboti")}</p>
          </div>
        )}
      </div>

      <div className="monitor-card-price">
        {balance === 0 && name === "gap"
          ? ""
          : balance && (
              <p style={{ display: "flex", alignItems: "flex-start" }}>
                {currency === "usd" && "$ "}
                <Statistic value={balance} /> {currency !== "usd" && t(`common.${currency}`)}
              </p>
            )}
      </div>

      <div className="monitor-card-footer">
        <div>
          <ClockIcon />
          {date ?? t("common.today")}
        </div>
      </div>
    </div>
  );
}

export default MonitorCard;
