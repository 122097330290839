export const card1Data = [
  "home.priority.card1line1",
  "home.priority.card1line2",
  "home.priority.card1line3",
  "home.priority.card1line4",
  "home.priority.card1line5",
  "home.priority.card1line6",
];
export const card2Data = [
  "home.priority.card2line1",
  "home.priority.card2line2",
  "home.priority.card2line3",
  "home.priority.card2line4",
  "home.priority.card2line5",
  "home.priority.card2line6",
];
export const card3Data = [
  "home.priority.card3line1",
  "home.priority.card3line2",
  "home.priority.card3line3",
  "home.priority.card3line4",
  "home.priority.card3line5",
  "home.priority.card3line6",
];
