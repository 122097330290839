import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber } from "antd";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import AccountFormItem from "src/components/formItem/AccountFormItem";
import CurrencyFormItem from "src/components/formItem/CurrencyFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";
import useAddBudgetPnl, { IAddProps } from "./useAdd";
import { useTranslation } from "react-i18next";

function AddBudjetPnl(props: IAddProps) {
  const { t } = useTranslation();
  const { editData } = props;
  const { form, visible, isLoading, type, currency, onChangeAccount, showModal, closeModal, onFinish } =
    useAddBudgetPnl(props);
  return (
    <div className="add">
      <div className="journal-actions">
        <CustomButton onClick={() => showModal("profit")} className="header-btn-green" height={38}>
          <PlusOutlined /> {t("subCash.profit")}
        </CustomButton>
        <CustomButton onClick={() => showModal("loss")} className="header-btn-red" height={38}>
          <MinusOutlined /> {t("common.expense")}
        </CustomButton>
      </div>
      <CustomModal padding={45} open={visible} width={640} onCancel={closeModal} centered={true}>
        <ModalHeader
          title={`${type === "profit" ? t("subCash.profit") : t("common.expense")} ${
            editData ? t("subHelper.editInformation") : t("common._add")
          }`}
        />

        <Form
          form={form}
          requiredMark={false}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
        >
          <DateFormItem className="half" format="month" />

          {type === "profit" ? (
            <DirectionFormItem className="half" />
          ) : (
            <SourceFormItem className="half" handleSort isExpence />
          )}

          <AccountFormItem className="half" required onChange={onChangeAccount} />
          <CurrencyFormItem className="half" disabled />

          <Form.Item
            name="amount"
            label={`${t("subCash.profit")} ${currency ? `(${t(`common.${currency}`)})` : ""}*`}
            rules={[{ required: true, message: t("common.enterProfit") }]}
            className="half"
          >
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          {type === "profit" && (
            <Form.Item
              name="cost"
              label={`${t("common.value")} ${currency ? `(${t(`common.${currency}`)})` : ""}*`}
              rules={[{ required: true, message: t("common.enterValue") }]}
              className="half"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}

          {currency && currency !== "uzs" && (
            <Form.Item
              name="exchange_rate"
              label={t("common.currencyRate")}
              className="half"
              rules={[
                {
                  required: true,
                  message: t("common.enterCurrencyRate"),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          )}

          <Form.Item
            name="comment"
            label={`${t("common.comment")} *`}
            rules={[{ required: true, message: t("common.enterComment") }]}
            className="full"
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <ModalFooter onCancel={closeModal} cancelText={t("common.check")} okLoading={isLoading} />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddBudjetPnl;
