import { useRef } from "react";
import { partners } from "./constants";
import "./partners.scss";

// Swiper
import SwiperCore from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import {
  isMobilePartnersSlider,
  isMobilePartnersSlider3,
  isMobilePartnersSlider4,
} from "src/static/constants";
import { useTranslation } from "react-i18next";

function Partners() {
  const swiperRef = useRef<SwiperCore>();
  const { t } = useTranslation();
  return (
    <div className="section-partners" id="partners">
      <div className="partners-wrap">
        <h2>
          {t("home.partners.title1")}
          <span> {t("home.partners.title2")}</span>
        </h2>
        <div className="partners-wrap-logos">
          <Swiper
            spaceBetween={40}
            slidesPerView={
              isMobilePartnersSlider4
                ? 2
                : isMobilePartnersSlider3
                ? 3
                : isMobilePartnersSlider
                ? 4
                : 5
            }
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[Autoplay]}
            className="partners-slide-cards"
          >
            {partners.map((el) => (
              <SwiperSlide key={el} className="partners-slide-card">
                <img
                  className="section-partners-img"
                  src={require(`src/assets/img/partners/${el}.png`)}
                  alt="partners logos"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <ArrowLeftOutlined
            className="slider-prev"
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <ArrowRightOutlined
            className="slider-next"
            onClick={() => swiperRef.current?.slideNext()}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default Partners;
