import { Tag, Statistic } from "antd";
import { ColumnsType } from "antd/es/table";
import { ITransactionTable } from "src/app/services/finance/type";
import { TFunctionType } from "src/static/constants";

export const transferColumns = (t: TFunctionType): ColumnsType<ITransactionTable> => [
  {
    title: t("subCash.from"),
    dataIndex: "transaction_from",
    key: "transaction_from",
    render: (el) => el.title,
  },
  {
    title: t("subCash.to"),
    dataIndex: "transaction_to",
    key: "transaction_to",
    render: (el) => el.title,
  },
  {
    title: t("subCash.size"),
    dataIndex: "amount",
    key: "amount",
    render: (_, { amount, transaction_from }) => (
      <Tag color={transaction_from.currency === "usd" ? "cyan" : "geekblue"}>
        <div className="transfer-table-tag">
          {transaction_from.currency === "usd" && "$ "}
          <Statistic value={amount} />
          {transaction_from.currency !== "usd" && t(`common.${transaction_from.currency}`)}
        </div>
      </Tag>
    ),
  },
  {
    title: t("common.currencyRate"),
    dataIndex: "exchange_rate",
    key: "exchange_rate",
    render: (val) =>
      val ? (
        <div className="transfer-table-tag">
          <Statistic value={val} />
        </div>
      ) : (
        ""
      ),
  },
  {
    title: t("subCash.transferDate"),
    dataIndex: "date",
    key: "date",
    // render: (el) => el.split(" ")[0],
  },
];
