import { Form, Input, message } from "antd";
import { MaskedInput } from "antd-mask-input";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCommentMutation } from "src/app/services/users";
import { IComment } from "src/app/services/users/type";
import { PhoneFilledSvg, TelegramFilledSvg } from "src/assets/svg/homeIcons";
import CustomButton from "src/components/common/button";
import { isMobilePartnersSlider2 } from "src/static/constants";
import { clearMask } from "src/utils";
import "./contact.scss";

function SectionContact() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);
  const { t } = useTranslation();

  const [comment, { isLoading }] = useCommentMutation();
  const [form] = Form.useForm();

  const onFinish = (val: IComment) => {
    val.phone_number = "+998" + clearMask(val.phone_number);
    comment(val)
      .unwrap()
      .then(() => {
        form.resetFields();
        message.success("So'rovingiz qabul qilindi! Siz bilan xodimlarimiz tez orada bog'lanadi.");
      });
  };

  return (
    <div className="section-contact" id="contact">
      <div className="contact-wrap container">
        <div className="contact-details" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1500">
          <h2>
            {t("home.contact.title1")} <span>{t("home.contact.title2")}</span>
          </h2>
          <div className="contact-details-connect">
            <div>
              <PhoneFilledSvg />
              <a href="tel:+998712001474">+998 71-200-14-74</a>
            </div>
            <div>
              <TelegramFilledSvg />
              <a href="https://t.me/aqchamsupport" target="_blanl" rel="noreferrer">
                @aqchamsupport
              </a>
            </div>
          </div>
        </div>
        <div className="contact-form" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1500">
          <p>{t("home.contact.formTitle")}</p>
          <Form
            form={form}
            requiredMark={false}
            name="normal_login"
            className="form-form"
            onFinish={onFinish}
            layout={"vertical"}
          >
            <Form.Item
              name="first_name"
              label="Ismingiz *"
              rules={[{ required: true, message: "Ismingizni kiriting" }]}
              className="full"
            >
              <Input />
            </Form.Item>
            {/*  */}
            <Form.Item
              name="phone_number"
              label="Telefon raqamingiz *"
              rules={[{ required: true, message: "Telefon raqamingizni kiriting" }]}
              className="full"
            >
              <MaskedInput className="phoneInput contact-input-phone" addonBefore="+998" mask="(00)-000-00-00" />
            </Form.Item>
            {/*  */}
            <CustomButton
              className="button-filled"
              fontSize={20}
              height={isMobilePartnersSlider2 ? 40 : 48}
              type="primary"
              loading={isLoading}
              htmlType="submit"
            >
              {t("home.contact.button")}
            </CustomButton>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SectionContact;
