import { ConfigProvider, SwitchProps, Switch } from "antd";
import { useTypedSelector } from "src/app/store";

interface Props extends SwitchProps {}

function CustomSwitch({ ...rest }: Props) {
  const { colors } = useTypedSelector((state) => state.layout);
  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            colorPrimary: colors.color_content_primary,
            colorPrimaryHover: colors.color_content_primary,
          },
        },
      }}
    >
      <Switch {...rest} />
    </ConfigProvider>
  );
}

export default CustomSwitch;
