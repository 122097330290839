import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { IAktTable } from "../services/finance/type";

export interface IAktState {
  akts?: IAktTable[];
}

const initialState: IAktState = {
  akts: undefined,
};

const aktSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getAkt.matchFulfilled, (state, action) => {
      let arr: IAktTable[] = [];
      action.payload.results.forEach((item) => {
        let { key, ...rest } = item;
        arr.push({ key: item.id, ...rest });
      });
      state.akts = arr;
    });
  },
});

export default aktSlice.reducer;
