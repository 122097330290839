export const explainData = [
  {
    title: "home.explain.card1Title",
    text: "home.explain.card1Text",
    img: "explain-1",
  },
  {
    title: "home.explain.card2Title",
    text: "home.explain.card2Text",
  },
  {
    title: "home.explain.card3Title",
    text: "home.explain.card3Text",
    img: "explain-2",
  },
  {
    title: "home.explain.card4Title",
    text: "home.explain.card4Text",
    img: "explain-3",
  },
  {
    title: "home.explain.card5Title",
    text: "home.explain.card5Text",
    img: "explain-4",
  },
  {
    title: "home.explain.card6Title",
    text: "home.explain.card6Text",
    img: "explain-5",
  },
];
