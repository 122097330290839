import {
  FacebookSvg,
  InstagramSvg,
  TelegramFilledSvg,
} from "src/assets/svg/homeIcons";
import "./footer.scss";
import CustomButton from "src/components/common/button";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-wrap container">
        <div className="footer-info">
          <img src={require("src/assets/img/logo.png")} alt="logo" />
          <p>{t("home.footer.description")}</p>
          <div className="footer-medias">
            <div>
              <TelegramFilledSvg />
            </div>
            <div>
              <InstagramSvg />
            </div>
            <div>
              <FacebookSvg />
            </div>
          </div>
        </div>
        <div className="footer-columns">
          <p className="footer-title">{t("home.footer.linkTitle")}</p>
          <a href="#about">{t("home.menu.menu1")}</a>
          <a href="#partners">{t("home.menu.menu2")}</a>
          <a href="#price">{t("home.menu.menu3")}</a>
        </div>
        <div className="footer-columns">
          <p className="footer-title">{t("home.footer.blogTitle")}</p>
          <p>{t("home.footer.blogLine1")}</p>
          <p>{t("home.footer.blogLine2")}</p>
        </div>
        <div className="footer-columns">
          <p className="footer-title">{t("home.footer.adressTitle")}</p>
          <p>{t("home.footer.adressLine1")}</p>
          <p>(+998) 90 71-200-14-74</p>
          <CustomButton
            className="button-filled"
            type="primary"
            paddingContentHorizontal={1}
          >
            {t("home.footer.contactButton")}
          </CustomButton>
        </div>
      </div>
      <p className="footer-copyright container">
        Copyright 2023. All rights reserved
      </p>
    </div>
  );
}

export default Footer;
