import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetStorageMutation } from "src/app/services/management";
import useParamsHook from "src/hooks/params";
import { ICashflowTableItem } from "src/pages/sub_control_reports/cashflow/type";
import { TFunctionType } from "src/static/constants";
import { findMonth, handleMakeMonthObj, handleMakeUrl } from "src/utils";

export default function useStorage() {
  const { t } = useTranslation();
  //Get section
  const { searchParams } = useParamsHook();

  const [getFunc, { isLoading }] = useGetStorageMutation();
  const [data, setData] = useState<ICashflowTableItem[]>([]);
  const handleGet = () => {
    let url = handleMakeUrl(searchParams);
    getFunc(url)
      .unwrap()
      .then((res) => {
        const total = handleMakeMonthObj(t("subHelper.storage"), "total");
        const raw_material = handleMakeMonthObj(t("subHelper.storageRowMaterials"), "raw_material");
        const semi_finished_product = handleMakeMonthObj(t("subHelper.storageSemiFinished"), "semi_finished_product");
        const finished_product = handleMakeMonthObj(t("subHelper.storageFinished"), "finished_product");
        res.results.forEach(({ date, price, product_type }) => {
          const month = findMonth(date.slice(5, 7));
          if (!month) return;
          if (product_type === "raw_material") {
            raw_material[month] = price;
          } else if (product_type === "semi_finished_product") {
            semi_finished_product[month] = price;
          } else if (product_type === "finished_product") {
            finished_product[month] = price;
          }
          total[month] = total[month] + price;
        });
        setData([total, raw_material, semi_finished_product, finished_product]);
      });
  };
  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return {
    data,
    isLoading,
    handleGet,
    getColumns,
  };
}

const getColumns = (t: TFunctionType) => {
  const columns: ColumnsType<ICashflowTableItem> = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: t("months.jan"),
      dataIndex: "jan",
      key: "jan",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.feb"),
      dataIndex: "feb",
      key: "feb",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.march"),
      dataIndex: "mar",
      key: "mar",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.apr"),
      dataIndex: "apr",
      key: "apr",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.may"),
      dataIndex: "may",
      key: "may",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.jun"),
      dataIndex: "june",
      key: "june",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.jul"),
      dataIndex: "july",
      key: "july",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.aug"),
      dataIndex: "aug",
      key: "aug",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.sep"),
      dataIndex: "sep",
      key: "sep",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.oct"),
      dataIndex: "oct",
      key: "oct",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.nov"),
      dataIndex: "nov",
      key: "nov",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
    {
      title: t("months.dec"),
      dataIndex: "dec",
      key: "dec",
      width: 110,
      render: (val) => val?.toLocaleString(),
    },
  ];

  return columns;
};
