import { WechatOutlined } from "@ant-design/icons";
import { FloatButton, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { web_socket_url } from "src/app/services/api/const";
import { useChatMutation } from "src/app/services/users";
import { IChatRes } from "src/app/services/users/type";
import { useTypedSelector } from "src/app/store";
import { MessageSendSvg } from "src/assets/svg";
import { CloseIcon } from "src/assets/svg/homeIcons";
import CustomButton from "src/components/common/button";
import "./chat.scss";
import ChatMessage from "./components/ChatMessage";

function ChatPage() {
  const location = useLocation();
  // Websoket functions
  const { token } = useTypedSelector((state) => state.users);
  const { sendMessage, lastMessage } = useWebSocket(`${web_socket_url}/chat?authorization=${token}`);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (lastMessage && messages.length > 0) {
      console.log("lastMessage", lastMessage);
      forceFocusEnding();
      let arr = messages;
      // if (arr[arr.length - 1].answer) {
      //   arr.push({ answer: JSON.parse(lastMessage.data)?.assistant });
      // } else {
      arr[arr.length - 1].answer = JSON.parse(lastMessage.data)?.assistant;
      setMessages([...arr]);
      setValue(undefined);
      setLoading(false);
    }
  }, [lastMessage]);

  //Declaring ref and force focus to end of chat
  const endingRef = useRef<HTMLInputElement>(null);
  const forceFocusEnding = () => {
    endingRef && endingRef?.current?.focus();
  };

  //Send chat queries
  const [sendMes, { data, isLoading, isError }] = useChatMutation();
  const [messages, setMessages] = useState<IChatRes[]>([]);
  const [value, setValue] = useState<string | undefined>();

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleSend = () => {
    let cur_val = value || "";
    setMessages([...messages, { question: cur_val }]);
    if (location.pathname === "/dashboard/cash") {
      console.log("webcoket");
      setLoading(true);
      sendMessage(
        JSON.stringify({
          message: cur_val,
          type: "cashflow",
        })
      );
    } else sendMes({ question: cur_val });
  };

  useEffect(() => {
    if (data) {
      forceFocusEnding();
      let arr = messages;
      arr[arr.length - 1].answer = data.answer;
      setMessages([...arr]);
      setValue(undefined);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      let arr = messages.slice(0, messages.length - 1);
      setMessages([...arr]);
    }
  }, [isError]);

  //Popover section
  const [open, setOpen] = useState(false);

  return (
    <div>
      <FloatButton
        icon={<WechatOutlined onClick={() => setOpen((prev) => !prev)} style={{ fontSize: 24 }} />}
        shape="square"
        type="primary"
        style={{ right: 24 }}
        className="chat-float"
      />

      <div className={`chat ${open && "chat-opened"}`}>
        <div className="chat-header">
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <div className="chat-content">
          {messages?.map((item, index) => {
            return (
              <div key={index + "message"} className="chat-message">
                {item.question && <ChatMessage text={item.question} isUser />}
                {item.answer && <ChatMessage response={item.answer} />}
              </div>
            );
          })}
          {(isLoading || loading) && (
            <div className="chat-message">
              <ChatMessage loading />
            </div>
          )}
          <input ref={endingRef} className="chat-message-ref" />
        </div>

        <div className="chat-input">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyUpCapture={handleKeypress}
            placeholder="O'zingizni qiziqtirgan savolni yozing"
            size="large"
            disabled={isLoading || loading}
          />
          <CustomButton
            type="primary"
            size="large"
            onClick={handleSend}
            icon={<MessageSendSvg />}
            disabled={!value}
            loading={isLoading || loading}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
