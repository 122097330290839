import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { currencyOptions } from "src/static/constants";

interface Props {
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

function CurrencyFormItem({ required, className, disabled }: Props) {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="currency"
      label={`${t("common.currency")}${required ? " *" : ""}`}
      rules={
        required
          ? [{ required: true, message: t("common.selectCurrency") }]
          : []
      }
      className={className || "full"}
    >
      <Select disabled={disabled} allowClear options={currencyOptions} />
    </Form.Item>
  );
}

export default CurrencyFormItem;
