import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSourceMutation } from "src/app/services/finance";
import { IOption } from "src/app/services/type";
import { useTypedSelector } from "src/app/store";

interface Props {
  className?: string;
  required?: boolean;
  allData?: boolean;
  handleSort?: boolean;
  isExpence?: boolean;
  isSalaryType?: boolean;
}

function SourceFormItem({
  className,
  required = true,
  allData,
  handleSort,
  isExpence,
  isSalaryType,
}: Props) {
  const { t } = useTranslation();
  const [getSource, { data }] = useGetSourceMutation();
  const [options, setOptions] = useState<IOption[]>([]);
  const { journalAction } = useTypedSelector((state) => state.journal);

  useEffect(() => {
    getSource("limit=1000");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      let arr: IOption[] = [];
      let arr_sorted = allData
        ? data.results
        : data.results.filter(
            (el) =>
              el.is_expense ===
              (handleSort ? isExpence : journalAction === "outcome")
          );
      if (isSalaryType)
        arr_sorted = arr_sorted.filter((el) => el.title.includes("иш ҳақи"));
      arr_sorted.forEach((item) => {
        arr.push({ label: item.title, value: item.id.toString() });
      });
      setOptions(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, journalAction]);

  return (
    <Form.Item
      name="source"
      label={`${t("common.Usource")}${required ? " *" : ""}`}
      rules={
        required ? [{ required: true, message: t("common.selectSource") }] : []
      }
      className={className || "full"}
    >
      <Select
        allowClear
        options={options}
        showSearch
        optionFilterProp="label"
        filterOption={(input, option: any) =>
          option?.label?.toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.label
            ?.toLowerCase()
            .localeCompare(optionB.label.toLowerCase())
        }
      />
    </Form.Item>
  );
}

export default SourceFormItem;
