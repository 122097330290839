import { Form, Input, Select, Upload } from "antd";
import { MaskedInput } from "antd-mask-input";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import {
  useAddTeamMutation,
  useEditTeamMutation,
} from "src/app/services/management";
import { ITeamAdd } from "src/app/services/management/type";
import { IProfile } from "src/app/services/users/type";
import { PlusIcon, UserIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { phonePattern, positionOptions } from "src/static/constants";
import { clearMask, handlePreviewFile, makePhoneMask } from "src/utils";
import { addTeamFormData } from "./constants";
import { useTranslation } from "react-i18next";

interface Props {
  customTitle?: string;
  handleGet?: () => void;
  editableData?: IProfile;
  setEditableData: React.Dispatch<React.SetStateAction<IProfile | undefined>>;
}

function AddTeam({
  editableData,
  setEditableData,
  handleGet,
  customTitle = "common.add",
}: Props) {
  //Modal section
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
    setFileList([]);
    setEditableData(undefined);
  };

  //Form section
  const [position, setPosition] = useState("");
  const [addTeam, { isLoading: addLoading, isSuccess: addStatus }] =
    useAddTeamMutation();
  const [editTeam, { isLoading: editLoading, isSuccess: editStatus }] =
    useEditTeamMutation();
  const onFinish = (val: ITeamAdd) => {
    if (val.phone) val.phone = clearMask(val.phone);
    if (val.telegram) val.telegram = val.telegram.replaceAll("@", "");
    val.avatar =
      fileList.length && val.files ? val.files.file?.originFileObj : "";
    const formData = new FormData();

    for (const key in val) {
      if (
        key !== "files" &&
        val.hasOwnProperty(key) &&
        val[key as keyof ITeamAdd]
      ) {
        formData.append(key, val[key as keyof ITeamAdd]);
      }
    }

    if (fileList.length === 0) formData.append("avatar", "");

    if (editableData) editTeam({ id: editableData.id, formData });
    else addTeam(formData);
  };

  useEffect(() => {
    if (addStatus || editStatus) {
      closeModal();
      handleGet?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, editStatus]);

  //Image upload
  const [fileList, setFileList] = useState<UploadFile[]>(
    form.getFieldValue("files") ?? []
  );
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { avatar, first_name, last_name, position, telegram, phone, email } =
        editableData;
      avatar &&
        setFileList([
          {
            uid: "-1",
            name: avatar?.split("/")[5],
            status: "done",
            url: avatar,
          },
        ]);
      setPosition(position);
      form.setFieldsValue({
        first_name,
        last_name,
        position,
        telegram,
        phone: phone && makePhoneMask(phone),
        email,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);
  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t(customTitle)}
        </div>
      </CustomButton>
      <CustomModal
        padding={45}
        open={isModalOpen}
        width={500}
        onCancel={closeModal}
        paddingMD={0}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData
              ? editableData?.first_name + t("common.editInfo")
              : t("common.addUuser")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <Form.Item name="files" className="team-add-upload">
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-circle"
              fileList={fileList}
              onChange={onChange}
              onPreview={handlePreviewFile}
              maxCount={1}
              beforeUpload={() => {}}
              style={{ width: 88, height: 88 }}
            >
              {fileList.length < 1 && <UserIcon />}
            </Upload>
          </Form.Item>
          {addTeamFormData.map((item, index) => {
            return (
              <Form.Item
                name={item.name}
                label={
                  t(item.label) +
                  (["phone", "telegram"].includes(item.name) ? "" : "*")
                }
                rules={
                  item.name === "phone"
                    ? [
                        {
                          pattern: phonePattern,
                          message: "Please input phone number complately",
                        },
                      ]
                    : [
                        {
                          required: item.name === "telegram" ? false : true,
                          message: item.message,
                        },
                      ]
                }
                className={item.className}
                key={item.name + index}
              >
                {item.action === "select" ? (
                  <Select
                    onChange={(val) => setPosition(val)}
                    allowClear
                    options={positionOptions}
                  />
                ) : item.name === "phone" ? (
                  <MaskedInput
                    className="phoneInput"
                    addonBefore="+998"
                    mask="(00)-000-00-00"
                    placeholder="(XX)-XXX-XX-XX"
                  />
                ) : (
                  <Input prefix={item.prefix} />
                )}
              </Form.Item>
            );
          })}
          {position &&
            (position == "ceo" ||
              position == "cfo" ||
              position == "accountant") && (
              <Form.Item
                name="email"
                label="Email *"
                className="full"
                // rules={[{ required: true, message: "Emailni kiriting" }]}
              >
                <Input />
              </Form.Item>
            )}

          <ModalFooter
            onCancel={closeModal}
            okLoading={addLoading || editLoading}
          />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddTeam;
