import { GoogleOAuthProvider } from "@react-oauth/google";

import { BrowserRouter as Router } from "react-router-dom";
import RoutElements from "./routes";

import AntConfigProvider from "./provider/antConfig";
import FullScreenProvider from "./provider/fullScreen";
import ReduxProvider from "./provider/redux";
import LanguageProvider from "./provider/language";

function App() {
  return (
    <LanguageProvider>
      <ReduxProvider>
        <FullScreenProvider>
          <Router>
            <AntConfigProvider>
              <GoogleOAuthProvider clientId="499657436082-g9oggi0d2s3ss49jjl6cv1520117pmnd.apps.googleusercontent.com">
                <RoutElements />
              </GoogleOAuthProvider>
            </AntConfigProvider>
          </Router>
        </FullScreenProvider>
      </ReduxProvider>
    </LanguageProvider>
  );
}

export default App;
