import { createSlice } from "@reduxjs/toolkit";
import { financeApi } from "../services/finance";
import { IJournalChange, IJournalResData, IJournalTable } from "../services/finance/type";

export type JournalActionType = "income" | "outcome";

export interface IJournalState {
  journals?: IJournalResData[];
  journalChanges?: IJournalChange[];
  journalAction?: JournalActionType;
}

const initialState: IJournalState = {
  journals: undefined,
  journalChanges: undefined,
  journalAction: undefined,
};

const journalSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    changeJournalAction: (state, action) => {
      state.journalAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(financeApi.endpoints.getJournal.matchFulfilled, (state, action) => {
      let arr: IJournalTable[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, index: index + 1, ...item });
      });
      state.journals = arr;
    });
    builder.addMatcher(financeApi.endpoints.getJournalPredicted.matchFulfilled, (state, action) => {
      let arr: IJournalTable[] = [];
      action.payload.results.forEach((item, index) => {
        arr.push({ key: item.id, index: index + 1, ...item });
      });
      state.journals = arr;
    });
    builder.addMatcher(financeApi.endpoints.getJournalChange.matchFulfilled, (state, action) => {
      state.journalChanges = action.payload.results;
    });
  },
});

export const { changeJournalAction } = journalSlice.actions;
export default journalSlice.reducer;
