import { Table } from "antd";
import { useTranslation } from "react-i18next";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import AddStorage from "./add";
import useBasicTools from "./useStorage";
import "./storage.scss";

function StoragePage() {
  const { t } = useTranslation();
  const { data, isLoading, handleGet, getColumns } = useBasicTools();

  return (
    <div className="storage">
      <ContentTop>
        <FilterDate format="year" name="year" />
        <AddStorage handleGet={handleGet} />
      </ContentTop>

      <Table
        columns={[...getColumns(t)]}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 2000 }}
        pagination={false}
      />
      {/* {data.length > 0 && <PaginationFilter total={data?.length} />} */}
    </div>
  );
}

export default StoragePage;
