export const addTeamFormData = [
  {
    name: "first_name",
    label: "auth.signUp.firstNameLabel",
    placeholder: "Ismni kiriting",
    message: "Ismni kiriting!",
    className: "half",
  },
  {
    name: "last_name",
    label: "auth.signUp.lastNameLabel",
    placeholder: "Familiyani kiriting",
    message: "Familiyani kiriting!",
    className: "half",
  },
  {
    name: "position",
    label: "common.position",
    placeholder: "Xodim ro'lini tanlang",
    message: "Xodim ro'lini tanlang!",
    className: "full",
    action: "select",
  },
  // {
  //   name: "telegram",
  //   label: "Telegram",
  //   placeholder: "Xodim telegerami",
  //   message: "Xodim telegeramini kiriting!",
  //   className: "full",
  //   prefix: "@",
  // },
  {
    name: "phone",
    label: "subInfo.phoneNumber",
    placeholder: "__ ___ __ __",
    message: "Please input your phone number!",
    className: "full",
    prefix: "+998",
  },
];
