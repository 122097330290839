import { Form, Input, InputNumber, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddSalaryMutation,
  useEditSalaryMutation,
} from "src/app/services/finance";
import {
  IFormSalary,
  ISalary,
  ISalaryTable,
} from "src/app/services/finance/type";
import { PlusIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { dateFormat } from "src/components/filter/FilterDate";
import DateFormItem from "src/components/formItem/DateFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";
import TeamFormItem from "src/components/formItem/TeamFormItem";

interface Props {
  handleGet: () => void;
  editableData?: ISalaryTable;
  setEditableData: React.Dispatch<
    React.SetStateAction<ISalaryTable | undefined>
  >;
}

export default function AddSalary({
  handleGet,
  editableData,
  setEditableData,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
  };

  //Form section
  const [addSalary, { isSuccess: addStatus }] = useAddSalaryMutation();
  const [editSalary, { isSuccess: editStatus }] = useEditSalaryMutation();
  const [form] = Form.useForm();
  const onFinish = (val: IFormSalary) => {
    let { date, source, ...rest } = val;
    let obj: ISalary = {
      ...rest,
      source: +source,
    };

    if (editableData) editSalary({ id: editableData.id, body: obj });
    else {
      obj["date"] = date?.format("YYYY-MM") + "-01" || "";
      addSalary(obj);
    }
  };

  useEffect(() => {
    if (addStatus) {
      message.success("Ish haqi muvaffaqiyatli qo'shildi!");
      closeModal();
      handleGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus) {
      message.success(`Ish haqi muvaffaqiyatli tahrirlandi!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let { date, employee, source, ...rest } = editableData;

      form.setFieldsValue({
        date: dayjs(date, dateFormat),
        employee: employee ? String(employee?.id) : null,
        source: String(source.id),
        ...rest,
      });
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  return (
    <div className="add">
      <CustomButton className="content-top-btn" height={38} onClick={showModal}>
        <div className="content-top-btn-content">
          <PlusIcon /> {t("common.add")}
        </div>
      </CustomButton>
      <CustomModal
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${
            editableData ? t("subHelper.editTitle") : t("subHelper.addTitle")
          }`}
        />

        <Form
          form={form}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
          requiredMark={false}
        >
          <DateFormItem
            format="month"
            className="half"
            disabled={!!editableData}
          />

          <Form.Item
            name="amount"
            label={`${t("common.amount")} (${t("common.uzs")})`}
            className="half"
            rules={[{ required: true, message: t("common.enterAmount") }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <TeamFormItem name="employee" className="half" required />

          <SourceFormItem className="half" allData isSalaryType />

          <Form.Item
            name="comment"
            label={`${t("common.comment")} *`}
            rules={[{ required: true, message: t("common.enterComment") }]}
            className="full"
          >
            <Input.TextArea />
          </Form.Item>

          <ModalFooter onCancel={closeModal} />
        </Form>
      </CustomModal>
    </div>
  );
}
